<template>
<div style="display: flex; gap:15px;" v-click-outside="onClickOutside">
  <div>
    <div class="this-person-dropdown-container" @click="showSettingsDropdown = !showSettingsDropdown;showBLESettingsDropdown = false" >
      <div class="this-box"><b-icon icon="gear-fill" style="color: white;" font-scale="1"></b-icon>
      </div>Settings
      <div class="this-person-dropdown-content" v-if="showSettingsDropdown">
        <div class="this-person-dropdown-content-item ndt-pointer" @click="userLogout">Logout</div>
        <div class="this-person-dropdown-content-item ndt-pointer" @click="unitModalVisible = true">Change Units</div>
        <div class="this-person-dropdown-content-item ndt-pointer" @click="passwordModalVisible = true">Change Password</div>
      </div>
    </div>

    <b-modal
      v-model="unitModalVisible"
      size="sm"
      centered
      content-class="ndt-card-1"
      scrollable
      no-close-on-backdrop
      no-close-on-escape
      hide-header-close
      hide-header
      hide-footer
    >
      <UnitManage @closeMe="unitModalVisible = false" />
    </b-modal>

    <b-modal
      v-model="passwordModalVisible"
      size="lg"
      content-class="ndt-card-1"
      centered
      scrollable
      no-close-on-backdrop
      no-close-on-escape
      hide-header-close
      hide-header
      hide-footer
    >
      <PasswordManage @closeMe="passwordModalVisible = false" />
    </b-modal>

  </div>
  <div>
    <div class="this-person-dropdown-container" @click="showBLESettingsDropdown = !showBLESettingsDropdown;showSettingsDropdown = false">
      <div class="this-box">
        <b-icon icon="record2" :style="{ color: gameboyObjectsGlobal.deviceConnected ? '#64F08C' : 'red' }" font-scale="1.59"></b-icon>
      </div>Nora 2
      <div class="this-person-dropdown-content ble-dropdown" v-if="showBLESettingsDropdown">
        <router-link to="/deviceHandler" v-if="!gameboyObjectsGlobal.deviceConnected">
          <div class="this-person-dropdown-content-item ndt-pointer" style="color: white;">
            Go to Device
          </div>
        </router-link>        <div class="this-person-dropdown-content-item ndt-pointer" @click="disconnectBLE" v-if="gameboyObjectsGlobal.deviceConnected">Disconnect Nora 2</div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
// import AvatarIcon2 from '@/assets/AvatarIcon2.vue'
import UnitManage from '@/components/UnitManage.vue'
import PasswordManage from '@/components/PasswordManage.vue'
import vClickOutside from 'v-click-outside'
import GeneralMixin from '@/mixins/GeneralMixin.vue'

export default {
  name: 'PersonMenuDesktop',

  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    // AvatarIcon2,
    UnitManage,
    PasswordManage
  },

  mixins: [GeneralMixin],

  data () {
    return {
      unitModalVisible: false,
      passwordModalVisible: false,
      showSettingsDropdown: false,
      showBLESettingsDropdown: false
    }
  },

  methods: {
    disconnectBLE () {
      this.gameboyObjectsGlobal.primaryServer.disconnect()
      this.$store.dispatch('data/setInitialGameboyObjects')
      // this.$router.push('/deviceHandler')
    },
    onClickOutside (event) {
      // console.log('Clicked outside. Event: ', event)
      this.showSettingsDropdown = false
      this.showBLESettingsDropdown = false
    },
    userLogout: function (event) {
      this.$store.dispatch('auth/logout')
        .then(() => {
          this.$router.push('/')
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/styles/app.scss';

  .this-person-icon {
    background-size:40px 40px;
    /* width: 40px;
    height: 40px;
    background: green;
    border-radius: 8px; */
  }

  .this-person-text {
    /*width: 131px;
    height: 19px;*/
    margin-top: 10.5px;
    margin-left: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
  }

  .this-person-dropdown-content {
    // display: none;
    position: absolute;
    left: 30px;
    top: -20px;
    background-color: $ndt-color-4;
    width: 130px;
    height: 72px;
    border: 1px solid $ndt-color-4;
    border-radius: 0.25rem;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 0px 2px 15px 2px;
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: $ndt-color-1;
    z-index: 5000;
    overflow: visible;
  }

  @media (max-width: $tablet-breakpoint) {
    .this-person-dropdown-content {
      top: 0px;
    }
  }

  .this-person-dropdown-content-item {
    cursor: pointer;
    margin-top: 8px;
    margin-left: 8px;
  }

  .this-person-dropdown-container {
    position: relative;
    padding-right: 9px;
    display: flex;
    color: white;
    border: 1px solid #003232;
    align-items: normal;
    /* padding-top: 5px; */
    padding-bottom: 0px;
    background: #0D3C3C;
    border-radius: 5px;
    /* overflow: hidden; */
    max-height: 87%;
}

.ble-dropdown{
  max-height: 30px;
  left: 50px;
  top: -23px;
}

  // .this-person-dropdown-container:hover .this-person-dropdown-content {
  //   display: block;
  // }

  .this-box {
    // background: $ndt-color-3;
    // border: 1px solid $ndt-color-3;
    width: 35px;
    // height: 35px;
    // border-radius: 5px;
    // padding-top: 5px;
  }
</style>
