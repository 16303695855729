import api from '@/utils/api.js'

class Utils {
  test () {
    console.log('Testing Helper class')
  }

  correctAnalysisUnitsAndValues (resp, payload) {
    resp.results.forEach((result) => {
      result.collected = result.collected ? result.collected : result.analyzed
      result.analysis_analyte?.forEach((item) => {
        // console.log('****STORE DATA ANALYSIS_ANALYTE', item)
        // If it is a micronutrient then all displayed values need to be as ppb or micrommoles
        if (item.analyte_type === 'micro') {
          item.base_value = item.value * 1000
        } else {
          item.base_value = item.value
        }
        if (payload.unit === 'mmol') {
          item.value = (item.base_value / item.molecular_weight).toFixed(3)
        } else if (payload.unit === 'second') {
          item.value = (item.base_value * item.analyte_second_conv).toFixed(3)
        } else {
          item.value = item.base_value
        }
        item.dilution = result.dilution
      })
    })
    return resp
  }

  combineCompactAndFullAnalysesResponses (compactResponse, fullResponse) {
    const combinedResults = { results: [] }
    for (let index = 0; index < fullResponse.results.length; index++) {
      const currentFullResponse = fullResponse.results[index]
      const currentCompactResponse = compactResponse.results.find(x => x.data_id === currentFullResponse.data_id)
      const merged = { ...currentCompactResponse, ...currentFullResponse }
      combinedResults.results.push(merged)
    }
    combinedResults.count = fullResponse.count
    combinedResults.next = fullResponse.next
    combinedResults.previous = fullResponse.previous
    return combinedResults
  }

  getDetailsForSelectedUserAnalytics (data) {
    const promises = data.analyses.map((analysis) => {
      return api.getAnalysisDetailAdmin(analysis.analysis_pk)
        .then((response) => {
          response.analysis_analyte.forEach((analyte) => {
            analysis[analyte.analyte_name] = parseFloat(analyte.value)
          })
          return response
        })
        .catch((error) => {
          console.log('getDetailsForSelectedUserAnalytics error', error)
          throw error
        })
    })

    return Promise.all(promises)
      .then(() => {
        return data
      })
  }
}

// export singleton
const utils = new Utils()
export default utils
