<template>
  <div>
    <svg width="19" height="23" viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.3" clip-path="url(#clip0_54_937)">
    <path d="M17.9821 4.03574C18.5462 4.03574 19 4.49166 19 5.0536C19 5.61766 18.5462 6.07145 17.9821 6.07145H17.4902L16.4723 19.8422C16.3663 21.2587 15.1873 22.3572 13.7665 22.3572H5.23348C3.81357 22.3572 2.63328 21.2587 2.5281 19.8422L1.50812 6.07145H1.01786C0.455915 6.07145 0 5.61766 0 5.0536C0 4.49166 0.455915 4.03574 1.01786 4.03574H3.97897L5.5346 1.70061C5.97567 1.03972 6.71786 0.642883 7.51094 0.642883H11.4891C12.2821 0.642883 13.0243 1.03976 13.4654 1.70061L15.0219 4.03574H17.9821ZM7.51094 2.6786C7.40067 2.6786 7.25647 2.73543 7.23103 2.82958L6.42522 4.03574H12.5748L11.769 2.82958C11.7054 2.73543 11.5993 2.6786 11.4891 2.6786H7.51094ZM15.4502 6.07145H3.54935L4.55915 19.6938C4.5846 20.0458 4.88147 20.3215 5.23348 20.3215H13.7665C14.1185 20.3215 14.4154 20.0458 14.4408 19.6938L15.4502 6.07145Z" fill="black"/>
    </g>
    <defs>
    <clipPath id="clip0_54_937">
    <rect width="19" height="21.7143" fill="white" transform="translate(0 0.642883)"/>
    </clipPath>
    </defs>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'DeleteIcon'
}
</script>

<style lang="scss" scoped>
  @import '@/styles/app.scss';
</style>
