<template>
  <div>
    <div class="ndt-debug" v-if="debugOn">
      {{getParentFileName}} / <strong>{{getFileName}}</strong>
    </div>
    <b-card v-if="thisAccount === undefined" class="text-center ndt-borderless-card">
      <h5>You must first choose an account</h5>
      <button @click="$emit('closeMe')" class="ndt-button-done-small">Ok</button>
    </b-card>

    <b-card v-if="createSuccessful" class="text-center ndt-borderless-card">
      <h5 v-if="action.create">Station {{ createdName }} was successfully created</h5>
      <h5 v-if="action.update">Station {{ createdName }} was successfully updated</h5>
      <button @click="$emit('closeMe')" class="ndt-button-done-small">Ok</button>
    </b-card>

    <b-card v-if="thisAccount && !createSuccessful" class="ndt-borderless-card">
      <template v-if="prodObject">
        <h6 v-if="action.create">Creating new station inside room {{prodObject.name}}</h6>
        <h6 v-if="action.update">Editing station {{ form.name }}</h6>
      </template>
      <template v-else>
        <h6 v-if="action.create">Creating new station in account {{ thisAccount }}</h6>
        <h6 v-if="action.update">Editing station {{ form.name }}</h6>
      </template>

      <b-card class="ndt-error-text" v-if="errorText">
        {{errorText}}
      </b-card>

      <b-form v-on:submit.prevent="submit">

        <b-form-group label="name" label-for="input1">
          <b-form-input id="input1" placeholder="Enter name" type="text" name="id" v-model="form.name"
              autoFocus autoComplete="off" />
        </b-form-group>

        <!-- <b-form-group label="Room" label-for="input2">
          <b-form-select
            v-model="prodObjectId"
            :options="prodObjectOptions"
            value-field="id"
            text-field="name">
            <template #first>
              <b-form-select-option :value="null">-- Select from the list --</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group> -->

        <!-- <b-form-group label="Target" label-for="input2" description="Optionally select a set of target thresholds for nutrient concentrations">
          <b-form-select
            v-model="targetId"
            :options="targetOptions"
            value-field="id"
            text-field="name">
            <template #first>
              <b-form-select-option :value="null">-- Select from the list --</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group> -->

        <!-- <b-form-group label="Description" label-for="input3">
          <b-form-input id="input3" placeholder="Enter a comment" type="text" name="tag1" v-model="form.tag"
              autoComplete="off" />
        </b-form-group> -->

        <div v-if="layout === 'outdoor'">
          <b-form-group label="Latitude" label-for="input4">
            <b-form-input id="input4" placeholder="" type="number" step="0.000001" name="lat" v-model="form.lat"
                autoComplete="off" />
          </b-form-group>

          <b-form-group label="Longitude" label-for="input5">
            <b-form-input id="input5" placeholder="" type="number" step="0.000001" name="long" v-model="form.long"
                autoComplete="off" />
          </b-form-group>

          <b-form-group label="Elevation" label-for="input6" description="Elevation is given in meters relative to ground-level">
            <b-form-input id="input6" type="number" value="0.00" step="0.01" name="elevation" v-model="form.elevation"
                autoComplete="off" />
          </b-form-group>
        </div>

        <!-- <b-form-group label="Barcode" label-for="input7">
          <b-form-input id="input7" placeholder="Enter barcode" type="text" name="barcode" v-model="form.barcode"
              autoComplete="off" />
        </b-form-group> -->

        <button class="ndt-button-done-small" type="submit">Submit</button>
        <span class="ml-2">
          <button class="ndt-button-cancel-small" @click.prevent="$emit('closeMe')">
            Cancel
          </button>
        </span>

      </b-form>
    </b-card>

  </div>
</template>

<script>
import GeneralMixin from '@/mixins/GeneralMixin.vue'

export default {
  name: 'StationCreateAndUpdate',

  mixins: [GeneralMixin],

  data () {
    return {
      form: {
        name: this.data.name,
        tag: this.data.tag,
        account: this.$store.getters['options/activeAccount'].id,
        // prod_object: null,
        // target: null,
        lat: this.data.lat,
        long: this.data.long,
        elevation: this.data.elevation,
        barcode: this.data.barcode
      },
      location: 'StationCreateAndUpdate.vue',
      errorText: null,
      accountOptions: [],
      prodObjectOptions: [],
      targetOptions: [],
      prodObjectSelectMessage: '',
      createSuccessful: false,
      createdName: null,
      targetId: null,
      prodObjectId: null
    }
  },

  props: {
    action: {
      type: Object,
      default () {
        return { none: true }
      }
    },
    pk: {
      type: Number,
      default: null
    },
    data: {
      type: Object,
      default () {
        return { name: '', tag: '', lat: '', long: '', elevation: '', barcode: '' }
      }
    },
    prodObject: {
      type: Object,
      default () {
        return null
      }
    }
  },

  mounted () {
    this.getProdObjectOptions()
    this.getTargetOptions()
    this.setupForm()
  },

  methods: {
    getProdObjectOptions: function () {
      this.$api.getProdObjectListMin()
        .then(response => {
          this.prodObjectOptions = response
          if (this.action.update) {
            const obj = response.find(o => o.name === this.data.prod_object)
            if (obj) { this.prodObjectId = obj.id }
          } else {
            if (this.prodObject) {
              this.prodObjectId = this.prodObject.id
            }
          }
        })
        .catch(error => {
          console.log('***Error in ', this.location, ' : ', error)
          this.errorText = error
        })
    },

    getTargetOptions: function () {
      this.$api.getTargetListMin()
        .then(response => {
          this.targetOptions = response
          if (this.action.update) {
            const obj = response.find(o => o.name === this.data.target)
            if (obj) { this.targetId = obj.id }
          }
        })
        .catch(error => {
          console.log('***Error in ', this.location, ' : ', error)
          this.errorText = error
        })
    },

    setupForm: function () {
      if (this.action.update) {
        if (this.data.prod_object) {
          this.prodObjectId = this.data.prod_object.id
        }
        if (this.data.target) {
          this.targetId = this.data.target.id
        }
      }
    },

    isEmpty: function (input) {
      return !input || !input.trim()
    },

    submit: function () {
      if (this.action.create) {
        this.createSubmit()
      } else {
        this.updateSubmit()
      }
    },

    createSubmit: function (e) {
      if (this.isEmpty(this.form.lat)) {
        this.form.lat = null
      }
      if (this.isEmpty(this.form.long)) {
        this.form.long = null
      }
      if (this.isEmpty(this.form.elevation)) {
        this.form.elevation = null
      }
      this.form.target = this.targetId
      this.form.prod_object = this.prodObjectId
      this.$api.postStation(this.form).then(data => {
        this.createdName = data.name
        this.errorText = null
        this.$emit('success')
        this.createSuccessful = true
      }).catch((error) => {
        if (error.non_field_errors) {
          this.errorText = error.non_field_errors[0]
        } else {
          this.errorText = error
        }
      })
    },

    updateSubmit: function (e) {
      if (this.isEmpty(this.form.lat)) {
        this.form.lat = null
      }
      if (this.isEmpty(this.form.long)) {
        this.form.long = null
      }
      if (this.isEmpty(this.form.elevation)) {
        this.form.elevation = null
      }
      this.form.prod_object = this.prodObjectId
      this.form.target = this.targetId
      this.$api.patchStationDetail(this.pk, this.form).then(data => {
        this.createdName = data.name
        this.errorText = null
        this.$emit('success')
        this.createSuccessful = true
      }).catch((error) => {
        console.log('***form error', error)
        if (error.non_field_errors) {
          this.errorText = error.non_field_errors[0]
        } else {
          this.errorText = error
        }
      })
    }

  }
}
</script>

<style scoped lang="scss">
  @import '@/styles/app.scss';
</style>
