import api from '@/utils/api.js'
import utils from '@/utils/utils.js'

const state = {
  selectedUser: null,
  users: [],
  analyticsSummary: [],
  status: null
}

const mutations = {
  user_request (state) {
    state.status = 'loading'
  },
  user_success (state, data) {
    state.status = 'loaded'
    state.users = data
  },
  user_error (state) {
    state.status = 'error'
  },
  selected_user_request (state) {
    state.status = 'loading'
  },
  selected_user_success (state, data) {
    state.status = 'loaded'
    state.selectedUser = data
  },
  selected_user_error (state) {
    state.status = 'error'
  },
  analytics_summary_request (state) {
    state.status = 'loading'
  },
  analytics_summary_success (state, data) {
    state.status = 'loaded'
    state.analyticsSummary = data
  },
  analytics_summary_error (state) {
    state.status = 'error'
  }
}

const getters = {
  users: state => state.users,
  status: state => state.status,
  selectedUser: state => state.selectedUser,
  analyticsSummary: state => state.analyticsSummary
}

const actions = {

  getUserList ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('user_request')
      api.getUserAnalyticsList()
        .then(resp => {
          commit('user_success', resp)
          resolve(resp)
        })
        .catch(err => {
          commit('user_error')
          reject(err)
        })
    })
  },

  getSelectedUserAnalytics ({ commit }, payload) {
    // console.log('****getting selected data pk =', payload.userPk, payload.startDate)
    return new Promise((resolve, reject) => {
      commit('selected_user_request')
      api.getUserAnalyticsDetail(payload.userPk, payload.startDate, payload.endDate)
        .then(resp => {
          utils.getDetailsForSelectedUserAnalytics(resp).then(response => {
            commit('selected_user_success', response)
            resolve(response)
          })
        })
        .catch(err => {
          commit('selected_user_error')
          reject(err)
        })
    })
  },

  getAnalyticsSummary ({ commit }, payload) {
    // console.log('****getting selected data pk =', payload.userPk, payload.startDate)
    return new Promise((resolve, reject) => {
      commit('analytics_summary_request')
      api.getUserAnalyticsSummary(payload.startDate, payload.endDate)
        .then(resp => {
          commit('analytics_summary_success', resp)
          resolve(resp)
        })
        .catch(err => {
          commit('analytics_summary_error')
          reject(err)
        })
    })
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
