import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
// import { eventsBus } from '@/main'
// Layouts
import LoginLayout from '@/layouts/LoginLayout.vue'
import HomeLayout from '@/layouts/HomeLayout.vue'
// Views
import Login from '@/views/Login.vue'
import NotFound from '@/components/NotFound.vue'
import DataBoard from '@/views/DataBoard.vue'
import RoomBoard from '@/views/RoomBoard.vue'
import ChartBoard from '@/views/ChartBoard.vue'
import GameboyBoard from '@/views/GameboyBoard.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: LoginLayout,
    children: [{
      path: '', name: 'login', component: Login, meta: { guest: true }
    }]
  }, {
    path: '/data',
    component: HomeLayout,
    meta: { requiresAuth: true },
    children: [{
      path: '',
      name: 'data',
      component: DataBoard,
      meta: { requiresAuth: true }
    }, {
      path: 'analyzer',
      name: 'analyzer',
      component: DataBoard,
      meta: { requiresAuth: true }
    }, {
      path: 'labreports',
      name: 'labreports',
      component: DataBoard,
      meta: { requiresAuth: true },
      props: { showLabReportsTable: true }
    }, {
      path: 'quickloggertable',
      name: 'quickloggertable',
      component: DataBoard,
      meta: { requiresAuth: true },
      props: { showQuickSelect: true, showQuickLogger: false }
    }, {
      path: 'quickloggerupload',
      name: 'quickloggerupload',
      component: DataBoard,
      meta: { requiresAuth: true },
      props: { showQuickSelect: true, showQuickLogger: true }
    }, {
      path: 'pdfUpload',
      name: 'pdfUpload',
      component: DataBoard,
      meta: { requiresAuth: true },
      props: { pdfUploadModalVisible: true }
    }]
  }, {
    path: '/charts',
    component: HomeLayout,
    meta: { requiresAuth: true },
    children: [{
      path: '',
      name: 'charts',
      component: ChartBoard,
      meta: { requiresAuth: true }
    }, {
      path: 'analyzer',
      name: 'chartsanalyzer',
      component: ChartBoard,
      meta: { requiresAuth: true },
      props: { showAnalyzerSelect: true, showLabSelect: false, showQuickSelect: false }
    }, {
      path: 'lab',
      name: 'lab',
      component: ChartBoard,
      meta: { requiresAuth: true },
      props: { showAnalyzerSelect: false, showLabSelect: true, showQuickSelect: false }
    }, {
      path: 'quickloggerchart',
      name: 'quickloggerchart',
      component: ChartBoard,
      meta: { requiresAuth: true },
      props: { showAnalyzerSelect: false, showLabSelect: false, showQuickSelect: true }
    }]
  }, {
    path: '/rooms',
    component: HomeLayout,
    meta: { requiresAuth: true },
    children: [{
      path: '',
      name: 'rooms',
      component: RoomBoard,
      meta: { requiresAuth: true }
    }]
  }, {
    path: '/deviceHandler',
    component: HomeLayout,
    meta: { requiresAuth: true },
    children: [{
      path: '',
      name: 'deviceHandler',
      component: GameboyBoard,
      meta: { requiresAuth: true },
      props: { showWifiConfigPage: false }
    },
    {
      path: 'devicedashboard',
      name: 'devicedashboard',
      component: GameboyBoard,
      meta: { requiresAuth: true },
      props: { showDashboard: true, showWifiConfigPage: false }
    },
    {
      path: 'wificonfig',
      name: 'wificonfig',
      component: GameboyBoard,
      meta: { requiresAuth: true },
      props: { showDashboard: false, showWifiConfigPage: true }
    }]
  }, {
    path: '/admin',
    component: HomeLayout,
    meta: { requiresAuth: true },
    children: [{
      path: '',
      name: 'admin',
      component: () => import('../views/AdminBoard.vue'),
      meta: { requiresAuth: true, requiresAdmin: true }
    }]
  }, {
    path: '*',
    name: 'notFound',
    component: NotFound,
    // component: Home,
    meta: { requiresAuth: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // console.log('***ROUTER beforeEach TO FROM NEXT', to, from)
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['auth/loggedIn']) {
      // console.log('***ROUTER IS LOGGED IN')
      if (to.matched.some(record => record.meta.requiresAdmin)) {
        if (store.getters['options/isAdmin']) {
          // console.log('***ROUTER IS ADMIN')
          next()
          return
        } else {
          next('/')
          return
        }
      }
      next()
      return
    }
    next('/')
  } else {
    next()
  }
})

router.afterEach((to, from) => {
  console.log('***ROUTER AFTER from, to', from.name, to.name)
  store.dispatch('options/setCurrentView', to.name)
})

export default router
