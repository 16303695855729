import api from '@/utils/api.js'

const state = {
  crops: [],
  cropStatus: ''
}

const mutations = {
  crop_request (state) {
    state.cropStatus = 'loading'
  },
  crop_success (state, data) {
    state.cropStatus = 'success'
    state.crops = data
  },
  crop_error (state) {
    state.cropStatus = 'error'
  }
}

const getters = {
  crops: state => state.crops,
  cropStatus: state => state.cropStatus
}

const actions = {

  getCropList ({ commit }, payload) {
    console.log('****GOT DATES payload ', payload)
    // console.log('****GOT DATES ', payload.startDate, payload.endDate)
    return new Promise((resolve, reject) => {
      commit('crop_request')
      let start = null
      let end = null
      if (payload) {
        start = payload.startDate
        end = payload.endDate
      }
      api.getCropList('id,name,sown,harvested,prod_object,target', start, end)
        .then(resp => {
          resp.forEach((item) => {
            item.sown = new Date(item.sown).toLocaleDateString('en-CA')
            item.harvested = new Date(item.harvested).toLocaleDateString('en-CA')
          })
          commit('crop_success', resp)
          resolve(resp)
        })
        .catch(err => {
          commit('crop_error')
          reject(err)
        })
    })
  },

  setStationId ({ commit }, value) {
    commit('stationid_set', value)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
