<template>
  <div id="mainChartDiv" class="chartPadding">
    <!-- <div class='this-chart-outline'> -->
      <div id='chartDiv'></div>
    <!-- </div> -->
    <!-- stationsSelected = <br/>
    {{ stationsSelected }} <br/><br/>
    variablesSelected = <br/>
    {{ variablesSelected }} <br/>
    {{ genericDataGlobal }} -->
    <br/><br/>
  </div>
</template>

<script>
/* eslint-disable */
import GeneralMixin from '@/mixins/GeneralMixin.vue'
import Plotly from 'plotly.js-dist'

export default {

  name: 'ChartHolder',

  components: {
  },

  mixins: [GeneralMixin],

  props: {
    labSelect: {
      type: Boolean,
      default: false
    },
    showQuickSelect: {
      type: Boolean,
      default: false
    },
    stationsSelected: {
      type: Array,
      default: () => []
    },
    variablesSelected: {
      type: Array,
      default: () => []
    },
    chartStart: { },
    chartEnd: {
    },
    target: {},
    chartType: {
      type: String,
      default: 'bar'
    },
    targetVisible: {}
  },

  data () {
    return {
      allValues: {},
      chartColors: ['#64F08C', '#78AB82', '#D6FADC', '#3B4472', '#00DAB2',
        '#00BFD3', '#00A1E1', '#545B8C', '#ECEEFF', '#8C8EB1',
        '#3C4A3E', '#A9AE8A', '#68EDCB', '#E8EAD8', '#78814E',
        '#C9A74E', '#907419', '#719A78', '#EEFDF0', '#9FAFA1',
        '#FF990F', '#AEEB77', '#FFDE82', '#FF6A6F', '#00D395',
        '#1ABA5B']
    }
  },

  computed: {
    mediaType: function () { return this.$store.getters['options/mediaType'] }
  },

  watch: {
    analysesGlobal: {
      handler: function (newValue, oldValue) {
        this.constructChart(1, 2)
      },
      deep: true
    },
    stationsSelected: function (newValue, oldValue) {
      this.constructChart(newValue, oldValue, true)
    },
    variablesSelected: function (newValue, oldValue) {
      this.constructChart(newValue, oldValue, true)
    },
    chartStart: function (newValue, oldValue) {
      this.constructChart(newValue, oldValue)
    },
    chartEnd: function (newValue, oldValue) {
      this.constructChart(newValue, oldValue)
    },
    target: function (newValue, oldValue) {
      this.constructChart(newValue, oldValue)
    },
    labSelect: function (newValue, oldValue) {
      this.constructChart(newValue, oldValue, true)
    },
    showQuickSelect: function (newValue, oldValue) {
      this.constructChart(newValue, oldValue, true)
    },
    chartType: function (newValue, oldValue) {
      this.constructChart(newValue, oldValue)
    },
    targetVisible: function (newValue, oldValue) {
      this.constructChart(newValue, oldValue)
    }
  },

  created () {
    this.logDebug(`mounted ${this.$options.name}`)
  },

  mounted () {
    // instead of console.log we use
    this.logDebug(`mounted ${this.$options.name}`)
    // The following is typically used in views. It logs a message to the Console database ClientEvent table
    this.logEvent('Crops tab mounted')
  },

  destroyed () {
    this.logDebug(`mounted ${this.$options.name}`)
  },

  methods: {
    submit: function (params) {
      this.logDebug(`submit called with params ${params}`)
    },
    constructChart (newValue, oldValue, changedStations = false) {
      Plotly.purge('chartDiv')
      // console.log('this.genericDataGlobal', this.genericDataGlobal)
      // console.log('this.analysesGlobal', this.analysesGlobal)
      // console.log('this.stationsSelected', this.stationsSelected)
      const variablesSelected = JSON.parse(JSON.stringify(this.variablesSelected))
      // console.log('variablesSelected', variablesSelected)
      if (this.stationsSelected[0] && variablesSelected.length > 0) {
        if (this.stationsSelected.length > 1) {
          this.compareSingleVariableForMultipleStations(changedStations)
        } else {
          this.compareMultipleVariablesForSingleStation(changedStations)
        }
      }
    },
    // constructAnalyteNameMapping () {
    //   const nameMapping = {}
    //   this.analytes.forEach((analyte) => {
    //     nameMapping[analyte.long_name] = analyte.name
    //   })
    //   this.nameMapping = nameMapping
    // },
    compareSingleVariableForMultipleStations (changedStations) {
      // const currentVariableName = this.nameMapping[this.variablesSelected[0]] // only one variable allowed for comparison, even if multiple are selected
      const currentVariableName = this.variablesSelected[0] // only one variable allowed for comparison, even if multiple are selected
      let oldestDate = this.chartStart
      let latestDate = this.chartEnd
      if (changedStations || (!oldestDate) || (!latestDate)) {
        const dates = this.getDateRangeForMultipleStations(oldestDate, latestDate)
        oldestDate = dates[0]
        latestDate = dates[1]
      }
      const allStationNames = []
      this.stationsSelected.forEach((currentStation) => {
        // console.log('currentStation', JSON.stringify(currentStation))
        const variableData = []
        const plotData = {}
        const collectedDataTimeStamps = []
        let currentStationData = null
        // if (this.showQuickSelect) {
        //   currentStationData = this.genericDataGlobal.filter((value) => value.location === currentStation)
        // } else {
        //   currentStationData = this.analysesGlobal.filter((value) => value.station_pk === currentStation)
        // }
        // if (this.labSelect) {
        //   currentStationData = currentStationData.filter((value) => value.device === this.webAppPk)
        // }
        if (this.showQuickSelect) {
          currentStationData = this.genericDataGlobal.filter((value) => value.location === currentStation)
          // console.log('filtered currentStation, currentStationData', currentStation, currentStationData)
        } else if (this.labSelect) {
          currentStationData = this.analysesGlobal.filter((value) => value.station_pk === currentStation)
          currentStationData = currentStationData.filter((value) => value.device === this.webAppPk)
          // console.log('filtered currentStationData for LAB', currentStationData)
        } else {
          currentStationData = this.analysesGlobal.filter((value) => value.station_pk === currentStation)
          currentStationData = currentStationData.filter((value) => value.device !== this.webAppPk)
          // console.log('filtered currentStationData for ANALZYER', currentStationData)
          // console.log('filtered currentStationData', JSON.stringify(currentStationData))
        }
        currentStationData.forEach((value) => {
          if (this.compareDates(value.collected, oldestDate, latestDate)) {
            let singularVariableData = null
            if (this.showQuickSelect) {
              if (value.variable_name === currentVariableName) {
                singularVariableData = [{ value: value.numeric_value }]
              }
            } else {
              singularVariableData = value.analysis_analyte.filter((item) => item.analyte_name === currentVariableName)
            }
            // console.log('before singularVariableData', JSON.stringify(singularVariableData))
            // console.log('before variableData', JSON.stringify(variableData))
            if (singularVariableData && singularVariableData?.length > 0) {
              // console.log('before pushing to variableData', JSON.stringify(variableData))
              // console.log('pushing to variableData singularVariableData[0]', JSON.stringify(variableData), JSON.stringify(singularVariableData[0]))
              variableData.push(singularVariableData[0])
              // console.log('after pushing to variableData', JSON.stringify(variableData))
              if (this.labSelect) {
                const currentDate = new Date(value.analyzed)
                collectedDataTimeStamps.push(currentDate)                
              } else{
                const currentDate = new Date(value.collected)
                collectedDataTimeStamps.push(currentDate)
            }
          }
            // console.log('after singularVariableData', JSON.stringify(singularVariableData))
            // console.log('after variableData', JSON.stringify(variableData))
          }
        })
        const sortedChartData = this.sortChartData(collectedDataTimeStamps, variableData)
        plotData.x = sortedChartData.dates
        plotData.y = sortedChartData.data
        plotData.type = this.chartType
        // const lineChartVariables = ['pH', 'EC', 'Electroconductivity', 'pH_quick', 'EC_quick']
        // if (lineChartVariables.includes(currentVariableName)) {
        //   plotData.type = 'line'
        // }
        const uniqueStations = Object.assign({}, ...this.stationsGlobal.map((item) => ({ [item.id]: item.name })))
        let currentStationName = null
        if (this.showQuickSelect) {
          currentStationName = uniqueStations[currentStationData[0].location]
        } else {
          currentStationName = currentStationData[0].station_name
        }
        this.allValues[currentStationName] = plotData
        allStationNames.push(currentStationName)
      })

      // this.stationsSelected.forEach((currentStation) => {
      //   allStationNames.push(currentStation.name)
      // })
      const chartPlotData = this.prepareChartPlotData(allStationNames, [])
      const chartLayoutData = this.prepareChartLayoutData(allStationNames, [])
      Plotly.newPlot('chartDiv', chartPlotData, chartLayoutData, { responsive: true })
    },
    compareMultipleVariablesForSingleStation (changedStations) {
      const showCasepH = false
      if (showCasepH) {
        this.variablesSelected.push('pH')
      }
      this.variablesSelected.forEach((currentVariable) => {
        const plotData = {}
        const variableData = []
        const collectedDataTimeStamps = []
        // const currentVariableName = this.nameMapping[currentVariable]
        const currentVariableName = currentVariable
        let oldestDate = this.chartStart
        let latestDate = this.chartEnd
        if (changedStations || (!oldestDate) || (!latestDate)) {
          const dates = this.getDateRangeForSingleStation(oldestDate, latestDate)
          oldestDate = dates[0]
          latestDate = dates[1]
        }
        let currentStationData = null
        if (this.showQuickSelect) {
          currentStationData = this.genericDataGlobal.filter((value) => value.location === this.stationsSelected[0])
        } else if (this.labSelect) {
          currentStationData = this.analysesGlobal.filter((value) => value.station_pk === this.stationsSelected[0])
          currentStationData = currentStationData.filter((value) => value.device === this.webAppPk)
          // console.log('filtered currentStationData for LAB', currentStationData)
        } else {
          currentStationData = this.analysesGlobal.filter((value) => value.station_pk === this.stationsSelected[0])
          currentStationData = currentStationData.filter((value) => value.device !== this.webAppPk)
          // console.log('filtered currentStationData for ANALYZER', currentStationData)
        }
        currentStationData.forEach((value) => {
          if (this.compareDates(value.collected, oldestDate, latestDate)) {
            let singularVariableData = null
            if (this.showQuickSelect) {
              if (value.variable_name === currentVariableName) {
                singularVariableData = [{ value: value.numeric_value }]
              }
            } else {
              singularVariableData = value.analysis_analyte.filter((currentValue) => currentValue.analyte_name === currentVariableName)
            }
            if (singularVariableData && singularVariableData?.length > 0) {
              variableData.push(singularVariableData[0])
              if (this.labSelect) {
                const currentDate = new Date(value.analyzed)
                collectedDataTimeStamps.push(currentDate)                
              } else{
                const currentDate = new Date(value.collected)
                collectedDataTimeStamps.push(currentDate)
            }
            }
            // console.log(' single station singularVariableData', singularVariableData)
          }
        })
        const sortedChartData = this.sortChartData(collectedDataTimeStamps, variableData)
        plotData.x = sortedChartData.dates
        plotData.y = sortedChartData.data
        plotData.type = this.chartType
        // const lineChartVariables = ['pH', 'EC', 'Electroconductivity', 'pH_quick', 'EC_quick']
        // if (lineChartVariables.includes(currentVariable)) {
        //   plotData.type = 'line'
        // }
        this.allValues[currentVariable] = plotData
        // console.log('this.allValues', this.allValues)
      })

      let chartPlotData = ''
      let chartLayoutData = ''
      if (showCasepH) {
        this.variablesSelected = this.variablesSelected.filter(function (e) { return e !== 'pH' })
        chartPlotData = this.prepareChartPlotData(this.variablesSelected, ['pH'])
        chartLayoutData = this.prepareChartLayoutData(['pH'])
      } else {
        chartPlotData = this.prepareChartPlotData(this.variablesSelected, [])
        chartLayoutData = this.prepareChartLayoutData(this.variablesSelected, [])
      }
      Plotly.newPlot('chartDiv', chartPlotData, chartLayoutData, { responsive: true })
    },
    sortChartData (datesInput, dataInput) {
      const combinedValues = []
      datesInput.forEach((key, index) => {
        combinedValues.push({ date: key, data: dataInput[index] })
      })
      combinedValues.sort((a, b) => a.date - b.date)
      const dates = combinedValues.map(({ date }) => date)
      const data = combinedValues.map(({ data }) => data)
      return { dates: dates, data: data }
    },
    compareDates (input, lowerRange, upperRange) {
      const givenDate = (new Date(input)).toISOString().split('T')[0]
      const lowerRangeDate = (new Date(lowerRange)).toISOString().split('T')[0]
      const upperRangeDate = (new Date(upperRange)).toISOString().split('T')[0]

      if (givenDate >= lowerRangeDate && givenDate <= upperRangeDate) {
        return true
      }
      return false
    },
    generateAdditionalColors (requiredColors) {
      const colors = []
      while (colors.length < requiredColors) {
        const newColor = `rgb(${rand(0, 255)}, ${rand(0, 255)}, ${rand(0, 255)})`
        colors.push(newColor)
        this.chartColors.push(newColor)
      }
      // random number generator
      function rand (from, to) {
        return ~~(Math.random() * (to - from)) + from
      }
    },
    prepareChartPlotData (mainPaneVariables, bottomPaneVariables) {
      if (mainPaneVariables.length > this.chartColors.length) {
        this.generateAdditionalColors(mainPaneVariables.length - this.chartColors.length)
      }
      const allChartPlotData = []
      const baseChartDataTemplate = {
        x: null, // array = [1,2,3]
        y: null, // array = [4,5,6]
        xaxis: null, // string = 'x3'
        yaxis: null, // string = 'y3'
        type: null, // string = 'bar' | 'line'
        name: null // string = 'Nutrient' | 'Temperature' | 'Humidity'
      }

      const totalSecondaryPlots = bottomPaneVariables.length
      const targetLineClone = Object.assign({}, baseChartDataTemplate)

      targetLineClone.x = this.getDateRangeForTargetLines(mainPaneVariables)
      targetLineClone.y = [this.target, this.target]

      // const differentYAxesVariables = mainPaneVariables.filter(value => ['pH','EC','pH_quick', 'EC_quick'].includes(value));
      // if (mainPaneVariables.length>differentYAxesVariables.length) {
      //   // variables other than ph and EC are there
      // }else if (mainPaneVariables.length == differentYAxesVariables.length){
      //   // only pH and EC are there
      // }else{
      //   // only pH or EC is there
      // }
      mainPaneVariables.forEach((currentVariable, index) => {
        // console.log('this.allValues', this.allValues)
        const analyteValues = this.allValues[currentVariable]
        const clone = Object.assign({}, baseChartDataTemplate)
        const currentMarkerColors = []
        const analyteDataValues = this.allValues[currentVariable].y.map((element) => { return Math.round(parseFloat(element.value)) })
        this.allValues[currentVariable].y.forEach((measurement, innerIndex) => {
          const valueWithoutDilution = parseFloat(measurement.base_value) / parseFloat(measurement.dilution)
          const lowerWorkingRange = measurement.lower_wr
          const upperWorkingRange = measurement.upper_wr

          if (lowerWorkingRange && valueWithoutDilution <= parseFloat(lowerWorkingRange)) {
            analyteDataValues[innerIndex] = Math.round(parseFloat(lowerWorkingRange) * measurement.dilution)
            currentMarkerColors.push('#ff0000')
          } else if (upperWorkingRange && valueWithoutDilution >= parseFloat(upperWorkingRange)) {
            analyteDataValues[innerIndex] = Math.round(parseFloat(upperWorkingRange) * measurement.dilution)
            currentMarkerColors.push('##000000')
          } else {
            currentMarkerColors.push(this.chartColors[index])
          }
        })
        // console.log('analyteValues', analyteValues)
        // console.log('analyteDataValues', analyteDataValues)
        clone.x = analyteValues.x
        clone.y = analyteDataValues

        if (totalSecondaryPlots === 0) {
          targetLineClone.xaxis = clone.xaxis = 'x'
          targetLineClone.yaxis = clone.yaxis = 'y'
        } else {
          targetLineClone.xaxis = clone.xaxis = 'x' + (totalSecondaryPlots + 1).toString()
          targetLineClone.yaxis = clone.yaxis = 'y' + (totalSecondaryPlots + 1).toString()
        }
        clone.name = currentVariable
        targetLineClone.name = 'Target'

        clone.type = analyteValues.type
        targetLineClone.type = 'line'

        targetLineClone.line = { color: '#003232', width: 4 }

        if (clone.type === 'line') {
          clone.line = { width: 8 }
        }

        if (clone.type === 'scatter') {
          clone.mode = 'markers'
          clone.marker = { size: 11, color: currentMarkerColors }
        } else {
          clone.marker = { color: currentMarkerColors }
        }

        allChartPlotData.push(clone)
      })
      if (this.variablesSelected?.length < 2 && this.target && this.targetVisible) {
        allChartPlotData.push(targetLineClone)
      }
      bottomPaneVariables.forEach((currentVariable, index) => {
        const analyteValues = this.allValues[currentVariable]
        const clone = Object.assign({}, baseChartDataTemplate)

        clone.x = analyteValues.x
        clone.y = analyteValues.y

        if (totalSecondaryPlots.length === 1) {
          clone.xaxis = 'x'
          clone.yaxis = 'y'
        } else {
          if (index === 0) {
            clone.xaxis = 'x'
            clone.yaxis = 'y'
          } else {
            clone.xaxis = 'x' + (index + 1).toString()
            clone.yaxis = 'y' + (index + 1).toString()
          }
        }
        clone.name = currentVariable

        clone.type = analyteValues.type
        allChartPlotData.push(clone)
      })

      return allChartPlotData
    },
    getDateRangeForTargetLines (mainPaneVariables) {
      const allOldDates = []
      const allLatestDates = []

      mainPaneVariables.forEach((currentVariable) => {
        const dates = this.allValues[currentVariable].x.sort((a, b) => a - b)
        const oldestDate = dates[0]
        const latestDate = dates[dates.length - 1]
        allOldDates.push(oldestDate)
        allLatestDates.push(latestDate)
      })

      const oldestDate = allOldDates.sort((a, b) => a - b)[0]
      const latestDate = allLatestDates.sort((a, b) => a - b)[allLatestDates.length - 1]

      return [oldestDate, latestDate]
    },
    prepareChartLayoutData (mainPaneVariables, bottomPaneVariables) {
      // const dates = this.allValues[mainPaneVariables[0]].x
      // const dateBreaks = this.getDateBreaks(dates) // dont show dates which have no data
      const allChartLayoutData = {
        showlegend: true,
        height: 600,
        title: {
          text: this.stationsSelected[0]?.name || 'Chart'
        }
      }
      const totalPlots = bottomPaneVariables.length + 1

      const lowestXAxisLayout = {
        // rangebreaks: [{ values: dateBreaks }],
        anchor: 'y',
        domain: [
          0.0,
          1.0
        ]
      }

      const lowestYAxisLayout = {
        anchor: 'x',
        domain: [
          0.0,
          1.0
        ]
      }

      if (totalPlots === 1) {
        allChartLayoutData.xaxis = lowestXAxisLayout
        allChartLayoutData.yaxis = lowestYAxisLayout
      } else {
        const layoutIntervals = this.getLayoutIntervals([0, 0.4], bottomPaneVariables.length)
        for (let i = totalPlots; i > 0; i--) {
          if (i === 1) {
            allChartLayoutData.xaxis = lowestXAxisLayout
            allChartLayoutData.yaxis = lowestYAxisLayout
            allChartLayoutData.yaxis.domain = layoutIntervals[0]
          } else {
            let yDomain = []
            if (i === totalPlots) {
              yDomain = [0.4, 1.0] // reserve 60% for the main pane
            } else {
              yDomain = layoutIntervals[i - 1]
            }
            allChartLayoutData['xaxis' + (i).toString()] = {
              anchor: 'yaxis' + (i).toString(),
              domain: [
                0.0,
                1.0
              ],
              matches: 'x',
              showticklabels: false
            }
            allChartLayoutData['yaxis' + (i).toString()] = {
              anchor: 'xaxis' + (i).toString(),
              domain: yDomain
            }
          }
        }
      }
      return allChartLayoutData
    },
    getDateRangeForMultipleStations (oldestDate, latestDate) {
      // if (!(this.chartStart && this.chartEnd)) {
      this.stationsSelected.forEach((currentStation) => {
        let currentStationData = null
        if (this.showQuickSelect) {
          currentStationData = this.genericDataGlobal.filter((value) => value.location === currentStation)
        } else {
          currentStationData = this.analysesGlobal.filter((value) => value.station_pk === currentStation)
        }

        let unique = [...new Set(currentStationData.map(item => new Date(item.collected)))]
        unique = unique.sort((a, b) => a - b)
        if (!(oldestDate && latestDate)) {
          latestDate = unique[unique.length - 1]
          oldestDate = unique[0]
        } else {
          if (oldestDate >= unique[0]) {
            oldestDate = unique[0]
          }
          if (latestDate <= unique[unique.length - 1]) {
            latestDate = unique[unique.length - 1]
          }
        }
      })
      this.setChartDates((new Date(oldestDate)).toISOString().split('T')[0], (new Date(latestDate)).toISOString().split('T')[0])
      return [(new Date(oldestDate)).toISOString().split('T')[0], (new Date(latestDate)).toISOString().split('T')[0]]
      // } else {
      //   return [false, false]
      // }
    },
    getDateRangeForSingleStation (oldestDate, latestDate) {
      // if (!(this.chartStart && this.chartEnd)) {
      let currentStationData = null
      if (this.showQuickSelect) {
        currentStationData = this.genericDataGlobal.filter((value) => value.location === this.stationsSelected[0])
      } else {
        currentStationData = this.analysesGlobal.filter((value) => value.station_pk === this.stationsSelected[0])
      }
      let unique = [...new Set(currentStationData.map(item => new Date(item.collected)))]
      unique = unique.sort((a, b) => a - b)
      oldestDate = unique[0]
      latestDate = unique[unique.length - 1]
      this.setChartDates((new Date(oldestDate)).toISOString().split('T')[0], (new Date(latestDate)).toISOString().split('T')[0])
      return [(new Date(oldestDate)).toISOString().split('T')[0], (new Date(latestDate)).toISOString().split('T')[0]]
      // } else {
      //   return [false, false]
      // }
    },
    getDateBreaks (allDates) {
      allDates = allDates.sort((a, b) => a - b)
      let dateRange = this.getDateRangeBetweenDays(allDates[0], allDates[allDates.length - 1], 1)
      allDates.forEach((currentDate) => {
        const date = currentDate.toISOString().slice(0, 10)
        if (dateRange.includes(date)) {
          dateRange = dateRange.filter((element) => element !== date)
        }
      })
      return dateRange
    },
    getDateRangeBetweenDays (startDate, endDate, steps = 1) {
      const dateArray = []
      const currentDate = new Date(startDate)

      while (currentDate <= new Date(endDate)) {
        dateArray.push(currentDate.toISOString().slice(0, 10))
        // Use UTC date to prevent problems with time zones and DST
        currentDate.setUTCDate(currentDate.getUTCDate() + steps)
      }
      return dateArray
    },
    getLayoutIntervals (rangeArray, interval) {
      const a = rangeArray
      const difference = (a[1] - a[0]) / interval
      let start = a[0]
      const result = []
      for (let i = 0; i < interval; i++) {
        result.push([start, start + difference])
        start += difference
      }
      return result
    },
    setChartDates (oldestDate, latestDate) {
      // this.$emit('chartEnd', latestDate)
      // this.$emit('chartStart', oldestDate)
      // also emit these back to the parent component (ChartBoard)
      this.$emit('setChartStart', oldestDate)
      this.$emit('setChartEnd', latestDate)
      return [oldestDate, latestDate] // for es-lint-no-unused var rule for now
    }

  }

}
</script>

<style lang="scss" scoped>
  @import '@/styles/app.scss';
  .chartPadding {
    padding-left: 2%;
    padding-right: 2%;
 }
  .this-text {
    /* pulling in a standard color defined in app.scss */
    color: $ndt-color-5;
  }
</style>
