<template>
  <div id="mainTableDiv" class="tablePadding">
    <div>
      <!-- <br><br> -->
      <ag-grid-vue :context="{ componentParent: this }" :style="{ ...styleComputed }" class="ag-theme-alpine" :columnDefs="columnDefs" :rowSelection="rowSelection"
        :rowMultiSelectWithClick="rowMultiSelectWithClick" :suppressRowClickSelection="suppressRowClickSelection"
        :animateRows=true @selection-changed="onSelectionChanged" :rowData="rowData" @grid-ready="onGridReady"
        @cell-value-changed="onCellValueChanged" @row-value-changed="onRowValueChanged"
        :overlayLoadingTemplate="overlayLoadingTemplate"  :overlayNoRowsTemplate="overlayNoRowsTemplate"
        @first-data-rendered="onFirstDataRendered"
        :suppressColumnVirtualisation="true"
        :pagination="true"
        :suppressMenuHide="true"
        :quickFilterText="searchInput"
         >
      </ag-grid-vue>
      <RowDetailModal
      v-if="isModalVisible"
      :rowData="selectedRowData"
      @close="hideRowDetailModal"
    />
    </div>
  </div>
</template>

<script>

import GeneralMixin from '@/mixins/GeneralMixin.vue'
import { eventsBus } from '@/main'
import CustomButtonComponent from '@/components/CustomButtonComponent.vue'
import RowDetailModal from '@/components/RowDetailModal.vue'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { AgGridVue } from 'ag-grid-vue'

export default {

  name: 'TableHolder',

  components: {
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    CustomButtonComponent,
    RowDetailModal
  },

  mixins: [GeneralMixin],

  props: {
    active: {
      type: Boolean,
      // String, Number, Boolean, Array, Object
      default: false
    },
    selectedStation: {
      // type: String,
      default: null
    },
    searchInput: {
    // type: String,
      default: null
    },
    dateFilterStart: {
    // type: String,
      default: null
    },
    dateFilterEnd: {
    // type: String,
      default: null
    }
  },

  data () {
    return {
      displayTable: false,
      columnDefs: undefined,
      rowData: undefined,
      allTableData: null,
      uniqueColumnVariables: null,
      rowSelection: null,
      gridApi: null,
      rowMultiSelectWithClick: true,
      suppressRowClickSelection: true,
      uniqueStations: [],
      stationCellColors: {},
      gridColumnApi: null,
      overlayLoadingTemplate: null,
      overlayNoRowsTemplate: null,
      selectedRows: [],
      windowHeight: null,
      analytesWithUnits: {},
      isModalVisible: false,
      selectedRowData: {}
    }
  },

  computed: {
    mediaType: function () { return this.$store.getters['options/mediaType'] },
    styleComputed: function () {
      let height

      // Get the current window innerHeight for accurate viewport height
      const currentHeight = window.innerHeight

      if (this.sidebarVisible) {
      // Subtract 300px (or adjust as needed) from the current height
        height = (currentHeight - 300).toString() + 'px'
      } else {
        height = '22rem' // Fallback value
        // height = (currentHeight - 72 - 20).toString() + 'px' // Fallback value
      }

      // Return the height in a style object
      return { height: height }
    },
    stations: function () {
      return this.$store.getters['data/stations']
    }
  },

  watch: {
    analysesGlobal: {
      handler: function (oldValue, newValue) {
        if (oldValue.length > 0 || newValue.length > 0) {
          this.getUniqueStations()
          this.setupTable()
        }
        if (oldValue.length === 0 || newValue.length === 0) {
          this.gridApi.hideOverlay()
          this.gridApi.showNoRowsOverlay()
        }
      },
      deep: true
    },
    dateFilterStart: {
      handler: function (oldValue, newValue) {
        if (newValue && this.analysesGlobal.length > 0) {
          this.setupTable()
        }
      }
    },
    dateFilterEnd: {
      handler: function (oldValue, newValue) {
        if (newValue && this.analysesGlobal.length > 0) {
          this.setupTable()
        }
      }
    }
  },

  created () {
    this.logDebug(`mounted ${this.$options.name}`)
    eventsBus.$on('accountChanged', (data) => {
      if (this.gridApi) {
        this.gridApi.showLoadingOverlay()
      }
    })
    this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Please wait while your data is loading</span>'
    this.overlayNoRowsTemplate = '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;">There is no data to show.</span>'
  },

  beforeMount () {
    this.setupTable()
  },

  mounted () {
    // instead of console.log we use
    this.logDebug(`mounted ${this.$options.name}`)
    // The following is typically used in views. It logs a message to the Console database ClientEvent table
    this.logEvent('Crops tab mounted')
    this.windowHeight = window.innerHeight
    window.addEventListener('resize', this.handleResize)
    this.handleResize() // Call it initially
  },

  destroyed () {
    this.logDebug(`mounted ${this.$options.name}`)
  },

  methods: {
    hideRowDetailModal () {
      this.isModalVisible = false
    },
    viewRow (data) {
      // console.log('viewRow', data)
      this.selectedRowData = data
      this.isModalVisible = true
    },
    handleResize () {
      // const isMobileView = window.innerWidth <= 768
      // this.arrangeColumnsForMobileView(isMobileView)
    },
    adjustColumnsForMobileView (allColumns) {
      const columnsToShow = ['sample_id', 'collected', 'view'] // Columns to show in mobile view

      if (!this.sidebarVisible) { // Check if it's mobile view
        // Iterate through all columns and set the "hide" property
        return allColumns.map(column => {
          if (columnsToShow.includes(column.field)) {
            column.hide = false // Show these columns in mobile view
          } else {
            column.hide = true // Hide all other columns
          }
          return column
        })
      } else {
        return allColumns
      }
    },

    submit: function (params) {
      this.logDebug(`submit called with params ${params}`)
    },

    onFirstDataRendered (params) {
      const allColumnIds = []
      this.gridColumnApi.getColumns().forEach((column) => {
        allColumnIds.push(column.getId())
      })
      const skipHeader = false
      if (this.sidebarVisible) {
        console.log('autoSizeColumns')
        this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader)
      } else {
        // Calculate the available width based on window inner width
        const availableWidth = window.innerWidth - 40

        // Use the sizeColumnsToFit method with the calculated width
        this.gridApi.sizeColumnsToFit(availableWidth)
      }
    },
    handleAssignStationInput () {
      this.selectedRows.forEach((row) => {
        row.station_name = this.selectedStation?.name || null
        row.station_pk = this.selectedStation?.id || null
      })
      this.gridApi.applyTransaction({ update: this.selectedRows })
      this.gridApi.redrawRows()
      // this.notifyUserAndDeselectRows()
    },
    handleSaveAssignedStation () {
      this.$emit('assignStation', this.selectedRows)
      this.notifyUserAndDeselectRows()
    },
    notifyUserAndDeselectRows () {
      // notify here
      // this.gridApi.undoCellEditing()
      this.gridApi.deselectAll()
    },
    handleDeleteRows () {
      this.selectedRows.forEach((row) => {
        this.$api.deleteSampleDetail(row.sample_pk).then((response) => { })
      })
      this.gridApi.applyTransaction({ remove: this.selectedRows })
      this.gridApi.redrawRows()
    },
    setupTable () {
      this.formatDataforTable()
      this.getUniqueColumnVariables()
      let allColumns = []
      this.getUniqueStations()
      this.calculateCellColorsForStations()
      const hiddenColumns = ['station_pk', 'sample_pk', 'device', 'data_id', 'analyte_unit', 'analysis_pk']
      this.uniqueColumnVariables = this.orderColumns()
      this.uniqueColumnVariables.forEach((variable) => {
        const commonColumnConfig = { resizable: true, sortable: true, filter: true, field: variable }
        if (variable === 'collected') {
          commonColumnConfig.valueFormatter = this.dateFormatter
          commonColumnConfig.sort = 'desc'
          allColumns.push(commonColumnConfig)
        } else if (variable === 'sample_id') {
          commonColumnConfig.checkboxSelection = true
          commonColumnConfig.headerCheckboxSelection = true
          if (this.sidebarVisible) {
            commonColumnConfig.pinned = 'left'
          }
          allColumns.push(commonColumnConfig)
        } else if (variable === 'station_name') {
          allColumns.push({
            // cellStyle: params => {
            //   return { backgroundColor: params.value ? this.stationCellColors[params.value] : 'none', 'border-radius': '25px' }
            // },
            // editable: true,
            resizable: true,
            field: variable,
            sortable: true,
            filter: true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
              values: this.uniqueStations
            }
          })
        } else if (variable === 'device_name') {
          commonColumnConfig.headerName = 'Device-Name'
          allColumns.push(commonColumnConfig)
        } else if (variable in this.analytesWithUnits) {
          if (this.analytesWithUnits[variable].length > 0) { // for pH
            const analyteUnit = this.analytesWithUnits[variable]
            variable = variable === 'Electroconductivity' ? 'EC' : variable
            commonColumnConfig.headerName = variable + ' (' + analyteUnit + ')'
          } else {
            commonColumnConfig.headerName = variable
          }
          allColumns.push(commonColumnConfig)
        } else {
          if (variable === 'comment') {
            commonColumnConfig.editable = true
          } else if (hiddenColumns.includes(variable)) {
            commonColumnConfig.hide = true
          }
          allColumns.push(commonColumnConfig)
        }
      })
      allColumns.push({
        headerName: 'View',
        field: 'view',
        cellRenderer: 'CustomButtonComponent', // Reference the custom button component
        width: 100, // Optional: set a fixed width for the button column
        hide: true
      })
      allColumns = this.adjustColumnsForMobileView(allColumns)
      this.columnDefs = allColumns
      this.rowData = [...new Set(this.allTableData)]

      // if (this.samples.length > 0) {
      this.rowSelection = 'multiple'
      // this.displayTable = true
      this.rowMultiSelectWithClick = true
      // }
    },
    orderColumns () {
      const fixedColumnOrder = ['collected', 'dilution', 'station_name', 'device_name', 'comment', 'pH',
        'Electroconductivity', 'Nitrate', 'Ammonium', 'Nitrite', 'Phosphate', 'Potassium', 'Calcium',
        'Magnesium', 'Sulphate', 'Sodium', 'Chlorine', 'Bicarbonate', 'Iron',
        'Copper', 'Zinc', 'Manganese', 'Molybdenum', 'Boron', 'Aluminium', 'Silicon']
      this.uniqueColumnVariables = Array.from(this.uniqueColumnVariables).sort((a, b) => fixedColumnOrder.indexOf(a) - fixedColumnOrder.indexOf(b))
      return this.uniqueColumnVariables
    },
    // deviceValueFormatter (params) {
    //   if (params.value === 3) {
    //     return 'Lab Report'
    //   }
    //   return 'Analyzer'
    // },
    dateFormatter (params) {
      return new Date(params.value).toLocaleString('en-Ca', { hour12: false })
    },
    getUniqueStations () {
      const uniqueStations = this.stationsGlobal.map(item => item.name)
      // const unique = [...new Set(this.allTableData.map(item => item.station_name))]
      this.uniqueStations = uniqueStations
    },
    formatDataforTable () {
      const allTableData = []
      const filteredAnalysesbyDate = this.filterDataByDateRange(this.analysesGlobal)
      filteredAnalysesbyDate.forEach((sample) => {
        if (sample.device !== this.webAppPk) {
          const currentRow = {}
          currentRow.collected = sample.collected
          // currentRow.location_name = sample.location_name
          currentRow.sample_pk = sample.sample
          currentRow.analysis_pk = sample.id
          currentRow.station_name = sample.station_name
          currentRow.sample_id = sample.sample_id
          currentRow.comment = sample.tag
          currentRow.data_id = sample.data_id
          currentRow.dilution = sample.dilution
          const station = this.stationsGlobal.filter(item => item.name === sample.station_name)[0]
          currentRow.station_pk = null
          if (station) {
            currentRow.station_pk = station.id
          }
          currentRow.device = sample.device
          currentRow.device_name = sample.device_id
          sample.analysis_analyte.forEach((analysis) => {
            let variableName = 'analyte_long_name'
            if (this.unit === 'second') {
              variableName = 'analyte_second_rep'
            }
            const analyteUnitName = this.analyteUnitName(analysis)
            this.analytesWithUnits[analysis[variableName]] = analyteUnitName// analysis.analyte_unit
            // console.log('analysis.lower_wr and upper_wr', analysis.lower_wr, analysis.upper_wr)
            analysis.value = parseFloat(analysis.value)
            currentRow.dilution = parseFloat(currentRow.dilution)
            let finalValueToShow = analysis.value
            if (this.unit === 'ppm' || this.unit === 'second') {
              finalValueToShow = Math.round(finalValueToShow)
            }
            // if (analysis.lower_wr && analysis.upper_wr && analyteUnitName === 'ppm') {
            if (analysis.lower_wr && analysis.upper_wr) {
              // important note: valueWithoutDilution is calculated in PPM (using analysis.base_value), then its compared across the working range
              const valueWithoutDilution = parseFloat(analysis.base_value) / currentRow.dilution
              const lowerWorkingRange = parseFloat(analysis.lower_wr)
              const upperWorkingRange = parseFloat(analysis.upper_wr)
              if (valueWithoutDilution <= lowerWorkingRange) {
                if (valueWithoutDilution === 0) {
                  currentRow[analysis[variableName]] = 0
                } else {
                  currentRow[analysis[variableName]] = '< ' + this.unitConv(analysis.analyte_name, lowerWorkingRange * currentRow.dilution)
                }
              } else if (valueWithoutDilution >= upperWorkingRange) {
                currentRow[analysis[variableName]] = '> ' + this.unitConv(analysis.analyte_name, upperWorkingRange * currentRow.dilution)
              } else {
                currentRow[analysis[variableName]] = finalValueToShow
              }
            } else {
              currentRow[analysis[variableName]] = finalValueToShow
            }
            currentRow.analyte_unit = analyteUnitName// analysis.analyte_unit
            allTableData.push(currentRow)
          })
        }
      })
      this.allTableData = allTableData
    },
    filterDataByDateRange (data) {
      // Convert the start and end dates to Date objects
      if (!this.dateFilterStart || !this.dateFilterEnd) {
        return data
      }
      const startDate = new Date(this.dateFilterStart)
      const endDate = new Date(this.dateFilterEnd)

      // Filter the data array
      return data.filter(sample => {
        // Convert the 'collected' field to a Date object
        const collectedDate = new Date(sample.collected)

        // Check if the collectedDate is within the date range
        return collectedDate >= startDate && collectedDate <= endDate
      })
    },
    getUniqueColumnVariables () {
      const uniqueColumnVariables = new Set()
      this.allTableData.forEach((row) => {
        Object.keys(row).forEach((key) => {
          uniqueColumnVariables.add(key)
        })
      })
      this.uniqueColumnVariables = uniqueColumnVariables
    },
    onSelectionChanged () {
      const selectedRows = this.gridApi.getSelectedRows()
      this.selectedRows = selectedRows
      this.$emit('setSelectedRows', selectedRows)
      // return selectedRows // for es lint-no-unused-var
      // emit these back for batch operations
    },
    onGridReady (params) {
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
      // if (this.globalAnalyses.length < 1) {
      //   this.gridApi.showLoadingOverlay()
      // }
      // if (this.searchInput && this.searchInput.length > 0) {
      //   this.gridApi.setQuickFilter(this.searchInput)
      // }
      // this.gridColumnApi.autoSizeAllColumns()
    },
    downloadSelectedRows () {
      this.setCorrectStationPK(this.selectedRows)
      const columnsToExport = []
      const excludedColumns = ['analyte_unit', 'sample_id', 'sample_pk', 'station_pk', 'device', 'analysis_pk']
      this.uniqueColumnVariables.forEach((column) => {
        if (!excludedColumns.includes(column)) {
          columnsToExport.push(column)
        }
      })
      this.gridApi.exportDataAsCsv({ onlySelected: true, columnKeys: columnsToExport })
      this.notifyUserAndDeselectRows()
    },
    setCorrectStationPK (rows) {
      const uniqueStations = Object.assign({}, ...this.stationsGlobal.map((item) => ({ [item.name]: item.id })))
      rows.forEach((row) => {
        row.station_pk = uniqueStations[row.station_name]
      })
      if (rows.length > 0) {
        this.gridApi.applyTransaction({ update: rows })
        this.gridApi.redrawRows()
      }
      return rows
    },
    onCellValueChanged (event) {
      this.selectedRows = this.setCorrectStationPK(this.selectedRows)
      event.data.station_pk = this.setCorrectStationPK([event.data])[0].station_pk
      // emit this back for updation of any value in a row
      if (event.column.colId === 'comment') {
        this.$emit('updateTag', event.data)
      } else if (event.column.colId === 'station_name') {
        this.$emit('assignStation', event.data)
        // this.gridApi.deselectAll()
      }
    },
    onRowValueChanged (event) {
      // not needed for now
      // const data = event.data
      return event.data // for es lint-no-unused-var
    },
    generateAdditionalColors (requiredColors) {
      const colors = []
      while (colors.length < requiredColors) {
        const newColor = `rgb(${rand(0, 255)}, ${rand(0, 255)}, ${rand(0, 255)})`
        colors.push(newColor)
        this.chartColors.push(newColor)
      }
      // random number generator
      function rand (from, to) {
        return ~~(Math.random() * (to - from)) + from
      }
    },
    calculateCellColorsForStations () {
      // const colors = [ // [Google Material Design color hex codes(100) ]https://gist.github.com/kiley0/756bf555c5a7ae17b8d03596ae364712
      //   '#F44336', '#FFEBEE', '#FFCDD2', '#EF9A9A', '#E57373',
      //   '#EF5350', '#F44336', '#E53935', '#D32F2F', '#C62828',
      //   '#B71C1C', '#FF8A80', '#FF5252', '#FF1744', '#D50000',
      //   '#E91E63', '#FCE4EC', '#F8BBD0', '#F48FB1', '#F06292',
      //   '#EC407A', '#E91E63', '#D81B60', '#C2185B', '#AD1457',
      //   '#880E4F', '#FF80AB', '#FF4081', '#F50057', '#C51162',
      //   '#9C27B0', '#F3E5F5', '#E1BEE7', '#CE93D8', '#BA68C8',
      //   '#AB47BC', '#9C27B0', '#8E24AA', '#7B1FA2', '#6A1B9A',
      //   '#4A148C', '#EA80FC', '#E040FB', '#D500F9', '#AA00FF',
      //   '#673AB7', '#EDE7F6', '#D1C4E9', '#B39DDB', '#9575CD',
      //   '#7E57C2', '#673AB7', '#5E35B1', '#512DA8', '#4527A0',
      //   '#311B92', '#B388FF', '#7C4DFF', '#651FFF', '#6200EA',
      //   '#3F51B5', '#E8EAF6', '#C5CAE9', '#9FA8DA', '#7986CB',
      //   '#5C6BC0', '#3F51B5', '#3949AB', '#303F9F', '#283593',
      //   '#1A237E', '#8C9EFF', '#536DFE', '#3D5AFE', '#304FFE',
      //   '#2196F3', '#E3F2FD', '#BBDEFB', '#90CAF9', '#64B5F6',
      //   '#42A5F5', '#2196F3', '#1E88E5', '#1976D2', '#1565C0',
      //   '#0D47A1', '#82B1FF', '#448AFF', '#2979FF', '#2962FF',
      //   '#03A9F4', '#E1F5FE', '#B3E5FC', '#81D4FA', '#4FC3F7',
      //   '#29B6F6', '#03A9F4', '#039BE5', '#0288D1', '#0277BD']
      // eslint-disable-next-line
      let colors = ['#64F08C', '#78AB82', '#D6FADC', '#3B4472', '#00DAB2',
        '#00BFD3', '#00A1E1', '#545B8C', '#ECEEFF', '#8C8EB1',
        '#3C4A3E', '#A9AE8A', '#68EDCB', '#E8EAD8', '#78814E',
        '#C9A74E', '#907419', '#719A78', '#EEFDF0', '#9FAFA1',
        '#FF990F', '#AEEB77', '#FFDE82', '#FF6A6F', '#00D395',
        '#1ABA5B']
      // uncomment the below code to generate a random colors array if the above are not sufficient
      while (colors.length < this.uniqueStations.length) {
        colors.push(`rgb(${rand(0, 255)}, ${rand(0, 255)}, ${rand(0, 255)})`)
      }
      // random number generator
      function rand (from, to) {
        return ~~(Math.random() * (to - from)) + from
      }
      const cellColors = {}
      this.uniqueStations.forEach((station, index) => {
        cellColors[station] = colors[index]
      })
      this.stationCellColors = cellColors
    }

  }

}
</script>

<style lang="scss" scoped>
  @import '@/styles/app.scss';
  // @import "~ag-grid-community/styles/ag-grid.css";
  // @import "~ag-grid-community/styles/ag-theme-alpine.css";

  .this-text {
    /* pulling in a standard color defined in app.scss */
    color: $ndt-color-5;
  }

  .ag-theme-alpine {
    --ag-font-size: 12px;
  }

 .tablePadding {
    margin-left: 46px;
    margin-top: 2%;
    margin-right: 46px;
 }

  .this-form-select {
    width: 204px;
    height: 35px;    /* changed from 44.21 */
    background: #053C37;
    border: 1px solid #002323;
    border-radius: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: #FFFFFF;
    margin-top: 18px;
    margin-bottom: 16px;
  }

  .this-table-outline {
    padding-top: 32px;
    border: 2px solid black;
    height: 453px;
    background: $ndt-debug-color;
    margin-left: $ndt-main-panel-left-margin;
    margin-right: $ndt-main-panel-right-margin;
  }

  .this-button-done-small {
    width: 110px;
    height: 31px;
    border: 1px solid $ndt-color-5;
    background: $ndt-color-5;
    border-radius: 72px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: $ndt-color-2;
  }
  @media (max-width: 768px) {
    .tablePadding {
      margin-left: 20px;
      margin-top: 2%;
      margin-right: 20px;
   }
  }
</style>
