<template>
  <div>

    <b-card class="ndt-borderless-card" style="margin-top: -1rem">
      <b-card-text>
        <p class="text-left">
        Your analytical data is currently displayed in units of <strong>{{ unitName }}</strong>.
        <p/>
        <b-form-group label="Change unit:">
          <b-form-radio v-model="unitChoice" @change="setUnit()" name="choose-unit" value="ppm">Mass units: ppm or ppb of the analyte</b-form-radio>
          <b-form-radio v-model="unitChoice" @change="setUnit()" name="choose-unit" value="mmol">Molar units: mmol/L or micromoles/L of the analyte</b-form-radio>
          <b-form-radio v-model="unitChoice" @change="setUnit()" name="choose-unit" value="second">Mass equivalent units: ppm or ppb of the elemental equivalent of the analyte</b-form-radio>
        </b-form-group>
      </b-card-text>
      <span class="ml-2">
        <button class="ndt-button-2 ndt-bg-6 ndt-text-light" @click="$emit('closeMe')">
          Ok
        </button>
      </span>
    </b-card>

  </div>
</template>

<script>
import GeneralMixin from '@/mixins/GeneralMixin.vue'

export default {
  name: 'UnitManage',

  mixins: [GeneralMixin],

  data () {
    return {
      unitChoice: this.$store.getters['options/unit'],
      location: 'UnitManage.vue',
      errorText: null,
      user: this.$store.getters['auth/user']
    }
  },

  methods: {
    setUnit: function () {
      this.$store.dispatch('options/setUnit', this.unitChoice)
      this.$store.dispatch('data/setUnit', this.unitChoice)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
