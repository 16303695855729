<template>
  <div>
    <svg width="14" height="23" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.3">
    <path d="M2.625 5.375C4.07422 5.375 5.25 4.19922 5.25 2.75C5.25 1.30078 4.07422 0.125 2.625 0.125C1.17578 0.125 0 1.30078 0 2.75C0 4.19922 1.17578 5.375 2.625 5.375ZM2.625 14.125C4.07422 14.125 5.25 12.9492 5.25 11.5C5.25 10.0508 4.07422 8.875 2.625 8.875C1.17578 8.875 0 10.0508 0 11.5C0 12.9492 1.17578 14.125 2.625 14.125ZM5.25 20.25C5.25 18.8008 4.07422 17.625 2.625 17.625C1.17578 17.625 0 18.8008 0 20.25C0 21.6992 1.17578 22.875 2.625 22.875C4.07422 22.875 5.25 21.6992 5.25 20.25ZM11.375 5.375C12.8242 5.375 14 4.19922 14 2.75C14 1.30078 12.8242 0.125 11.375 0.125C9.92578 0.125 8.75 1.30078 8.75 2.75C8.75 4.19922 9.92578 5.375 11.375 5.375ZM14 11.5C14 10.0508 12.8242 8.875 11.375 8.875C9.92578 8.875 8.75 10.0508 8.75 11.5C8.75 12.9492 9.92578 14.125 11.375 14.125C12.8242 14.125 14 12.9492 14 11.5ZM11.375 22.875C12.8242 22.875 14 21.6992 14 20.25C14 18.8008 12.8242 17.625 11.375 17.625C9.92578 17.625 8.75 18.8008 8.75 20.25C8.75 21.6992 9.92578 22.875 11.375 22.875Z" fill="black"/>
    </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'EditIcon'
}
</script>

<style lang="scss" scoped>
  @import '@/styles/app.scss';
</style>
