<template>
  <div @click="$emit('clicked', true)">
    <template>
      <div class="this-button-large d-flex">
        <div style="margin-left: 10px"><slot></slot></div>
        <div style="margin-left: 10px; margin-top: -4px">
          <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_334_283)">
          <path d="M5.90977 20.9143C2.90977 20.9143 0.509766 18.5143 0.509766 15.5143C0.509766 13.1893 2.00977 11.1643 4.10977 10.4518C4.10977 10.3393 4.10977 10.2268 4.10977 10.1143C4.10977 6.81426 6.77227 4.11426 10.1098 4.11426C12.3223 4.11426 14.2723 5.35176 15.2848 7.15176C15.8473 6.73926 16.5598 6.51426 17.3098 6.51426C19.2973 6.51426 20.9098 8.12676 20.9098 10.1143C20.9098 10.6018 20.7973 11.0143 20.6473 11.4268C22.8598 11.8768 24.5098 13.8268 24.5098 16.1143C24.5098 18.7768 22.3348 20.9143 19.7098 20.9143H5.90977ZM8.87227 12.7768C8.49727 13.1518 8.49727 13.7143 8.87227 14.0518C9.20977 14.4268 9.77227 14.4268 10.1098 14.0518L11.6098 12.5893V17.6143C11.6098 18.1393 11.9848 18.5143 12.5098 18.5143C12.9973 18.5143 13.4098 18.1393 13.4098 17.6143V12.5893L14.8723 14.0518C15.2098 14.4268 15.7723 14.4268 16.1098 14.0518C16.4848 13.7143 16.4848 13.1518 16.1098 12.7768L13.1098 9.77676C12.7723 9.43926 12.2098 9.43926 11.8723 9.77676L8.87227 12.7768Z" fill="white"/>
          </g>
          <defs>
          <clipPath id="clip0_334_283">
          <rect width="24" height="24" fill="white" transform="translate(0.509766 0.114258)"/>
          </clipPath>
          </defs>
          </svg>
        </div>
      </div>
    </template>
    <!-- <template v-else>
      <div class="this-button-large d-flex">
        <div><slot></slot></div>
        <div><CloudIcon/></div>
      </div>
    </template> -->
  </div>
</template>

<script>
import GeneralMixin from '@/mixins/GeneralMixin.vue'
// import CloudIcon from '@/assets/CloudIcon.vue'

export default {
  name: 'CloudIcon',

  components: {
    // CloudIcon
  },

  mixins: [GeneralMixin],

  props: {
    type: {
      type: String,
      default: null
    },
    colour: {
      type: String,
      default: null
    },
    fill: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    darkMode: {
      type: Boolean,
      default: false
    },
    iconPosition: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: true
    }
  }
}

</script>

<style lang="scss" scoped>
@import '@/styles/app.scss';

.this-button-large {
  /* Style=Primary, Small=False, Dark mode=False, Icon position=Leading */
  padding-top: 10px;
  width: 117px;
  height: 40px;
  /* Lighter green */
  background: #0D3C3C;
  /* Lighter green */
  border: 1px solid #0D3C3C;
  border-radius: 72px;
  /* text */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $ndt-color-1;
}

.this-button-small {
  /* Style=Primary, Small=True, Dark mode=False, Icon position=Trailing */
  width: 109px;
  height: 32px;
  background: #0D3C3C;
  /* Lighter green */
  border: 1px solid #0D3C3C;
  border-radius: 144px;
  /* Button */
  width: 49px;
  height: 23px;
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 150%;
  /* White */
  color: #FFFFFF;
}
</style>
