<template>
  <div>
    <template v-if="isActive">
      <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" fill="none" viewBox="0 0 33 33" x="0px" y="0px"  width="40" height="40">
        <path d="M26.75,13.63H25V9.27a1.37,1.37,0,0,0-1.35-1.4H5.49a1.38,1.38,0,0,0-1.36,1.4V20.14a1.38,1.38,0,0,0,1.36,1.4h8.85v1.09h-2.1a.75.75,0,1,0,0,1.5h5.7a.75.75,0,0,0,0-1.5h-2.1V21.54h4.94V23a1.12,1.12,0,0,0,1.12,1.11h4.85A1.12,1.12,0,0,0,27.87,23V14.75A1.12,1.12,0,0,0,26.75,13.63Zm-6,1.12V20H5.63V9.37H23.51v4.26H21.9A1.12,1.12,0,0,0,20.78,14.75Zm5.59,7.88H22.28v-7.5h4.09Z" fill="#64F08C"/><path d="M23.79,22h1.06a.75.75,0,0,0,0-1.5H23.79a.75.75,0,0,0,0,1.5Z" fill="#64F08C"/><path d="M7.82,12.82a.75.75,0,0,0,0,1.5A3.68,3.68,0,0,1,11.49,18,.75.75,0,0,0,13,18,5.18,5.18,0,0,0,7.82,12.82Z" fill="#64F08C"/><path d="M7.82,14.82a.75.75,0,0,0,0,1.5A1.67,1.67,0,0,1,9.49,18,.75.75,0,0,0,11,18,3.17,3.17,0,0,0,7.82,14.82Z" fill="#64F08C"/><path d="M7.82,17.09a.9.9,0,1,0,.9.9A.89.89,0,0,0,7.82,17.09Z" fill="#64F08C"/>
        </svg>
    </template>
    <template v-else>
      <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" fill="none" viewBox="0 0 33 33" x="0px" y="0px"  width="40" height="40">
        <path d="M26.75,13.63H25V9.27a1.37,1.37,0,0,0-1.35-1.4H5.49a1.38,1.38,0,0,0-1.36,1.4V20.14a1.38,1.38,0,0,0,1.36,1.4h8.85v1.09h-2.1a.75.75,0,1,0,0,1.5h5.7a.75.75,0,0,0,0-1.5h-2.1V21.54h4.94V23a1.12,1.12,0,0,0,1.12,1.11h4.85A1.12,1.12,0,0,0,27.87,23V14.75A1.12,1.12,0,0,0,26.75,13.63Zm-6,1.12V20H5.63V9.37H23.51v4.26H21.9A1.12,1.12,0,0,0,20.78,14.75Zm5.59,7.88H22.28v-7.5h4.09Z" fill="#158D38"/><path d="M23.79,22h1.06a.75.75,0,0,0,0-1.5H23.79a.75.75,0,0,0,0,1.5Z" fill="#158D38"/><path d="M7.82,12.82a.75.75,0,0,0,0,1.5A3.68,3.68,0,0,1,11.49,18,.75.75,0,0,0,13,18,5.18,5.18,0,0,0,7.82,12.82Z" fill="#158D38"/><path d="M7.82,14.82a.75.75,0,0,0,0,1.5A1.67,1.67,0,0,1,9.49,18,.75.75,0,0,0,11,18,3.17,3.17,0,0,0,7.82,14.82Z" fill="#158D38"/><path d="M7.82,17.09a.9.9,0,1,0,.9.9A.89.89,0,0,0,7.82,17.09Z" fill="#158D38"/>
        </svg>
    </template>
  </div>
</template>

<script>
export default {
  name: 'DeviceIcon',

  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/app.scss';
</style>
    <!-- <a href="https://www.flaticon.com/free-icons/gameboy" title="gameboy icons">Gameboy icons created by Flat Icons - Flaticon</a> -->
