import axios from 'axios'
import api from '@/utils/api.js'
import router from '@/router'

const state = {
  status: '',
  authToken: '',
  refreshToken: '',
  user: ''
}

const mutations = {
  auth_request (state) {
    state.status = 'loading'
  },
  auth_refresh (state, token) {
    state.authToken = token
  },
  auth_success (state, { token, user, refreshToken }) {
    state.status = 'success'
    state.authToken = token
    state.user = user
    state.refreshToken = refreshToken
  },
  auth_error (state) {
    state.status = 'error'
  },
  auth_refresh_error (state) {
    state.status = 'error'
  },
  logout (state) {
    state.status = ''
    state.authToken = ''
    state.refreshToken = ''
  }
}

const getters = {
  loggedIn: state => !!state.authToken,
  authStatus: state => state.status,
  authToken: state => state.authToken,
  refreshToken: state => state.refreshToken,
  user: state => state.user
}

const actions = {
  login ({ commit }, creds) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      const postdata = { username: creds.username, password: creds.password }
      api.postLogin(postdata)
        .then(resp => {
          const token = resp.access
          const refreshToken = resp.refresh
          const user = creds.username
          axios.defaults.headers.common.Authorization = 'Bearer ' + token
          commit('auth_success', { token, user, refreshToken })
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error')
          reject(err)
        })
    })
  },
  refresh ({ getters, commit }) {
    return new Promise((resolve, reject) => {
      const refreshToken = getters.refreshToken
      const postdata = { refresh: refreshToken }
      api.postRefreshToken(postdata)
        .then(resp => {
          const token = resp.access
          axios.defaults.headers.common.Authorization = 'Bearer ' + token
          commit('auth_refresh', { token })
          resolve(resp)
        })
        .catch(err => {
          commit('logout')
          router.push('login')
          reject(err)
        })
    })
  },
  logout ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('logout')
      delete axios.defaults.headers.common.Authorization
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
