<template>
  <div>
    <div class="this-person-dropdown-container">
      <!-- <div class="this-person-icon"><AvatarIcon2/></div> -->
      <div class="this-box"><b-icon icon="gear-fill" style="color: white;" font-scale="1"></b-icon></div>
      <div class="this-person-dropdown-content">
        <div class="this-person-dropdown-content-item ndt-pointer" @click="userLogout">Logout</div>
        <div class="this-person-dropdown-content-item ndt-pointer" @click="unitModalVisible = true">Change Units</div>
        <div class="this-person-dropdown-content-item ndt-pointer" @click="passwordModalVisible = true">Change Password</div>
      </div>
    </div>

    <b-modal
      v-model="unitModalVisible"
      size="sm"
      centered
      content-class="ndt-card-1"
      scrollable
      no-close-on-backdrop
      no-close-on-escape
      hide-header-close
      hide-header
      hide-footer
    >
      <UnitManage @closeMe="unitModalVisible = false" />
    </b-modal>

    <b-modal
      v-model="passwordModalVisible"
      size="lg"
      content-class="ndt-card-1"
      centered
      scrollable
      no-close-on-backdrop
      no-close-on-escape
      hide-header-close
      hide-header
      hide-footer
    >
      <PasswordManage @closeMe="passwordModalVisible = false" />
    </b-modal>

  </div>
</template>

<script>
// import AvatarIcon2 from '@/assets/AvatarIcon2.vue'
import UnitManage from '@/components/UnitManage.vue'
import PasswordManage from '@/components/PasswordManage.vue'

export default {
  name: 'PersonMenu',

  components: {
    // AvatarIcon2,
    UnitManage,
    PasswordManage
  },

  data () {
    return {
      unitModalVisible: false,
      passwordModalVisible: false
    }
  },

  methods: {
    userLogout: function (event) {
      this.$store.dispatch('auth/logout')
        .then(() => {
          this.$router.push('/')
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/styles/app.scss';

  .this-person-icon {
    background-size:40px 40px;
    /* width: 40px;
    height: 40px;
    background: green;
    border-radius: 8px; */
  }

  .this-person-text {
    /*width: 131px;
    height: 19px;*/
    margin-top: 10.5px;
    margin-left: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
  }

  .this-person-dropdown-content {
    display: none;
    position: absolute;
    left: 30px;
    top: -20px;
    background-color: $ndt-color-4;
    width: 130px;
    height: 72px;
    border: 1px solid $ndt-color-4;
    border-radius: 0.25rem;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 0px 2px 15px 2px;
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: $ndt-color-1;
    z-index: 5000;
    overflow: visible;
  }

  @media (max-width: $tablet-breakpoint) {
    .this-person-dropdown-content {
      top: 0px;
    }
  }

  .this-person-dropdown-content-item {
    cursor: pointer;
    margin-top: 8px;
    margin-left: 8px;
  }

  .this-person-dropdown-container {
    position: relative;
    /* padding-right: 40px; */
  }

  .this-person-dropdown-container:hover .this-person-dropdown-content {
    display: block;
  }

  .this-box {
    background: $ndt-color-3;
    border: 1px solid $ndt-color-3;
    width: 35px;
    height: 35px;
    border-radius: 5px;
    padding-top: 5px;
  }
</style>
