<template>
<div class="ndt-main-panel" :class="{ 'ndt-sidebar-expanded': sidebarVisible, 'ndt-sidebar-collapsed': sidebarCollapsed }">
  <div class="ndt-debug" v-if="debugOn">
    {{getParentFileName}} / <strong>{{getFileName}}</strong>
  </div>

  <div class="d-flex">
    <div class="ndt-top-title d-flex">
      <div class="ndt-icon-container">
        <div class="this-mobile-element">
          <PersonMenu/>
        </div>
        <ChartIcon class="this-desktop-element" :isActive="true"/>
      </div>
      <div class="ndt-title-text">
        Charts
      </div>
    </div>
    <div class="ndt-top-button d-flex ml-auto">
      <div v-if = "!sidebarVisible" class="this-datasource">
        <b-dropdown id="data source dropdown" :text="dataSourceName"  class="this-button-done-small">
          <b-dropdown-item class="this-datasource-dropdown-link"
          @click="handleDatasourceDropdown('analyzer')">Analyzer</b-dropdown-item>
          <b-dropdown-item class="this-datasource-dropdown-link"
          @click="handleDatasourceDropdown('lab')">Lab Reports</b-dropdown-item>
          <b-dropdown-item class="this-datasource-dropdown-link"
          @click="handleDatasourceDropdown('quick')">ph/EC</b-dropdown-item>
        </b-dropdown>
        <!-- <div class="this-button-done-small">{{ dataSourceName }}</div>
        <div class="this-datasource-dropdown-content">
          <ul>
            <li class="this-datasource-dropdown-link" @click="handleDatasourceDropdown('analyzer')">
              Analyzer Data
            </li>
            <li class="this-datasource-dropdown-link"  @click="handleDatasourceDropdown('lab')">
              Lab Data
            </li>
            <li class="this-datasource-dropdown-link" @click="handleDatasourceDropdown('quick')">
              Quick logger
            </li>
          </ul>
        </div> -->
      </div>
      <div class="this-mobile-element">
        <AccountSelector/>
      </div>
      <!-- <div class="this-reset-button this-desktop-element" @click="resetChart()">
        <b-icon icon="arrow-counterclockwise" font-scale="1.5"></b-icon>
        Reset chart
      </div> -->
      <div class="this-upload this-desktop-element" @clicked="resetChart()">
        <div class="this-button-large d-flex">Reset
        <!-- <div style="margin-left: 10px"></div> -->
      </div>
          <!-- <MultiButton @clicked="resetChart()">Reset</MultiButton> -->
        </div>
    </div>
  </div>

  <div class="this-chart-menu d-flex flex-wrap">
    <div class="this-chart-menu-item this-chart-menu-right-margin">
      <div class="this-chart-menu-text">
        Station
      </div>
      <b-dropdown style="background: #053C37" class="this-form-select border-0" :text="getstationsSelectedInfo()" checkbox-menu allow-focus>
      <b-dropdown-form>
        <b-form-checkbox-group
      v-model="stationsSelected"
      :options="stationsGlobal"
      value-field="id"
      text-field="name"
    ></b-form-checkbox-group>
      </b-dropdown-form>
    </b-dropdown>
    </div>
    <div class="this-chart-menu-item this-chart-menu-right-margin">
      <div v-if="showAnalyzerSelect || showLabSelect" class="this-chart-menu-text">
        Nutrients
      </div>
      <div v-if="showQuickSelect" class="this-chart-menu-text" style="font-size: 14px;">
        Quick logger variables
      </div>
      <b-dropdown v-if="variableMultiple" class="this-form-select" :text="getVariablesSelectedInfo()" checkbox-menu allow-focus>
      <b-dropdown-form>
        <b-form-checkbox-group
          v-if="(showAnalyzerSelect || showLabSelect)"
          v-model="variablesSelected"
          :options="variables"
          stacked
          >
        </b-form-checkbox-group>
        <b-form-checkbox-group
          v-if="showQuickSelect"
          v-model="variablesSelected"
          :options="generics"
          stacked
          >
        </b-form-checkbox-group>
      </b-dropdown-form>
    </b-dropdown>
    <b-form-select
    class="this-form-select" title="Select Nutrients"
    v-if ="(showAnalyzerSelect || showLabSelect) && !variableMultiple"
    v-model="variablesSelected[0]"
    :options="variables"></b-form-select>
    <b-form-select
    class="this-form-select" title="Select Nutrients"
    v-if ="showQuickSelect && !variableMultiple"
    v-model="variablesSelected[0]"
    :options="generics"></b-form-select>
    <!-- <b-form-select
        :multiple="variableMultiple"
        :select-size="variableSelectSize"
        class="this-form-select this-variables-form-select"
        v-model="variablesSelected"
        @mouseover.native="variableSelectSize=4, selectExpanded=true"
        @mouseleave.native="handleOnMouseLeave('variable')"
      >
        <option :value="null">{{ variablesMessage }}</option>
        <template v-if="showAnalyzerSelect || showLabSelect">
          <option v-for="variable in variables" :value="variable" :key="variable">
            {{ variable }}
          </option>
        </template>
        <template v-if="showQuickSelect">
          <option v-for="variable in generics" :value="variable" :key="variable">
            {{ variable }}
          </option>
        </template>
      </b-form-select> -->
    </div>
    <div class="this-chart-menu-item this-chart-menu-right-margin">
      <div class="this-chart-menu-text">
        Chart Type
      </div>
      <b-form-select
        class="this-form-select this-chart-form-select"
        v-model="chartType"
      >
        <option :value="'bar'">Bar</option>
        <option :value="'line'">Line</option>
        <option :value="'scatter'">Scatter</option>
      </b-form-select>
    </div>
    <div class="this-chart-menu-item this-chart-menu-right-margin">
      <div class="this-chart-menu-text">
        Date range
      </div>
      <div class="d-flex">
        <div class="mr-2">
          <input
            type="date"
            class="this-form-datepicker"
            id="startdate"
            v-model="chartStart"
            placeholder="Choose date"
          />
        </div>
        <div>
          <input
            type="date"
            class="this-form-datepicker"
            id="enddate"
            v-model="chartEnd"
            placeholder="Choose date"
          />
        </div>
      </div>
    </div>
    <div v-if="targetVisible" class="this-chart-menu-item this-chart-menu-right-margin ndt-hide-on-mobile">
      <div class="this-chart-menu-text">
        Target
      </div>
      <input
        class="this-form-input"
        id="input4"
        type="number"
        name="id"
        v-model="target"
        autoFocus autoComplete="off"
      />
    </div>
    <div v-if="targetVisible" class="this-chart-menu-item this-chart-menu-right-margin ndt-hide-on-mobile">
      <div class="this-save-button" @click="handleSaveButtonClicked">
        Save
      </div>
    </div>
  </div>

  <div class="this-dividing-line ndt-hide-on-mobile" :class="{'this-select-expanded': selectExpanded}">
  </div>

  <div>
    <!-- DEBUG STATEMENTS -->
    <!-- stationsSelected: {{ stationsSelected }},
    variablesSelected: {{ variablesSelected }},
    target: {{ target }},
    showLabSelect: {{ showLabSelect }}, <br/>
    genericLocationList: {{ genericLocationList }}
    generics: {{ generics }} -->
  </div>

  <div v-if="targetUpdateSuccessful">
    Your target has been saved
  </div>

  <div v-if="variablesSelected.length > 0 && stationsSelected.length > 0">
    Black Plots: Measurement is ABOVE the device's working range ||
    Red Plots: Measurement is BELOW the device's working range

  </div>
  <div class="this-chart-title d-flex">
    <template v-if="variablesSelected.length > 0 && stationsSelected.length > 0">
      <div class="mr-2" v-for="v in variablesSelected" :key="v">
        {{ v }}
      </div>
      <div class="mr-2">in</div>
      <div class="mr-2" v-for="name in stationNames" :key="name">
          {{ name }}
      </div>
    </template>
    <template v-else>
     Please select some chart parameters in the above menu
    </template>
  </div>

  <div v-bind:class="{'mt-4 chartHolder': variablesSelected.length > 0 && stationsSelected.length > 0,  'mt-4': !(variablesSelected.length > 0 && stationsSelected.length > 0)}">
    <ChartHolder
      :stationsSelected="stationsSelected"
      :variablesSelected="variablesSelected"
      :chartStart="chartStart"
      :chartEnd="chartEnd"
      :target="target"
      :labSelect="showLabSelect"
      :showQuickSelect = "showQuickSelect"
      :chartType = "chartType"
      :targetVisible = "targetVisible"
      @setChartStart="setChartStartDate"
      @setChartEnd="setChartEndDate"
      :key="chartHolderKey"
    />
  </div>

</div>
</template>

<script>
import GeneralMixin from '@/mixins/GeneralMixin.vue'
import ChartIcon from '@/assets/ChartIcon.vue'
import PersonMenu from '@/components/PersonMenu.vue'
import AccountSelector from '@/components/AccountSelector.vue'
import ChartHolder from '@/components/ChartHolder'

export default {
  name: 'ChartBoard',

  components: {
    ChartIcon,
    ChartHolder,
    PersonMenu,
    AccountSelector
  },

  mixins: [GeneralMixin],

  data () {
    return {
      stationsSelected: [],
      variablesSelected: [],
      chartStart: null,
      chartEnd: null,
      target: null,
      variables: null,
      chartType: 'bar',
      generics: [],
      chartHolderKey: 0,
      targetVisible: false,
      targetUpdateSuccessful: false,
      thisTargetId: null,
      dataSourceName: 'Analyzer',
      selectExpanded: false,
      stationMultiple: true,
      stationSelectSize: 1,
      variableSelectSize: 1,
      chartSelectSize: 1,
      variableMultiple: false,
      variablesMessage: 'Please select a station first',
      stationNames: [],
      thisAnalyteId: null
    }
  },

  computed: {
  },

  props: {
    showAnalyzerSelect: {
      type: Boolean,
      default: true
    },
    showQuickSelect: {
      type: Boolean,
      default: false
    },
    showLabSelect: {
      type: Boolean,
      default: false
    }
  },

  watch: {

    stationsSelected: {
      handler: function (newValue, oldValue) {
        this.variablesSelected = []
        this.generics = []
        this.variables = []
        // console.log('***WATCH STATIONS SELECTED OLD NEW', oldValue, newValue)
        // console.log('***WATCH STATIONS STATIONS', this.stationsGlobal)
        this.stationsChosen = this.stationsGlobal.filter(item => newValue.includes(item.id))
        const stationNames = []
        this.stationsChosen.forEach((station) => {
          stationNames.push(station.name)
        })
        this.stationNames = stationNames
        // console.log('***WATCH STATIONS CHOSEN', stationNames)
        // console.log('***WATCH ANALYSES', this.analysesGlobal)
        // filter all analyses in these station
        const theAnalyses = this.analysesGlobal.filter(item => newValue.includes(item.station_pk))
        const varList = []
        theAnalyses.forEach((analysis) => {
          // console.log('***WATCH ANALYSIS_ANALYTE', analysis.analysis_analyte)
          analysis.analysis_analyte.forEach((analyte) => {
            if (!varList.includes(analyte.analyte_name)) {
              varList.push(analyte.analyte_name)
            }
          })
        })
        this.variables = varList
        // console.log('***WATCH ANALYSES', theAnalyses)
        if (newValue.length === 1) {
          this.variableMultiple = true
        } else if (newValue.length > 1) {
          this.variableMultiple = false
          this.targetVisible = false
          if (this.variablesSelected.length > 1) {
            this.variablesSelected = this.variablesSelected.shift()
          }
        }
        // Set the variables message
        if (this.showQuickSelect) {
          this.variablesMessage = 'Click here to select variable'
        } else {
          if (newValue.length === 1) {
            this.variablesMessage = 'Hold ctrl key for multi-select'
          } else {
            this.variablesMessage = 'Click here to select nutrient'
          }
        }
        // construct generic list
        // var c = a.filter(value => b.includes(value))
        for (const key of Object.keys(this.genericLocationList)) {
          newValue.forEach((item) => {
            if (this.genericLocationList[key].includes(item) && !this.generics.includes(key)) {
              this.generics.push(key)
            }
          })
        }
        this.setTarget()
      },
      deep: true
    },

    variablesSelected: {
      handler: function (newValue, oldValue) {
        // console.log('***WATCH VARIABLES SELECTED', oldValue, newValue, typeof newValue)
        if (!newValue) {
          this.variablesSelected = []
        }
        if (!Array.isArray(newValue)) {
          this.variablesSelected = [newValue]
        }
        this.thisTargetId = null
        if (this.variablesSelected.length > 1) {
          this.target = null
          this.targetVisible = false
          return
        }
        if (this.variablesSelected.length === 1 && this.variablesSelected[0] === null) {
          this.resetChart()
          return
        }
        this.setTarget()
      },
      deep: true
    },

    variableMultiple: {
      handler: function (newValue, oldValue) {
        this.variablesSelected = []
      }
    },

    showQuickSelect: {
      handler: function (newValue, oldValue) {
        if (newValue) {
          this.dataSourceName = 'pH/EC'
          this.targetVisible = false
          this.variablesSelected = []
        }
      }
    },

    showAnalyzerSelect: {
      handler: function (newValue, oldValue) {
        if (newValue) {
          this.variablesSelected = []
          this.dataSourceName = 'Analyzer'
        }
      }
    },

    showLabSelect: {
      handler: function (newValue, oldValue) {
        if (newValue) {
          this.variablesSelected = []
          this.dataSourceName = 'Lab Reports'
        }
      }
    }
  },

  mounted () {
    this.logEvent('Data tab mounted')
    this.setInitialChart()
  },

  methods: {

    getstationsSelectedInfo: function () {
      const numberOfStations = this.stationsSelected.length
      if (numberOfStations === 0) {
        return 'No station(s) selected'
      } else if (numberOfStations === 1) {
        return '1 station selected'
      } else {
        return numberOfStations + ' stations selected'
      }
    },

    getVariablesSelectedInfo: function () {
      const numberOfVariables = this.variablesSelected.length
      if (numberOfVariables === 0) {
        return 'No variable(s) selected'
      } else if (numberOfVariables === 1) {
        return '1 variable selected'
      } else {
        return numberOfVariables + ' variables selected'
      }
    },

    setInitialChart: async function () {
      if (this.stationsGlobal.length > 0) {
        await this.stationsSelected.push(this.stationsGlobal[0].id)
        if (this.variables.length > 0) {
          this.variablesSelected.push(this.variables[0])
        }
      }
    },

    resetChart: function () {
      this.chartHolderKey++
      this.stationsSelected = []
      this.variablesSelected = []
      // this.variables = null
      this.generics = [] // NMR CHECK
    },

    setChartStartDate: function (date) {
      this.chartStart = date
    },

    setChartEndDate: function (date) {
      this.chartEnd = date
    },

    handleSaveButtonClicked: function () {
      if (this.thisTargetId) {
        this.updateSubmit()
      } else {
        this.createSubmit()
      }
    },

    createSubmit: function () {
      const form = {
        name: this.stationNames[0],
        account: this.$store.getters['options/activeAccount'].id,
        target_analyte: [
          { analyte: this.thisAnalyteId, value: this.target, upper: null, lower: null }
        ]
      }
      this.$api.postTarget(form).then(data => {
        this.errorText = null
        this.targetUpdateSuccessful = true
        this.$api.patchStationDetail(this.stationsChosen[0].id, { target: data.id }).then(data => {
          this.errorText = null
        }).catch((error) => {
          if (error.non_field_errors) {
            this.errorText = error.non_field_errors[0]
          } else {
            this.errorText = error
          }
        })
        this.$store.dispatch('data/getTargets')
        this.$store.dispatch('data/getStations')
      }).catch((error) => {
        if (error.non_field_errors) {
          this.errorText = error.non_field_errors[0]
        } else {
          this.errorText = error
        }
      })
    },

    updateSubmit: function () {
      const form = {
        target_analyte: [
          { analyte: this.thisAnalyteId, value: this.target, upper: null, lower: null }
        ]
      }
      this.$api.patchTargetDetail(this.thisTargetId, form).then(data => {
        this.errorText = null
        this.targetUpdateSuccessful = true
        this.$store.dispatch('data/getTargets')
      }).catch((error) => {
        if (error.non_field_errors) {
          this.errorText = error.non_field_errors[0]
        } else {
          this.errorText = error
        }
      })
    },

    handleDatasourceDropdown: function (type) {
      this.showAnalyzerSelect = false
      this.showQuickSelect = false
      this.showLabSelect = false
      if (type === 'analyzer') {
        this.showAnalyzerSelect = true
        this.dataSourceName = 'Analyzer'
      } else if (type === 'quick') {
        this.showQuickSelect = true
        this.dataSourceName = 'ph/EC'
        this.targetVisible = false
      } else if (type === 'lab') {
        this.showLabSelect = true
        this.dataSourceName = 'Lab Reports'
      }
    },

    handleOnMouseLeave: async function (source) {
      await this.doSleep(1000)
      if (source === 'station') {
        this.stationSelectSize = 1
        this.selectExpanded = false
      } else if (source === 'chartType') {
        this.chartSelectSize = 1
        this.selectExpanded = false
      } else {
        this.variableSelectSize = 1
        this.selectExpanded = false
      }
    },

    setTarget: function () {
      this.target = null
      if (this.stationsSelected.length === 1 && this.stationsSelected[0] && !this.showQuickSelect && this.variablesSelected.length) {
        // now find the target
        this.targetVisible = true
        const thisAnalyte = this.analytesGlobal.find(item => item.name === this.variablesSelected[0])
        console.log('****WATCH FINDING TARGET', thisAnalyte)
        this.thisAnalyteId = thisAnalyte.id
        const thisStation = this.stationsChosen[0]
        console.log('****WATCH IN STATION', thisStation)
        if (thisStation.target) {
          const thisTarget = this.targetsGlobal.find(item => item.id === thisStation.target.id)
          this.thisTargetId = thisTarget.id
          // now find the analyte value in this target
          const itemToFind = this.variablesSelected[0]
          const objectToSearch = thisTarget.target_analyte
          // this.itemToFind = itemToFind
          // this.objectToSearch = objectToSearch
          const analyteFound = objectToSearch.find(item => item.analyte_name === itemToFind)
          if (analyteFound) {
            if (analyteFound.analyte_name === 'pH' || analyteFound.analyte_name === 'EC') {
              this.target = Number(analyteFound.value).toFixed(2)
            } else {
              this.target = Number(analyteFound.value).toFixed(0)
            }
          } else {
            this.target = null
          }
        }
      } else {
        this.target = null
      }
    }

  }

}

</script>

<style lang="scss" scoped>
  @import '@/styles/app.scss';

.this-top-title {
  margin-top: 45px;
  width: 149px;
  height: 51px;
}

.this-icon-container {
  width: 26px;
  height: 26px;
}

.this-title-text {
  width: 93px;
  height: 31px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 31px;
  color: #000000;
}

.this-save-button {
  margin-top: 32px;
  padding-top: 6px;
  width: 69px;
  height: 30px;  // 43px
  background: #64F08C;
  border-radius: 90px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.this-save-button:hover {
    background: #64F08C95;
}

.this-reset-button {
  padding-top: 8px; //4px
  width: 107px; //100
  // height: 30px;
  background: $ndt-color-4;
  border-radius: 20px;
  color: $ndt-color-1;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem; //14px
  line-height: 22px;
}

.this-chart-menu {
  background: $ndt-debug-color;
  margin-top: 15px;
  margin-left: $ndt-main-panel-left-margin;
}

.this-chart-menu-item {
  margin-top: 0px;
}

.this-chart-menu-text {
  margin-top: 0px;
  margin-bottom: 12px;
  text-align: left;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.this-form-select {
  width: 204px;
  height: 43px;    /* changed from 44.21 */
  background: #053C37;
  border: 1px solid #002323;
  border-radius: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  // font-size: 12px;
  line-height: 19px;
  color: #FFFFFF;
  margin-bottom: 16px;
}

.this-variables-form-select {
  height: 30px;
  width: 100px;
}

.this-chart-form-select {
  // height: 43px;
  width: 100px;
}

.this-form-datepicker {
  /* box-sizing: border-box; */
  width: 150px; //114
  height: 43px; // 43
  text-align: center;
  background: #053C37;
  border: 1px solid #002323;
  border-radius: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  // font-size: 12px;  // 16
  line-height: 15px;
  color: #FFFFFF !important;
  margin-bottom: 16px;
  padding: 5%;
}

.this-form-input {
  /* box-sizing: border-box; */
  text-align: center;
  width: 52px;
  height: 43px;  // 43
  background: #053C37;
  border: 1px solid #002323;
  border-radius: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  // font-size: 12px;
  line-height: 19px;
  color: #FFFFFF !important;
  margin-bottom: 16px;
}

.this-chart-menu-right-margin {
  margin-right: 28px;
}

.this-dividing-line {
  width: 100%;
  height: 0px;
  margin-right: 0px;
  margin-left: 0px;
  margin-top: 36px;    /* 202.5 from top */
  border: 1px solid #D5D5D5;
}

.this-select-expanded {
  margin-top: -8px;
}

.this-chart-title {
  text-align: left;
  height: 31px;
  margin-top: 20px;
  margin-left: $ndt-main-panel-left-margin;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 31px;
  color: #000000;
  background: $ndt-debug-color;
}

.this-datasource {
  position: relative;
  margin-right: 25px;
}

.this-datasource-dropdown-content {
  display: none;
  overflow: visible;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: $ndt-color-5;
  width: 130px;
  border: 1px solid $ndt-color-5;
  border-radius: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px;
  text-align: left;
  z-index: 5000;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: $ndt-color-2;
}

@media (max-width: $tablet-breakpoint) {
  .this-datasource-dropdown-content {
    top: -3px;
  }
}

.this-datasource-dropdown-content ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.this-datasource:hover .this-datasource-dropdown-content {
  display: block;
}

.this-datasource-dropdown-title {
  font-size: 0.9rem;
}

.this-datasource-dropdown-link {
  font-size: 0.9rem;
  cursor: pointer;
}

.this-datasource-dropdown-link:hover {
  color: $ndt-color-6;
}

.this-button-done-small {
  // padding-top: 5px;
  // width: 110px;
  // height: 31px;
  border: 1px solid $ndt-color-5;
  background: $ndt-color-5;
  border-radius: 72px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: $ndt-color-2;
}

.this-datasource-button-container {
  margin-top: 38.5px;
  margin-left: 100px;
}

@media (max-width: $tablet-breakpoint) {
  .this-form-select {
    width: 175px;
  }

  .this-chart-title {
    text-align: left;
    margin-top: 20px;
    margin-left: $ndt-main-panel-left-margin;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
  }
}

/*---- The following controls the transition to tablet ------*/

.this-mobile-element {
  display: none;
}

.this-desktop-element {
  display: block;
}

@media (max-width: $tablet-breakpoint) {
  .this-mobile-element {
    display: block;
  }

  .this-desktop-element {
    display: none;
  }
}

:deep(.btn-secondary) {
  color: #fff;
    background: none;
    border: none;
}

:deep(.btn-secondary:hover) {
  color: #fff;
    background: none;
    border: none;
}
:deep(.btn-secondary:focus) {
  outline: none;
  box-shadow: none;
}
:deep(.dropdown-toggle){
background-color: transparent !important;
border-color: transparent !important;
}
.this-upload {
  position: relative;
  cursor: pointer;
}

.this-button-large {
  /* Style=Primary, Small=False, Dark mode=False, Icon position=Leading */
  // padding-top: 10px;
  width: 117px;
  height: 40px;
  /* Lighter green */
  background: #0D3C3C;
  /* Lighter green */
  border: 1px solid #0D3C3C;
  border-radius: 72px;
  /* text */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $ndt-color-1;
  align-items: center;
  padding-left: 30%;
}

.chartHolder {
  border: 2.5px solid #003232;
  overflow: hidden;
  margin-left: 2%;
  margin-right: 2%;
  min-height: 300px;
}
</style>
