<template>
  <div class="notification is-danger">
    {{ message }}
    <br/>
  </div>
</template>

<script>
export default {
  name: 'Notification',
  props: ['message']
}
</script>
