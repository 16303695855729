<template>
  <div class="ndt-main-panel" :class="{ 'ndt-sidebar-expanded': sidebarVisible, 'ndt-sidebar-collapsed': sidebarCollapsed }">
    <div class="ndt-debug" v-if="debugOn">
      {{getParentFileName}} / <strong>{{getFileName}}</strong>
    </div>

    <div class="d-flex" style="margin-bottom: 5%;">
      <div class="ndt-top-title d-flex ndt-pointer" @click="loadMainPane('table')" style="cursor: pointer">
        <div class="ndt-icon-container">
          <div class="this-mobile-element">
            <PersonMenu/>
          </div>
          <DataIcon class="this-desktop-element" :isActive="true"/>
        </div>
        <div class="ndt-title-text">
          Data
        </div>
      </div>
      <div class="ndt-top-button d-flex ml-auto">
        <div v-if = "!sidebarVisible" class="this-datasource">
          <div class="this-datasource-button">{{ dataSourceName }}</div>
          <div class="this-datasource-dropdown-content">
            <ul>
              <li class="this-datasource-dropdown-link" @click="handleDatasourceDropdown('analyzer')">
                Analyzer
              </li>
              <li class="this-datasource-dropdown-link" @click="handleDatasourceDropdown('labreports')">
                Lab Reports
              </li>
              <li class="this-datasource-dropdown-link" @click="handleDatasourceDropdown('quick')">
                pH/EC
              </li>
            </ul>
          </div>
        </div>
        <div v-if="showLabReportsTable || showQuickSelect" class="this-upload this-desktop-element" v-click-outside="onClickOutside">
          <MultiButton @clicked="handleUploadClicked()">Upload</MultiButton>
          <div class="this-upload-dropdown-content" v-if="showUploadDropdown">
            <ul>
              <li class="this-upload-dropdown-link" @click="quickLoggerModalVisible = true; showUploadDropdown= false;">
                Log pH/EC
              </li>
              <li class="this-upload-dropdown-link" @click="pdfUploadModalVisible = true; showUploadDropdown= false;">
                Upload lab reports
              </li>
              <!-- <li class="this-upload-dropdown-link" @click="loadMainPane('design')">
                Design Specification
              </li> -->
            </ul>
          </div>
        </div>
        <div :style="{ opacity: selectedRows.length > 0 ? 1 : 0.5, cursor: selectedRows.length > 0 ? 'auto' : 'not-allowed'  }" @click="downloadRows" class="this-desktop-element" style="padding-left: 10px">
          <DownloadMultiButton :style="{ opacity: selectedRows.length > 0 ? 1 : 0.5, cursor: selectedRows.length > 0 ? 'auto' : 'not-allowed','pointer-events': selectedRows.length > 0 ? 'auto' : 'none'  }">Download to CSV</DownloadMultiButton>
        </div>
        <div class="this-mobile-element">
          <AccountSelector/>
        </div>
      </div>
    </div>

    <div class="row-controls">
      <div class="row-operations">
        <div class="filter-column-container" >
          <div class="assign-station-heading" :style="{ opacity: selectedRows.length > 0 ? 1 : 0.5, cursor: selectedRows.length > 0 ? 'auto' : 'not-allowed' }">Assign Station</div>
          <div class="row-operations">
            <div class="assign-station-dropdown">
              <b-form-select @change="handleAssignStationInput" :disabled="selectedRows.length === 0"
              class="this-form-select this-chart-form-select"
              v-model="selectedStation"
            >
            <!-- <option :value="null">Assign Station</option> -->
              <option v-for="(station, index) in stationsGlobal"
              :key="index"
              :value="station">{{station.name}}</option>
            </b-form-select>
            </div>
            <div class="assign-station-save-button" @click="assign" :style="{ opacity: selectedRows.length > 0 ? 1 : 0.5, cursor: selectedRows.length > 0 ? 'auto' : 'not-allowed', }" style="margin-left: 0.2rem;">Save</div>
            <div class="vertical-divider"></div>
            <div class="station-delete-button" @click="deleteRows" :style="{ opacity: selectedRows.length > 0 ? 1 : 0.5, cursor: selectedRows.length > 0 ? 'auto' : 'not-allowed' }">Delete</div>
        </div>
        </div>

        <div class="filter-row-controls this-desktop-element">
          <div class="filter-column-container">
            <div class="assign-station-heading">Search</div>
            <div class="row-operations">
              <div class="input-container">
                <input
                  class="this-form-input"
                  id="searchInput1"
                  type="text"
                  name="searchInputName"
                  v-model="searchInput"
                  placeholder="Type to search"
                  autoFocus
                  autoComplete="off"
                />
                <div class="input-icon"></div>
              </div>
          </div>
          </div>
          <div class="filter-column-container">
          <div class="this-chart-menu-item this-chart-menu-right-margin">
            <div class="assign-station-heading">
              Date Range
            </div>
            <div class="d-flex">
              <div class="mr-2">
                <input
                  type="date"
                  class="this-form-datepicker"
                  id="startdate"
                  v-model="dateFilterStart"
                  placeholder="Choose date"
                />
              </div>
              <div>
                <input
                  type="date"
                  class="this-form-datepicker"
                  id="enddate"
                  v-model="dateFilterEnd"
                  placeholder="Choose date"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>

    </div>

    <div v-if="!sidebarVisible" class="filter-row-controls this-mobile-element" style="margin-left: 46px;">
      <div class="filter-column-container">
        <div class="assign-station-heading">Search</div>
        <div class="row-operations">
          <div class="input-container">
            <input
              class="this-form-input"
              id="searchInput1"
              type="text"
              name="searchInputName"
              v-model="searchInput"
              placeholder="Type to search"
              autoFocus
              autoComplete="off"
            />
            <div class="input-icon"></div>
          </div>
      </div>
      </div>
      <!-- <div class="filter-column-container">
      <div class="this-chart-menu-item this-chart-menu-right-margin">
        <div class="assign-station-heading">
          Date Range
        </div>
        <div class="d-flex">
          <div class="mr-2">
            <input
              type="date"
              class="this-form-datepicker"
              id="startdate"
              v-model="dateFilterStart"
              placeholder="Choose date"
            />
          </div>
          <div>
            <input
              type="date"
              class="this-form-datepicker"
              id="enddate"
              v-model="dateFilterEnd"
              placeholder="Choose date"
            />
          </div>
        </div>
      </div>
    </div> -->
  </div>
    <!-- <div>
    update success: {{ sampleUpdateSuccessful }}
    </div> -->
    <div class="this-dividing-line ndt-hide-on-mobile">
    </div>
    <div v-if="designSpecVisible">
      <DesignSpec/>
    </div>
    <div v-else-if="showQuickSelect">
      <TableHolder2 ref="quickLoggerDataRef" @setSelectedRows="setSelectedRows" :selectedStation="selectedStation" @assignStation="assignStationGeneric"
      :searchInput="searchInput" :dateFilterStart="dateFilterStart" :dateFilterEnd="dateFilterEnd" />
      <div class="this-quicklog-mobile-button" @click="quickLoggerModalVisible = true">
      +
      </div>
    </div>
    <div v-else-if="showLabReportsTable">
      <LabReportsTableHolder ref="labReportDataRef" @setSelectedRows="setSelectedRows" :selectedStation="selectedStation" @assignStation="assignStation"
      :searchInput="searchInput" :dateFilterStart="dateFilterStart" :dateFilterEnd="dateFilterEnd"/>
    </div>
    <div v-else>
      <TableHolder ref="analyzerDataRef" :selectedStation="selectedStation" @assignStation="assignStation" @setSelectedRows="setSelectedRows" @updateTag="updateTag"
      :searchInput="searchInput" :dateFilterStart="dateFilterStart" :dateFilterEnd="dateFilterEnd"/>
    </div>

    <div>
    <!-- unit = {{ unit}}
    unitName = {{ unitName }}
    webAppPK = {{ webAppPk }}
    unitNames = {{ unitNames }}
    <br/><br/> -->
    <!-- analysesGlobal = {{ analysesGlobal }} -->
    </div>

    <b-modal
      v-model="pdfUploadModalVisible"
      content-class="ndt-card-1"
      size="xl"
      centered
      scrollable
      no-close-on-backdrop
      no-close-on-escape
      hide-header-close
      hide-header
      hide-footer
    >
      <PdfUpload @closeMe="pdfUploadModalVisible = false"/>
    </b-modal>

    <b-modal
      v-model="quickLoggerModalVisible"
      content-class="ndt-card-1"
      size="lg"
      centered
      scrollable
      no-close-on-backdrop
      no-close-on-escape
      hide-header-close
      hide-header
      hide-footer
    >
      <QuickLogger @closeMe="quickLoggerModalVisible = false"/>
    </b-modal>

  </div>
</template>

<script>
import GeneralMixin from '@/mixins/GeneralMixin.vue'
import MultiButton from '@/assets/MultiButton.vue'
import DownloadMultiButton from '@/assets/DownloadMultiButton.vue'
import DataIcon from '@/assets/DataIcon.vue'
import DesignSpec from '@/components/DesignSpec.vue'
import PersonMenu from '@/components/PersonMenu.vue'
import AccountSelector from '@/components/AccountSelector.vue'
import PdfUpload from '@/components/PdfUpload.vue'
import QuickLogger from '@/components/QuickLogger.vue'
import TableHolder from '@/components/TableHolder.vue'
import TableHolder2 from '@/components/TableHolder2.vue'
import vClickOutside from 'v-click-outside'
import LabReportsTableHolder from '@/components/LabReportsTableHolder.vue'
export default {
  name: 'DataBoard',

  directives: {
    clickOutside: vClickOutside.directive
  },

  components: {
    DownloadMultiButton,
    MultiButton,
    DataIcon,
    DesignSpec,
    PdfUpload,
    QuickLogger,
    TableHolder,
    TableHolder2,
    PersonMenu,
    AccountSelector,
    LabReportsTableHolder
  },

  mixins: [GeneralMixin],

  data () {
    return {
      designSpecVisible: false,
      genericTableVisible: false,
      quickLoggerModalVisible: false,
      selectedRows: [],
      sampleUpdateSuccessful: false,
      dataSourceName: 'Analyzer',
      showSampleSelect: true,
      // showQuickSelect: false,
      unitNames: [],
      showUploadDropdown: false,
      selectedStation: { name: 'Select a station' },
      searchInput: null,
      dateFilterStart: null,
      dateFilterEnd: null
    }
  },

  props: {
    showQuickSelect: {
      type: Boolean,
      default: false
    },

    pdfUploadModalVisible: {
      type: Boolean,
      default: false
    },

    showQuickLogger: {
      type: Boolean,
      default: false
    },

    showLabReportsTable: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    analysesGlobal: {
      handler: function (oldValue, newValue) {
        if (oldValue.length > 0 || newValue.length > 0) {
          this.initializeDateFilters()
        }
      },
      deep: true
    }
  },

  mounted () {
    this.logEvent('Data tab mounted')
    this.getAnalyteUnitNames()
    console.log('this.showQuickSelect  pdfUploadModalVisible  showQuickLogger in data', this.showQuickSelect, this.pdfUploadModalVisible, this.showQuickLogger)
    if (this.showQuickSelect && this.showQuickLogger) {
      this.quickLoggerModalVisible = true
      this.dataSourceName = 'Quick logger'
    }
    this.initializeDateFilters()
  },

  methods: {
    initializeDateFilters () {
      // Set dateFilterStart to 3 months before the current date
      const currentDate = new Date()
      const threeMonthsAgo = new Date()
      threeMonthsAgo.setMonth(currentDate.getMonth() - 24) // TODO: FIX THIS: only do -3 and not -24, that is for testing.Subtract 3 months from the current date
      this.dateFilterStart = threeMonthsAgo.toISOString().split('T')[0] // Convert to 'YYYY-MM-DD' format

      // Commented out: Old code for setting dateFilterStart based on analysesGlobal
      /*
  if (this.analysesGlobal.length > 0) {
    const firstDate = this.analysesGlobal.reduce((minDate, sample) => {
      const collectedDate = new Date(sample.collected)
      return collectedDate < minDate ? collectedDate : minDate
    }, new Date(this.analysesGlobal[0].collected))

    this.dateFilterStart = firstDate.toISOString().split('T')[0] // Convert to 'YYYY-MM-DD' format
  }
  */

      // Set dateFilterEnd to the current date plus one day
      const currentDatePlusOne = new Date()
      currentDatePlusOne.setDate(currentDatePlusOne.getDate() + 1)
      this.dateFilterEnd = currentDatePlusOne.toISOString().split('T')[0] // Convert to 'YYYY-MM-DD' format
    },
    handleAssignStationInput () {
      if (this.showQuickSelect) {
        this.$refs.quickLoggerDataRef.handleAssignStationInput()
      } else if (this.showLabReportsTable) {
        this.$refs.labReportDataRef.handleAssignStationInput()
      } else {
        this.$refs.analyzerDataRef.handleAssignStationInput()
      }
    },
    assign () {
      if (this.showQuickSelect) {
        this.$refs.quickLoggerDataRef.handleSaveAssignedStation()
      } else if (this.showLabReportsTable) {
        this.$refs.labReportDataRef.handleSaveAssignedStation()
      } else {
        this.$refs.analyzerDataRef.handleSaveAssignedStation()
      }
    },
    deleteRows () {
      if (this.showQuickSelect) {
        this.$refs.quickLoggerDataRef.handleDeleteRows()
      } else if (this.showLabReportsTable) {
        this.$refs.labReportDataRef.handleDeleteRows()
      } else {
        this.$refs.analyzerDataRef.handleDeleteRows()
      }
    },
    downloadRows () {
      if (this.selectedRows.length > 0) {
        if (this.showQuickSelect) {
          this.$refs.quickLoggerDataRef.downloadSelectedRows()
        } else if (this.showLabReportsTable) {
          this.$refs.labReportDataRef.downloadSelectedRows()
        } else {
          this.$refs.analyzerDataRef.downloadSelectedRows()
        }
      }
    },
    handleDropdownChange () {
      // This method will be triggered when the dropdown selection changes
    },
    getstationsSelectedInfo () {
      if (this.selectedStation) {
        return this.selectedStation.name
      }
      return 'Select a station'
    },
    onClickOutside (event) {
      // console.log('Clicked outside. Event: ', event)
      this.showUploadDropdown = false
    },

    setSelectedRows: function (data) {
      this.selectedRows = data
    },

    assignStation: function (data) {
      console.log('***ASSIGN STATIONS HANDLED', data, this.selectedStation)
      if (!Array.isArray(data)) {
        data = [data]
      }
      data.forEach((item) => {
        const patchData = {
          location: this.selectedStation?.station_pk || item.station_pk
        }
        this.$api.patchSampleDetail(item.sample_pk, patchData).then(data => {
          this.errorText = null
          this.sampleUpdateSuccessful = true
          // this.$store.dispatch('data/getSamples')
          const payload = { unit: this.unit, pag: 0, noStaff: false }
          this.$store.dispatch('data/getAnalysesCompact', payload)
        }).catch((error) => {
          this.logDebug(`***Error in assignStation ${error}`)
          if (error.non_field_errors) {
            this.errorText = error.non_field_errors[0]
          } else {
            this.errorText = error
          }
        })
      })
    },

    assignStationGeneric: function (data) {
      // return data // for es lint-no-unused-var
      if (!Array.isArray(data)) {
        data = [data]
      }
      data.forEach((item) => {
        const patchData = {
          location: item.station_pk
        }
        this.$api.patchGenericDataDetail(item.ecPH_pk, patchData).then(data => {
          this.errorText = null
          this.sampleUpdateSuccessful = true
          this.$store.dispatch('data/getGenericData')
        }).catch((error) => {
          this.logDebug(`***Error in assignStationGeneric ${error}`)
          if (error.non_field_errors) {
            this.errorText = error.non_field_errors[0]
          } else {
            this.errorText = error
          }
        })
      })
    },

    updateTag: function (data) {
      const patchData = {
        tag: data.comment
      }
      this.$api.patchAnalysisDetail(data.analysis_pk, patchData).then(data => {
        this.errorText = null
        this.sampleUpdateSuccessful = true
        const payload = { unit: this.unit, pag: 0, noStaff: false }
        this.$store.dispatch('data/getAnalysesCompact', payload)
        // this.$store.dispatch('data/getSamples')
      }).catch((error) => {
        if (error.non_field_errors) {
          this.errorText = error.non_field_errors[0]
        } else {
          this.errorText = error
        }
      })
    },

    handleUploadClicked: function (event) {
      this.showUploadDropdown = !this.showUploadDropdown
    },

    loadMainPane: function (choice) {
      this.designSpecVisible = false
      this.genericTableVisble = false
      if (choice === 'design') {
        this.designSpecVisible = true
      } else if (choice === 'generic') {
        this.genericTableVisible = true
      }
    },

    handleDatasourceDropdown: function (type) {
      this.showSampleSelect = false
      this.showQuickSelect = false
      this.showLabReportsTable = false
      if (type === 'analyzer') {
        this.showSampleSelect = true
        this.dataSourceName = 'Analyzer'
      } else if (type === 'quick') {
        this.showQuickSelect = true
        this.dataSourceName = 'ph/EC'
      } else {
        this.dataSourceName = 'Lab Reports'
        this.showLabReportsTable = true
      }
    },

    getAnalyteUnitNames: function () {
      // console.log('****ANALYTES', this.analytesGlobal)
      this.analytesGlobal.forEach((analyte) => {
        this.unitNames.push(this.analyteUnitName(analyte))
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/styles/app.scss';

.row-controls{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 46px;
  margin-right: 46px;
}
.assign-station-heading{
  color: #000;
  font-family: 'Helvetica Neue';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
}
.row-operations{
display: flex;
align-items:center;
width: 100%;
justify-content: space-between;
}
.this-chart-menu-right-margin {
  margin-right: 28px;
}

.this-form-select {
  width: 204px;
  height: 43px;    /* changed from 44.21 */
  background: #053C37;
  border: 1px solid #002323;
  border-radius: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  // font-size: 12px;
  line-height: 19px;
  color: #FFFFFF;
  margin-bottom: 16px;
}
.custom-dropdown::after {
  content: '\25BC'; /* Unicode character for downward arrow */
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #000; /* Change the color if needed */
}
.assign-station-dropdown{
  margin-right: 1%;
}
.assign-station-save-button{
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: flex-start;
  gap: 56.57px;
  border-radius: 90px;
  background: #64F08C;
  color: #000;
  font-family: Helvetica Neue;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: -15px;
  cursor: pointer;
}

.station-delete-button{
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: flex-start;
  gap: 56.57px;
  color: var(--Black, #000);
  text-align: center;
  font-family: Helvetica Neue;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 90px;
  background: var(--Bright-green, #64F08C);
  margin-top: -15px;
  margin-left: auto;
  cursor: pointer;

}
.this-square {
  height: 50px;
  width: 100px;
  border-radius: 5px;
}

/* upload button */

.this-upload {
  position: relative;
  cursor: pointer;
}

.this-upload-dropdown-content {
  // display: none;
  overflow: visible;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: $ndt-color-4;
  min-width: 160px;
  border: 1px solid $ndt-color-4;
  border-radius: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px;
  text-align: left;
  z-index: 5000;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: $ndt-color-1;
}

.this-upload-dropdown-content ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.this-upload:hover .this-upload-dropdown-content {
  display: block;
}

.this-upload-dropdown-title {
  font-size: 0.9rem;
}

.this-upload-dropdown-link {
  font-size: 0.9rem;
  cursor: pointer;
}

.this-upload-dropdown-link:hover {
  color: $ndt-color-5;
}

.this-datasource {
  position: relative;
  margin-right: 25px;
}

.this-datasource-dropdown-content {
  display: none;
  overflow: visible;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: $ndt-color-5;
  width: 120px;
  border: 1px solid $ndt-color-5;
  border-radius: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px;
  text-align: left;
  z-index: 5000;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: $ndt-color-2;
}

.this-datasource-dropdown-content ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.this-datasource:hover .this-datasource-dropdown-content {
  display: block;
}

.this-datasource-dropdown-title {
  font-size: 0.9rem;
}

.this-datasource-dropdown-link {
  font-size: 0.9rem;
  cursor: pointer;
}

.this-datasource-dropdown-link:hover {
  color: $ndt-color-6;
}

.this-button-done-small {
  padding-top: 5px;
  width: 110px;
  height: 31px;
  border: 1px solid $ndt-color-5;
  background: $ndt-color-5;
  border-radius: 72px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: $ndt-color-2;
}

.this-datasource-button-container {
  margin-top: 38.5px;
  margin-left: 100px;
}

.this-datasource-button {
  padding-top: 10px;
  width: 117px;
  height: 40px;
  /* Lighter green */
  background: $ndt-color-5;
  /* Lighter green */
  border: 1px solid $ndt-color-5;
  border-radius: 72px;
  /* text */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $ndt-color-2;
}

.this-quicklog-mobile-button {
  display: none;
}

@media (max-width: $tablet-breakpoint) {
  .this-quicklog-mobile-button {
    position: fixed;
    bottom: 100px;
    right: 13px;
    display: block;
    width: 43px;
    height: 43px;
    background: $ndt-color-5;
    border: 1px solid $ndt-color-5;
    border-radius: 65px;
    padding-top: 9px;
  }
}

/*---- The following controls the transition to tablet ------*/

.this-mobile-element {
  display: none;
}

.this-desktop-element {
  display: block;
}

.custom-select {
  background: #003232 url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='5' viewBox='0 0 8 5'%3e%3cpath fill='white' d='M0 0l4 4 4-4H0z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px !important;
}
.this-dividing-line {
  width: 100%;
  height: 0px;
  margin-right: 0px;
  margin-left: 0px;
  margin-top: 36px;    /* 202.5 from top */
  border: 1px solid #D5D5D5;
}

.input-container {
  position: relative;
  width: 12.69rem;
}

.this-form-input {
  box-sizing: border-box;
  text-align: left;
  width: 100%;
  height: 43px;
  background: #053C37;
  border: 1px solid #002323;
  border-radius: 10px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  color: #FFFFFF;
  padding-left: 2.5rem; /* Space for the icon */
  padding-right: 1rem;
}

.input-icon {
  position: absolute;
  top: 50%;
  left: 0.75rem; /* Adjust to position the icon properly */
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background: url('~@/assets/img/searchIcon.png') no-repeat center center;
  background-size: contain;
}

::placeholder {
  text-align: left;
}

/* or, for legacy browsers */

::-webkit-input-placeholder {
  text-align: left;
}

:-moz-placeholder { /* Firefox 18- */
  text-align: left;
}

::-moz-placeholder {  /* Firefox 19+ */
  text-align: left;
}

:-ms-input-placeholder {
  text-align: left;
}
.this-form-datepicker {
  /* box-sizing: border-box; */
  width: 133px; //114
  height: 43px; // 43
  text-align: center;
  background: #053C37;
  border: 1px solid #002323;
  border-radius: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  // font-size: 12px;  // 16
  line-height: 15px;
  color: #FFFFFF !important;
  margin-bottom: 16px;
  padding: 5%;
  font-family: "Helvetica Neue";
}
.filter-row-controls {
  display: flex;
  flex-direction: row; /* Default direction */
  align-items: flex-start;
  margin-right: -1.5rem;
}

.filter-column-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin-right: 1%;
}

@media (max-width: 768px) {
  .filter-row-controls {
    display: flex;
    flex-direction: column !important; /* Overrides default direction for larger screens */
    align-items: flex-start;
    margin-left: 46px;
    margin-right: 46px;
  }
  .filter-column-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin-right: 1%;
   /* margin-bottom: 16px;*/
  }
  .this-mobile-element {
    display: block;
  }

  .this-desktop-element {
    display: none;
  }

  .assign-station-save-button{
    padding: 6px 8px;
  }

  .station-delete-button{
    padding: 6px 8px;
  }

}
.vertical-divider {
  width: 4px;
  background-color: black;
  height: 2.5rem;
  margin: -0.8rem 5px 0px 5px;
}

</style>
