<script>
export default {
  computed: {

    analysesGlobal: function () { return this.$store.getters['data/analysesCompact'] },

    stationsGlobal: function () { return this.$store.getters['data/stations'] },

    targetsGlobal: function () { return this.$store.getters['data/targets'] },

    genericDataGlobal: function () { return this.$store.getters['data/genericData'] },

    genericPredefinedVarsGlobal: function () { return this.$store.getters['data/genericPredefinedVars'] },

    genericLocationList: function () { return this.$store.getters['data/genericLocationList'] },

    analytesGlobal: function () { return this.$store.getters['data/analytes'] },

    gameboyObjectsGlobal: function () { return this.$store.getters['data/gameboyObjects'] },

    mediaType: function () { return this.$store.getters['options/mediaType'] },

    isAdmin: function () { return this.$store.getters['options/isAdmin'] },

    viewportWidth: function () { return this.$store.getters['options/width'] },

    layout: function () { return this.$store.getters['options/layout'] },

    sidebarCollapsed: function () { return this.$store.getters['options/sidebarCollapsed'] },

    isMobile: function () {
      const media = this.$store.getters['options/mediaType']
      return media === 'mobile'
    },

    sidebarVisible: {
      get () {
        const media = this.$store.getters['options/mediaType']
        if (media === 'desktop') { return true }
        if (media === 'laptop') { return true }
        return false
      },
      set () {
        return null
      }
    },

    betaShow: function () {
      return this.$store.getters['options/betaShow']
    },

    debugOn: function () {
      // return this.$store.getters['options/debugOn']
      return false
    },

    fullApp: function () {
      // console.log('***fullApp ', this.$store.getters['options/userPrefs'])
      return this.$store.getters['options/userPrefs'].full_access
    },

    /* sidebarVisible: function () {
      const media = this.$store.getters['options/mediaType']
      if (media === 'desktop') { return true }
      if (media === 'laptop') { return true }
      return false
    }, */

    thisAccount: function () {
      const acc = this.$store.getters['options/activeAccount']
      if (acc) {
        return acc.name
      }
      return ''
    },

    isDemo: function () {
      const acc = this.$store.getters['options/activeAccount']
      if (acc) {
        if (acc.number === 4) {
          return true
        }
      }
      return false
    },

    unit: function () {
      return this.$store.getters['options/unit']
    },

    unitName: function () {
      return this.$store.getters['options/unitName']
    },

    currentView: function () {
      const view = this.$store.getters['options/currentView']
      const retValue = {}
      retValue[view] = true
      return retValue
    },

    getFileName: function () {
    // Thanks to https://stackoverflow.com/questions/44048649/what-is-the-name-of-my-vue-component
      if (this.$vnode.tag) {
        const res = this.$vnode.tag
        return res.replace(/vue-component-\d+-/i, '') + '.vue'
      }
      return ''
    },

    getParentFileName: function () {
    // Thanks to https://stackoverflow.com/questions/44048649/what-is-the-name-of-my-vue-component
      if (this.$parent.$vnode.tag) {
        const res = this.$parent.$vnode.tag
        return res.replace(/vue-component-\d+-/i, '') + '.vue'
      }
      return ''
    },

    currentUser: function () {
      return this.$store.getters['auth/user']
    },

    currentAccount: function () {
      const acc = this.$store.getters['options/activeAccount']
      if (acc) {
        return acc.name
      }
      return ''
    },

    webAppPk () {
      const devices = this.$store.getters['data/devices']
      const result = devices.find(item => item.device_id === 'Web Application')
      // console.log('****DEVICES result', result, result.id)
      if (result) {
        return result.id
      } else {
        return 'Web Application'
      }
    },

    roomPk () {
      const types = this.$store.getters['data/prodObjectTypes']
      const result = types.find(item => item.name === 'Room')
      console.log('****ROOMS result', result, result.id)
      return result.id
    }

  },

  /* watch: {
    analysesGlobal: {
      handler: function (oldValue, newValue) {
        console.log('***ANALYSES GLOBAL CHANGED', oldValue, newValue)
      },
      deep: true
    },
    unitName: {
      handler: function (oldValue, newValue) {
        console.log('***UNIT NAME CHANGED', oldValue, newValue)
      },
      deep: true
    }
  }, */

  methods: {

    analyteUnitName: function (analyte) {
      const analyteName = this.$store.getters['options/unitName']
      // console.log('***ANALYTE NAME', analyteName)
      // console.log('***ANALYTE', analyte.name, analyte.type)
      if (analyte.analyte_type === 'micro') {
        if (analyteName === 'ppm') { return 'ppb' }
        if (analyteName === 'mmol/L') { return 'micromole/L' }
        if (analyteName === 'ppm as element') { return 'ppb as element' }
      } else if (analyte.analyte_type === 'other') {
        if (analyte.analyte_name === 'pH') { return '' }
        if (analyte.analyte_name === 'EC') { return 'mS/cm' }
      }
      return analyteName
    },

    // This method converts an assumed input value of ppm into whatever the current unit setting is
    unitConv (analyte, number) {
      if (!number) {
      // ensures that we don't return 0's when it should be nothing
        return null
      }
      const micros = ['Fe', 'Mn', 'Zn', 'B', 'Cu', 'Mo', 'Al']
      const isMicro = (micros.indexOf(analyte) > -1)
      // console.log('****ANALYTE ISMICRO', analyte, isMicro)
      const unit = this.$store.getters['options/unit']
      if (unit === 'ppm') {
        if (isMicro) {
          const retValue = (Number(number) * 1000).toFixed(0)
          // if (analyte === 'Fe') { console.log('*****FE PPM', retValue) }
          return retValue
        }
        return Number(number)
      }
      const analyteData = this.$store.getters['data/analytes']
      const analyteInfo = analyteData.find(({ name }) => name === analyte)
      // console.log('***Found analyte analyteInfo:', analyte, analyteInfo)
      if (unit === 'mmol') {
        const retValue = Number(number / analyteInfo.molecular_weight)
        if (isMicro) {
          return (retValue * 1000).toFixed(3)
        }
        return retValue.toFixed(3)
      }
      if (unit === 'second') {
        const retValue = Number(number * analyteInfo.second_conv)
        if (isMicro) {
          return (retValue * 1000).toFixed(3)
        }
        return retValue.toFixed(3)
      }
      return Number(number).toFixed(3)
    },

    logEvent (text) {
      const data = {
        function: this.$options.name,
        event_text: text,
        device: this.$store.getters['options/device_id'],
        user: this.$store.getters['options/userId'],
        account: this.$store.getters['options/activeAccount'].id
      }
      try {
        this.$api.postEvent(data)
      } catch (err) {
        console.log('***Error in logEvent:', this.$options.name, err)
      }
    },

    logDebug (text) {
      console.log('****DEBUG', this.debugOn)
      if (this.debugOn) {
        console.log(`${this.$options.name}: ` + text)
      }
    },

    getDate (offset) {
      // offset is in days
      // returns a date string format 1999-12-01 at offset days ago, so if offset = 7 returns 1 week ago
      const day = new Date()
      day.setDate(day.getDate() - offset)
      const dd = String(day.getDate()).padStart(2, '0')
      const mm = String(day.getMonth() + 1).padStart(2, '0') // January is 0!
      const yyyy = day.getFullYear()
      return (yyyy + '-' + mm + '-' + dd)
    },

    doSleep (time) {
      /* usage:
      async function test() {
        await doSleep(1000)
      }
      */
      return new Promise(resolve => setTimeout(resolve, time))
    }
  }

}
</script>
