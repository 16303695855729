<template>
  <div id="mainTableDiv" class="tablePadding">
    <div class="ndt-debug" v-if="debugOn">
      {{ getParentFileName }} / <strong>{{ getFileName }}</strong>
    </div>

    <div>
      <!-- <button type="button" class="mr-3 this-button-done-small" v-on:click="downloadSelectedRows()" v-if="selectedRows.length>0">Download</button>
      <b-form-select v-if="selectedRows.length>0"
        class="this-form-select"
        id="assignStationInput"
        v-model="selectedStation"
        @change="handleAssignStationInput"
      >
      <option :value="null">Assign Station</option>
        <option v-for="station in stations" :value="station" :key="station.id">
          {{ station.name }}
        </option>
      </b-form-select>
      <button type="button" class="ml-3 this-button-done-small" v-on:click="handleSaveAssignedStation()" v-if="selectedRows.length>0">Save Assigned</button>
      <br>
      <button type="button" class="ml-3 this-button-done-small" v-on:click="handleDeleteRows()" v-if="selectedRows.length>0">Delete</button> -->
      <ag-grid-vue :style="{ ...styleComputed }" class="ag-theme-alpine" :columnDefs="columnDefs" :rowSelection="rowSelection"
        :rowMultiSelectWithClick="rowMultiSelectWithClick" :suppressRowClickSelection="suppressRowClickSelection"
        :animateRows=true @selection-changed="onSelectionChanged" :rowData="rowData" @grid-ready="onGridReady"
        @cell-value-changed="onCellValueChanged" @row-value-changed="onRowValueChanged"
        :overlayLoadingTemplate="overlayLoadingTemplate"  :overlayNoRowsTemplate="overlayNoRowsTemplate"
        @first-data-rendered="onFirstDataRendered"
        :pagination="true" :quickFilterText="searchInput">
      </ag-grid-vue>
    </div>

    <!-- <div class="this-table-outline mt-5 pt-5">
      Showing pH and EC in account: {{ currentAccount }}
      <br/>
      <div v-for="genericVar, index in genericVars" :key="index">
        {{ genericVar }}
      </div>
      <div v-for="datapoint, index2 in genericData" :key="index2">
        {{ datapoint }}
      </div>
      There are {{ genericData.length }} datapoints in total. -->
    </div>
</template>

<script>
import GeneralMixin from '@/mixins/GeneralMixin.vue'
import { eventsBus } from '@/main'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { AgGridVue } from 'ag-grid-vue'
export default {
  name: 'TableHolder2',

  mixins: [GeneralMixin],

  components: { AgGridVue },
  props: {
    dataType: {
      type: String,
      default: 'sample'
    },
    selectedStation: {
      // type: String,
      default: null
    },
    searchInput: {
    // type: String,
      default: null
    },
    dateFilterStart: {
    // type: String,
      default: null
    },
    dateFilterEnd: {
    // type: String,
      default: null
    }
  },

  data () {
    return {
      eventData: null,
      genericVars: [],
      genericData: [],
      errorText: null,
      displayTable: false,
      columnDefs: undefined,
      rowData: undefined,
      allTableData: null,
      uniqueColumnVariables: null,
      rowSelection: null,
      gridApi: null,
      rowMultiSelectWithClick: true,
      suppressRowClickSelection: true,
      uniqueStations: [],
      stationCellColors: {},
      gridColumnApi: null,
      overlayLoadingTemplate: null,
      overlayNoRowsTemplate: null,
      selectedRows: [],
      windowHeight: null,
      ECUnit: null,
      analytesWithUnits: {}
    }
  },

  computed: {
    /* genericVars: function () {
      return this.$store.getters['data/genericVars']
    } */
    stations: function () {
      return this.$store.getters['data/stations']
    },
    genericDataTest: function () {
      return this.$store.getters['data/genericData']
    },
    styleComputed: function () {
      let height

      // Get the current window innerHeight for accurate viewport height
      const currentHeight = window.innerHeight

      if (this.sidebarVisible) {
      // Subtract 300px (or adjust as needed) from the current height
        height = (currentHeight - 300).toString() + 'px'
      } else {
        height = '22rem' // Fallback value
        // height = (currentHeight - 72 - 20).toString() + 'px' // Fallback value
      }

      // Return the height in a style object
      return { height: height }
    }
  },
  watch: {
    genericDataTest: {
      handler: function (oldValue, newValue) {
        if (oldValue.length > 0 || newValue.length > 0) {
          this.getUniqueStations()
          this.setupTable()
        }
        if (oldValue.length === 0 || newValue.length === 0) {
          this.gridApi.hideOverlay()
          this.gridApi.showNoRowsOverlay()
        }
      },
      deep: true
    },
    dateFilterStart: {
      handler: function (oldValue, newValue) {
        if (newValue && this.genericDataTest.length > 0) {
          this.setupTable()
        }
      }
    },
    dateFilterEnd: {
      handler: function (oldValue, newValue) {
        if (newValue && this.genericDataTest.length > 0) {
          this.setupTable()
        }
      }
    }
  },

  beforeMount () {
    this.setupTable()
  },

  created () {
    eventsBus.$on('accountChanged', (data) => {
      console.log('***EVENT', data)
      this.eventData = data
      if (this.gridApi) {
        this.gridApi.showLoadingOverlay()
        // this.getGenericVars()
        // this.getGenericData()
      }
    })
    this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Please wait while your data is loading</span>'
    this.overlayNoRowsTemplate = '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;">There is no data to show.</span>'
  },

  mounted () {
    // this.getGenericData()
    this.windowHeight = window.innerHeight
    window.addEventListener('resize', () => { this.windowHeight = window.innerHeight })
  },
  methods: {
    onFirstDataRendered () {
      const allColumnIds = []
      this.gridColumnApi.getColumns().forEach((column) => {
        allColumnIds.push(column.getId())
      })
      const skipHeader = false
      this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader)
    },
    getGenericVars: function () {
      this.$api.getGenericVarList()
        .then(response => {
          this.genericVars = response
          console.log('***RESPONSE', response)
        })
        .catch(error => {
          console.log('***Error in ', this.$options.name, ' : ', error)
          this.errorText = error
        })
    },

    getGenericData: function () {
      this.$api.getGenericDataList()
        .then(response => {
          this.genericData = response
          console.log('***RESPONSE', response)
          // this.formatDataforTable()
          this.setupTable()
        })
        .catch(error => {
          console.log('***Error in ', this.$options.name, ' : ', error)
          this.errorText = error
        })
    },
    handleAssignStationInput () {
      this.selectedRows.forEach((row) => {
        row.station_name = this.selectedStation?.name || null
        row.station_pk = this.selectedStation?.id || null
      })
      this.gridApi.applyTransaction({ update: this.selectedRows })
      this.gridApi.redrawRows()
      // this.notifyUserAndDeselectRows()
    },
    notifyUserAndDeselectRows () {
      // notify here
      // this.gridApi.undoCellEditing()
      this.gridApi.deselectAll()
    },
    handleSaveAssignedStation () {
      this.$emit('assignStation', this.selectedRows)
      this.notifyUserAndDeselectRows()
    },
    handleDeleteRows () {
      this.selectedRows.forEach((row) => {
        this.$api.deleteGenericDataDetail(row.ecPH_pk).then((response) => { })
      })
      this.gridApi.applyTransaction({ remove: this.selectedRows })
      this.gridApi.redrawRows()
    },
    formatDataforTable () {
      this.uniqueColumnVariables = ['collected', 'pH', 'EC', 'station_pk', 'station_name', 'ecPH_pk']
      const allTableData = []
      const uniqueStations = Object.assign({}, ...this.stations.map((item) => ({ [item.id]: item.name })))
      const filteredAnalysesbyDate = this.filterDataByDateRange(this.genericDataTest)
      filteredAnalysesbyDate.forEach((value) => {
        const currentVariable = value.variable_name
        if (value.unit) {
          this.ECUnit = value.unit
        }
        const rowData = {
          collected: value.collected,
          station_pk: value.location,
          station_name: uniqueStations[value.location],
          ecPH_pk: value.id,
          unit: value.unit
          // tag: value.tag
        }
        rowData[currentVariable] = value.numeric_value
        allTableData.push(rowData)
      })
      this.allTableData = allTableData
    },
    setupTable () {
      this.formatDataforTable()
      const allColumns = []
      this.getUniqueStations()
      this.calculateCellColorsForStations()
      this.uniqueColumnVariables.forEach((variable) => {
        const commonColumnConfig = { resizable: true, sortable: true, filter: true, field: variable }
        if (variable === 'collected') {
          commonColumnConfig.checkboxSelection = true
          commonColumnConfig.headerCheckboxSelection = true
          commonColumnConfig.pinned = 'left'
          commonColumnConfig.valueFormatter = this.dateFormatter
          commonColumnConfig.sort = 'desc'
          allColumns.push(commonColumnConfig)
        } else if (variable === 'sample_id') {
          allColumns.push(commonColumnConfig)
        } else if (variable === 'device_name') {
          commonColumnConfig.headerName = 'Device-Name'
          allColumns.push(commonColumnConfig)
        } else if (variable === 'EC') {
          commonColumnConfig.headerName = 'EC (' + this.ECUnit + ')'
          allColumns.push(commonColumnConfig)
        } else if (variable === 'pH') {
          commonColumnConfig.headerName = 'pH'
          allColumns.push(commonColumnConfig)
        } else if (variable === 'station_name') {
          allColumns.push({
            // cellStyle: params => {
            //   return { backgroundColor: params.value ? this.stationCellColors[params.value] : 'none', 'border-radius': '25px' }
            // },
            editable: false,
            resizable: true,
            field: variable,
            sortable: true,
            filter: true
            // cellEditor: 'agSelectCellEditor',
            // cellEditorParams: {
            //   values: this.uniqueStations
            // }
          })
        } else {
          if (variable === 'tag') {
            commonColumnConfig.editable = true
          } else if (variable === 'station_pk' || variable === 'sample_pk' || variable === 'ecPH_pk') {
            commonColumnConfig.hide = true
          }
          allColumns.push(commonColumnConfig)
        }
      })
      this.columnDefs = allColumns
      // this.rowData = this.allTableData
      this.rowData = [...new Set(this.allTableData)]
      // if (this.samples.length > 0) {
      this.rowSelection = 'multiple'
      // this.displayTable = true
      this.rowMultiSelectWithClick = true
      // }
    },
    filterDataByDateRange (data) {
      // Convert the start and end dates to Date objects
      if (!this.dateFilterStart || !this.dateFilterEnd) {
        return data
      }
      const startDate = new Date(this.dateFilterStart)
      const endDate = new Date(this.dateFilterEnd)

      // Filter the data array
      return data.filter(sample => {
        // Convert the 'collected' field to a Date object
        const collectedDate = new Date(sample.collected)

        // Check if the collectedDate is within the date range
        return collectedDate >= startDate && collectedDate <= endDate
      })
    },
    dateFormatter (params) {
      return new Date(params.value).toLocaleString('en-Ca', { hour12: false })
    },
    getUniqueStations () {
      const uniqueStations = this.stations.map(item => item.name)
      // const unique = [...new Set(this.allTableData.map(item => item.station_name))]
      this.uniqueStations = uniqueStations
    },
    onSelectionChanged () {
      const selectedRows = this.gridApi.getSelectedRows()
      this.selectedRows = selectedRows
      this.$emit('setSelectedRows', selectedRows)
      // return selectedRows // for es lint-no-unused-var
      // emit these back for batch operations
    },
    onGridReady (params) {
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
      if (this.allTableData.length < 1) {
        this.gridApi.showLoadingOverlay()
      }
    },
    downloadSelectedRows () {
      this.setCorrectStationPK(this.selectedRows)
      const columnsToExport = []
      const excludedColumns = ['analyte_unit', 'sample_id', 'sample_pk', 'station_pk', 'device']
      this.uniqueColumnVariables.forEach((column) => {
        if (!excludedColumns.includes(column)) {
          columnsToExport.push(column)
        }
      })
      this.gridApi.exportDataAsCsv({ onlySelected: true, columnKeys: columnsToExport })
      this.notifyUserAndDeselectRows()
    },
    setCorrectStationPK (rows) {
      const uniqueStations = Object.assign({}, ...this.stations.map((item) => ({ [item.name]: item.id })))
      rows.forEach((row) => {
        row.station_pk = uniqueStations[row.station_name]
      })
      if (rows.length > 0) {
        this.gridApi.applyTransaction({ update: rows })
        this.gridApi.redrawRows()
      }
      return rows
    },
    onCellValueChanged (event) {
      this.selectedRows = this.setCorrectStationPK(this.selectedRows)
      event.data.station_pk = this.setCorrectStationPK([event.data])[0].station_pk
      // emit this back for updation of any value in a row
      if (event.column.colId === 'tag') {
        this.$emit('updateTag', event.data)
      } else if (event.column.colId === 'station_name') {
        this.$emit('assignStation', event.data)
      }
    },
    onRowValueChanged (event) {
      // not needed for now
      // const data = event.data
      return event.data // for es lint-no-unused-var
    },
    addData (data) {
      // data is in the following form:
      // [{
      //   collected: "2022-11-29T22:35:40.281000Z",
      //   station_pk : 363,
      //   station_name: null,
      //   ecPH_pk : 23232,
      //   unit: null,
      //   tag: null,
      //   pH: 222
      // }]
      const uniqueStations = Object.assign({}, ...this.stations.map((item) => ({ [item.id]: item.name })))
      data.forEach((item) => {
        item.station_name = uniqueStations[item.station_pk]
      })
      this.gridApi.applyTransaction({
        add: data,
        addIndex: 0
      })
      this.gridApi.redrawRows()
    },
    calculateCellColorsForStations () {
      // const colors = [ // [Google Material Design color hex codes(100) ]https://gist.github.com/kiley0/756bf555c5a7ae17b8d03596ae364712
      //   '#F44336', '#FFEBEE', '#FFCDD2', '#EF9A9A', '#E57373',
      //   '#EF5350', '#F44336', '#E53935', '#D32F2F', '#C62828',
      //   '#B71C1C', '#FF8A80', '#FF5252', '#FF1744', '#D50000',
      //   '#E91E63', '#FCE4EC', '#F8BBD0', '#F48FB1', '#F06292',
      //   '#EC407A', '#E91E63', '#D81B60', '#C2185B', '#AD1457',
      //   '#880E4F', '#FF80AB', '#FF4081', '#F50057', '#C51162',
      //   '#9C27B0', '#F3E5F5', '#E1BEE7', '#CE93D8', '#BA68C8',
      //   '#AB47BC', '#9C27B0', '#8E24AA', '#7B1FA2', '#6A1B9A',
      //   '#4A148C', '#EA80FC', '#E040FB', '#D500F9', '#AA00FF',
      //   '#673AB7', '#EDE7F6', '#D1C4E9', '#B39DDB', '#9575CD',
      //   '#7E57C2', '#673AB7', '#5E35B1', '#512DA8', '#4527A0',
      //   '#311B92', '#B388FF', '#7C4DFF', '#651FFF', '#6200EA',
      //   '#3F51B5', '#E8EAF6', '#C5CAE9', '#9FA8DA', '#7986CB',
      //   '#5C6BC0', '#3F51B5', '#3949AB', '#303F9F', '#283593',
      //   '#1A237E', '#8C9EFF', '#536DFE', '#3D5AFE', '#304FFE',
      //   '#2196F3', '#E3F2FD', '#BBDEFB', '#90CAF9', '#64B5F6',
      //   '#42A5F5', '#2196F3', '#1E88E5', '#1976D2', '#1565C0',
      //   '#0D47A1', '#82B1FF', '#448AFF', '#2979FF', '#2962FF',
      //   '#03A9F4', '#E1F5FE', '#B3E5FC', '#81D4FA', '#4FC3F7',
      //   '#29B6F6', '#03A9F4', '#039BE5', '#0288D1', '#0277BD']
      // eslint-disable-next-line
      let colors = ['#64F08C', '#78AB82', '#D6FADC', '#3B4472', '#00DAB2',
        '#00BFD3', '#00A1E1', '#545B8C', '#ECEEFF', '#8C8EB1',
        '#3C4A3E', '#A9AE8A', '#68EDCB', '#E8EAD8', '#78814E',
        '#C9A74E', '#907419', '#719A78', '#EEFDF0', '#9FAFA1',
        '#FF990F', '#AEEB77', '#FFDE82', '#FF6A6F', '#00D395',
        '#1ABA5B']
      // uncomment the below code to generate a random colors array if the above are not sufficient
      while (colors.length < this.uniqueStations.length) {
        colors.push(`rgb(${rand(0, 255)}, ${rand(0, 255)}, ${rand(0, 255)})`)
      }
      // random number generator
      function rand (from, to) {
        return ~~(Math.random() * (to - from)) + from
      }
      const cellColors = {}
      this.uniqueStations.forEach((station, index) => {
        cellColors[station] = colors[index]
      })
      this.stationCellColors = cellColors
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/app.scss';
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-alpine.css";
.tablePadding {
  margin-left: 46px;
  margin-top: 2%;
  margin-right: 46px;
 }
 @media (max-width: 768px) {
  .tablePadding {
      margin-left: 20px;
      margin-top: 2%;
      margin-right: 20px;
  }
}
.this-form-select {
  width: 204px;
  height: 35px;    /* changed from 44.21 */
  background: #053C37;
  border: 1px solid #002323;
  border-radius: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #FFFFFF;
  margin-top: 18px;
  margin-bottom: 16px;
}

.this-table-outline {
  padding-top: 32px;
  border: 2px solid black;
  height: 453px;
  background: $ndt-debug-color;
  margin-left: $ndt-main-panel-left-margin;
  margin-right: $ndt-main-panel-right-margin;
}

.this-button-done-small {
  width: 110px;
  height: 31px;
  border: 1px solid $ndt-color-5;
  background: $ndt-color-5;
  border-radius: 72px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: $ndt-color-2;
}
.ag-theme-alpine {
    --ag-font-size: 12px;
}
</style>
