<template>
  <div>

    <b-card v-if="createSuccessful" class="text-center ndt-borderless-card">
      <h5>Your password was successfully updated</h5>
      <button class="ndt-button-2 ndt-bg-5" @click="$emit('closeMe')">
        Ok
      </button>
    </b-card>

    <b-card v-else class="ndt-borderless-card" style="margin-top: -1rem">
      <h5>Changing password for {{ user }} </h5>
      <b-form v-on:submit.prevent="changePasswordSubmit">
        <b-form-group label="Current password" label-for="current">
          <b-form-input id="current" type="password" name="current" placeholder="Type your existing password here" v-model="changePasswordForm.old_password"
              autoFocus autoComplete="off" />
        </b-form-group>
        <b-form-group label="New password" label-for="new">
          <b-form-input id="new" type="password" name="new" placeholder="Type your new password here" v-model="changePasswordForm.new_password"
              autoComplete="off" />
        </b-form-group>
        <button class="ndt-button-2 ndt-bg-5" type="submit">Change</button>
        <span class="ml-2">
          <button class="ndt-button-2 ndt-bg-6 ndt-text-light" @click.prevent="$emit('closeMe')">
            Cancel
          </button>
        </span>
      </b-form>
    </b-card>

    <b-card v-if="errorText">
      {{errorText}}
    </b-card>

  </div>
</template>

<script>
export default {
  name: 'PasswordManage',
  data () {
    return {
      changePasswordForm: {
      },
      location: 'PasswordManage.vue',
      errorText: null,
      createSuccessful: false,
      createdName: null,
      user: this.$store.getters['auth/user'],
      userId: null
    }
  },

  computed: {
  },

  mounted () {
    this.getAccounts()
    this.getUserId()
  },

  methods: {
    getAccounts: function () {
      this.$store.dispatch('data/getAccounts')
    },
    getUserId: function () {
      this.$api.getAuthUser()
        .then(resp => {
          this.userId = resp.id
        })
        .catch(err => {
          console.log('***AccountManage.vue error', err)
        })
    },

    isEmpty: function (input) {
      return !input || !input.trim()
    },

    changePasswordSubmit: function (e) {
      // console.log('PASSWORD', this.changePasswordForm)
      this.errorText = null
      this.$api.putChangePassword(this.changePasswordForm).then(data => {
        if (data.response) {
          if (data.response.data.new_password) {
            this.errorText = data.response.data.new_password[0]
          } else if (data.response.data.old_password) {
            this.errorText = 'Current password is incorrect'
          } else {
            this.errorText = 'There was an error during password update'
          }
        } else {
          this.createSuccessful = true
        }
      }).catch(() => {
      })
    }

  }
}
</script>

<style scoped lang="scss">
</style>
