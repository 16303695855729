// import axios from 'axios'
import api from '@/utils/api.js'

const state = {
  activeAccount: null,
  activeAccountSet: false,
  layout: 'indoor',
  layoutDescription: 'indoor food production',
  currentView: 'home',
  userPrefs: {},
  userPrefsSet: false,
  isAdmin: false,
  mediaType: 'desktop',
  deviceType: 'desktop',
  betaShow: false,
  fullApp: true,
  width: 50,
  device_id: null,
  userId: null,
  unit: 'ppm',
  unitName: 'ppm',
  // choices: ppm, mmol, second
  showNoStaffAnalyses: false,
  debugOn: false,
  sidebarCollapsed: false
}

const mutations = {
  layout_set (state, data) {
    state.layout = data
    // console.log('***updating layout', data)
    if (data === 'indoor') {
      state.layoutDescription = 'indoor food production'
    } else if (data === 'outdoor') {
      state.layoutDescription = 'outdoor agriculture'
    } else {
      state.layoutDescription = 'other'
    }
  },
  layout_set_from_web (state, data) {
    console.log('***updating layout from web', data)
    if (data === 'IN') {
      state.layout = 'indoor'
    } else if (data === 'OUT') {
      state.layout = 'outdoor'
    } else if (data === 'ADM') {
      state.layout = 'admin'
    } else {
      state.layout = 'home'
    }
  },
  current_view_set (state, data) {
    state.currentView = data
  },
  active_account_set (state, data) {
    state.activeAccount = data
    state.activeAccountSet = true
  },
  active_account_error (state) {
    state.activeAccount = {}
    state.activeAccountSet = false
  },
  userprefs_set (state, data) {
    state.userPrefs = data
    state.userPrefsSet = true
    if (data.web_device_id) {
      state.device_id = data.web_device_id
    }
    if (data.admin_access) {
      state.isAdmin = true
    } else {
      state.isAdmin = false
    }
  },
  userprefs_error (state) {
    state.userPrefs = {}
    state.userPrefsSet = false
  },
  media_type_set (state, data) {
    state.mediaType = data
  },
  device_type_set (state, data) {
    state.deviceType = data
  },
  full_app_set (state, data) {
    state.fullApp = data
  },
  no_staff_analyses_set (state, data) {
    state.showNoStaffAnalyses = data
  },
  user_id_set (state, data) {
    state.userId = data
  },
  width_set (state, data) {
    state.width = data
  },
  beta_show_set (state, data) {
    state.betaShow = data
  },
  debug_on_set (state, data) {
    state.debugOn = data
  },
  unit_set (state, data) {
    state.unit = data
    if (data === 'mmol') {
      state.unitName = 'mmol/L'
    } else if (data === 'second') {
      state.unitName = 'ppm as element'
    } else {
      state.unitName = 'ppm'
    }
  },
  sidebar_collapsed_set (state, data) {
    state.sidebarCollapsed = data
  }
}

const getters = {
  layout: state => state.layout,
  layoutDescription: state => state.layoutDescription,
  activeAccount: state => state.activeAccount,
  accountSet: state => state.activeAccountSet,
  userPrefs: state => state.userPrefs,
  userPrefsSet: state => state.userPrefsSet,
  isAdmin: state => state.isAdmin,
  mediaType: state => state.mediaType,
  deviceType: state => state.deviceType,
  currentView: state => state.currentView,
  width: state => state.width,
  stationId: state => state.stationId,
  unit: state => state.unit,
  unitName: state => state.unitName,
  device_id: state => state.device_id,
  userId: state => state.userId,
  fullApp: state => state.fullApp,
  betaShow: state => state.betaShow,
  debugOn: state => state.debugOn,
  showNoStaffAnalyses: state => state.showNoStaffAnalyses,
  sidebarCollapsed: state => state.sidebarCollapsed
}

const actions = {

  setActiveAccount ({ commit, getters }, accountInfo) {
    // whenever we set the active account locally we update the preferences on Console
    // console.log('***setActiveAccount userprefs, accountInfo: ', state.userPrefs, accountInfo, accountInfo.id, accountInfo[0].id)
    console.log('***setActiveAccount userprefs, accountInfo: ', getters.userPrefs.id)
    // const userPrefsPk = state.userPrefs.id
    const userPrefsPk = getters.userPrefs.id
    // accountInfo is a list and we only want the first value
    const data = { last_account: accountInfo[0].id }
    return new Promise((resolve, reject) => {
      api.patchUserPrefs(userPrefsPk, data)
        .then(resp => {
          commit('active_account_set', accountInfo[0])
          commit('userprefs_set', resp)
          resolve(resp)
        })
        .catch(err => {
          console.log('***error in patch useprefs ', err)
          commit('userprefs_error')
          reject(err)
        })
    })
  },

  setLayout ({ commit }, layout) {
    commit('layout_set', layout)
  },

  setCurrentView ({ commit }, view) {
    commit('current_view_set', view)
  },

  getUserPrefs ({ commit }) {
    return new Promise((resolve, reject) => {
      api.getUserPrefs()
        .then(resp => {
          if (resp.length > 0) {
            commit('userprefs_set', resp[0])
            console.log('***UserPrefs', resp[0])
            // note get always returns a list, but we only want the first (and in fact only) value
            if (resp[0].last_account) {
              const commitData = { name: resp[0].last_account_name, id: resp[0].last_account }
              commit('active_account_set', commitData)
            }
            commit('layout_set_from_web', resp[0].web_layout)
          } else {
            commit('userprefs_set', [])
          }
          resolve(resp)
        })
        .catch(err => {
          commit('userprefs_error')
          reject(err)
        })
    })
  },

  setUserPrefs ({ commit, getters }, data) {
    // userprefs are updated on console, and then response committed to state
    const userPrefsPk = getters.userPrefs.id
    console.log('***setUserPrefs 1: ', userPrefsPk)
    return new Promise((resolve, reject) => {
      api.patchUserPrefs(userPrefsPk, data)
        .then(resp => {
          commit('userprefs_set', resp)
          console.log('***setUserPrefs 2: ', resp)
          resolve(resp)
        })
        .catch(err => {
          commit('userprefs_error')
          reject(err)
        })
    })
  },

  setUserId ({ commit }) {
    return new Promise((resolve, reject) => {
      api.getAuthUser()
        .then(resp => {
          commit('user_id_set', resp.id)
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  setMediaType ({ commit }, value) {
    commit('media_type_set', value)
  },

  setDeviceType ({ commit }, value) {
    commit('device_type_set', value)
  },

  setWidth ({ commit }, value) {
    commit('width_set', value)
    let mediaType = 'desktop'
    if (value < 920) {
      mediaType = 'laptop'
    }
    if (value < 800) {
      mediaType = 'tablet'
    }
    if (value < 576) {
      mediaType = 'mobile'
    }
    commit('media_type_set', mediaType)
  },

  setUnit ({ commit }, value) {
    commit('unit_set', value)
  },

  setFullApp ({ commit }, value) {
    commit('full_app_set', value)
  },

  setBetaShow ({ commit }, value) {
    commit('beta_show_set', value)
  },

  setDebugOn ({ commit }, value) {
    // commit('debug_on_set', value)
    commit('debug_on_set', true)
  },

  setShowNoStaffAnalyses ({ commit }, value) {
    commit('no_staff_analyses_set', value)
  },

  setSidebarCollapsed ({ commit }, value) {
    commit('sidebar_collapsed_set', value)
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
