<template>
  <div>
    <template v-if="isActive">
      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.25 13V8.125H11.375V13H3.25ZM3.25 16.25H11.375V21.125H3.25V16.25ZM14.625 21.125V16.25H22.75V21.125H14.625ZM22.75 13H14.625V8.125H22.75V13ZM3.25 1.625C1.45742 1.625 0 3.08242 0 4.875V21.125C0 22.9176 1.45742 24.375 3.25 24.375H22.75C24.5426 24.375 26 22.9176 26 21.125V4.875C26 3.08242 24.5426 1.625 22.75 1.625H3.25Z" fill="#64F08C"/>
      </svg>
    </template>
    <template v-else>
      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.25 13V8.125H11.375V13H3.25ZM3.25 16.25H11.375V21.125H3.25V16.25ZM14.625 21.125V16.25H22.75V21.125H14.625ZM22.75 13H14.625V8.125H22.75V13ZM3.25 1.625C1.45742 1.625 0 3.08242 0 4.875V21.125C0 22.9176 1.45742 24.375 3.25 24.375H22.75C24.5426 24.375 26 22.9176 26 21.125V4.875C26 3.08242 24.5426 1.625 22.75 1.625H3.25Z" fill="#158D38"/>
      </svg>
    </template>
  </div>
</template>

<script>
export default {
  name: 'DataIcon',

  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/styles/app.scss';

</style>
