<template>
  <div>
    <div class="ndt-debug" v-if="debugOn">
      {{getParentFileName}} / <strong>{{getFileName}}</strong>
    </div>

    <div class="d-flex">

      <div class="mt-5 ml-5">
        DIMENSIONS
        <Dimensions/>
      </div>

      <div class="mt-5 ml-5">
        FONTS
        <div style="font-size: 16px;">
          Default
        </div>
        <div style="font-family: 'Inter'; font-size: 16px;">
          Inter
        </div>
        <div style="font-family: 'Avenir'; font-size: 16px;">
          Avenir
        </div>
        <div style="font-family: 'Helvetica Neue'; font-size: 16px;">
          Helvetica Neue
        </div>
      </div>

      <div class="mt-5 ml-5">
        COLOURS
        <div class="d-flex">
          <div class="this-square ndt-bg-1"></div><div class="ml-3 mt-3">$ndt-color-1, white</div>
        </div>
        <div class="d-flex">
          <div class="this-square ndt-bg-2"></div><div class="ml-3 mt-3">$ndt-color-2, black</div>
        </div>
        <div class="d-flex">
          <div class="this-square ndt-bg-3"></div><div class="ml-3 mt-3">$ndt-color-3, dark green</div>
        </div>
        <div class="d-flex">
          <div class="this-square ndt-bg-4"></div><div class="ml-3 mt-3">$ndt-color-4, middle green</div>
        </div>
        <div class="d-flex">
          <div class="this-square ndt-bg-5"></div><div class="ml-3 mt-3">$ndt-color-5, light green</div>
        </div>
        <div class="d-flex">
          <div class="this-square ndt-bg-6"></div><div class="ml-3 mt-3">$ndt-color-6, orange</div>
        </div>
        <div class="d-flex">
          <div class="this-square ndt-bg-7"></div><div class="ml-3 mt-3">$ndt-color-7, blue</div>
        </div>
        <div class="d-flex">
          <div class="this-square ndt-bg-8"></div><div class="ml-3 mt-3">$ndt-color-8, grey-1</div>
        </div>
        <div class="d-flex">
          <div class="this-square ndt-bg-9"></div><div class="ml-3 mt-3">$ndt-color-9, grey-2</div>
        </div>
        <div class="d-flex">
          <div class="this-square ndt-bg-10"></div><div class="ml-3 mt-3">$ndt-color-10, grey-3</div>
        </div>
        <div class="d-flex">
          <div class="this-square ndt-bg-11"></div><div class="ml-3 mt-3">$ndt-color-11, grey-4</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GeneralMixin from '@/mixins/GeneralMixin.vue'
import Dimensions from '@/components/Dimensions.vue'

export default {
  name: 'DesignSpec',

  mixins: [GeneralMixin],

  data () {
    return {}
  },

  components: {
    Dimensions
  },

  mounted () {
    this.logEvent('Data tab mounted')
  },

  computed: {
  }
}
</script>

<style lang="scss" scoped>
  @import '@/styles/app.scss';

.this-square {
  height: 50px;
  width: 100px;
  border-radius: 5px;
}

.ndt-card-body {
  background: transparent;
  padding: 0rem 1rem 1rem 1rem;
  margin-top: -1rem;
}

.ndt-card-title {
  font-weight: bold;
}

.ndt-link-card {
  background-color: rgba(255, 255, 255, 1);
}

.ndt-link-card:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.this-group {
  padding: 0rem 1rem 0rem 1rem;
}

/* upload button */

.this-upload {
  position: relative;
}

.this-upload-dropdown-content ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.this-upload-dropdown-content {
  display: none;
  overflow: visible;
  position: absolute;
  left: 0rem;
  /* right: -3rem; */
  bottom: 2.3rem;
  background-color: #f9f9f9;
  min-width: 10rem;
  border: 1px solid $ndt-color-4;
  border-radius: 0.25rem;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 0.5rem;
  text-align: left;
  z-index: 5000;
}

.this-upload:hover .this-upload-dropdown-content {
  display: block;
}

</style>
