<template>
  <div class="this-dropdown" @click="showAccountDropdown = !showAccountDropdown" v-click-outside="onClickOutside">
    <div class="this-dropdown-button">
      <div class="ndt-desktop-element">
        {{ thisAccount }}
      </div>
      <div class="this-chevron">
        <ChevronDown/>
      </div>
    </div>
    <div class="this-dropdown-content" v-if="showAccountDropdown">
      <ul>
        <li class="this-mobile-element this-dropdown-title">
          Current: {{ thisAccount }}
        </li>
        <li
          v-for="account in accountOptions"
          :key="account.id"
          class="this-dropdown-link"
          @click="handleDropdown(account)"
        >
          {{ account.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { eventsBus } from '@/main'
import GeneralMixin from '@/mixins/GeneralMixin.vue'
import ChevronDown from '@/assets/ChevronDown.vue'
import vClickOutside from 'v-click-outside'

export default {
  name: 'AccountSelector',

  directives: {
    clickOutside: vClickOutside.directive
  },

  components: {
    ChevronDown
  },

  mixins: [GeneralMixin],

  data () {
    return {
      showAccountDropdown: false
    }
  },

  computed: {
    accountOptions: function () {
      return this.$store.getters['data/accounts']
    },

    accountSelect: {
      get () {
        return this.$store.getters['options/activeAccount'].id
      },
      set (newValue) {
        const data = this.accountOptions.filter(obj => obj.id === newValue)
        console.log('***accountSelect newValue data: ', newValue, data)
        this.handleDropdown(data)
      }
    }

  },

  methods: {
    onClickOutside (event) {
      // console.log('Clicked outside. Event: ', event)
      this.showAccountDropdown = false
    },

    handleDropdown: async function (data) {
      // https://stackoverflow.com/questions/41160084/return-dictionary-object-in-an-array-by-searching-for-a-certain-value
      console.log('***SET ACCOUNT', data)
      // note data is a list in this context
      await this.$store.dispatch('options/setActiveAccount', [data])
      eventsBus.$emit('accountChanged', '')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/styles/app.scss';

  .this-dropdown {
    position: relative
  }

  .this-dropdown-button {
    width: 204px;
    height: 43px;    /* changed from 44.21 */
    background: $ndt-color-4;
    border: 1px solid #002323;
    border-radius: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $ndt-color-1;
    margin-bottom: 16px;
    padding-top: 12px;
  }

  .this-dropdown-content {
    // display: none;
    overflow: visible;
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: $ndt-color-4;
    width: 204px;
    border: 1px solid #002323;
    border-radius: 10px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px;
    text-align: left;
    z-index: 5000;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $ndt-color-1;
  }

  .this-dropdown-content ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .this-dropdown:hover .this-dropdown-content {
    display: block;
  }

  .this-dropdown-title {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .this-dropdown-link {
    font-size: 16px;
    cursor: pointer;
  }

  .this-dropdown-link:hover {
    color: $ndt-color-6;
  }

  .this-chevron {
    position: absolute;
    top: 10px;  // 8
    left: 175px;    // 200
  }

  .this-desktop-element {
    display: block;
  }

  .this-mobile-element {
    display: none;
  }

  @media (max-width: $tablet-breakpoint) {
    .this-dropdown-button {
      width: 35px;
      height: 35px;
    }

    .this-dropdown-content {
      top: 0px;
      left: -167px;
    }

    .this-chevron {
      position: absolute;
      top: 7px;  // 8
      left: 9px;    // 200
    }

    .this-desktop-element {
      display: none;
    }

    .this-mobile-element {
      display: block;
    }
  }
</style>
