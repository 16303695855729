<template>
  <div>
    <template v-if="isActive">
      <svg width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.28571 7.7143C4.28571 6.76608 5.05339 6.00001 6 6.00001C6.94821 6.00001 7.71429 6.76608 7.71429 7.7143C7.71429 8.66251 6.94821 9.42858 6 9.42858C5.05339 9.42858 4.28571 8.66251 4.28571 7.7143ZM10.5804 1.71429C11.4911 1.71429 12.3589 2.07537 13.0018 2.71876L22.4304 12.1447C23.7696 13.4839 23.7696 15.6589 22.4304 16.9982L15.2839 24.1447C13.9446 25.4839 11.7696 25.4839 10.4304 24.1447L1.00446 14.7161C0.361232 14.0732 0 13.2054 0 12.2947V4.28572C0 2.86554 1.15125 1.71429 2.57143 1.71429H10.5804ZM2.82268 12.9L12.2518 22.3286C12.5839 22.6661 13.1304 22.6661 13.4625 22.3286L20.6143 15.1768C20.9518 14.8447 20.9518 14.2982 20.6143 13.9661L11.1857 4.53697C11.025 4.37626 10.8054 4.28572 10.5804 4.28572H2.57143V12.2947C2.57143 12.5197 2.66196 12.7393 2.82268 12.9Z" fill="#64F08C"/>
      </svg>
    </template>
    <template v-else>
      <svg width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.28571 7.7143C4.28571 6.76608 5.05339 6.00001 6 6.00001C6.94821 6.00001 7.71429 6.76608 7.71429 7.7143C7.71429 8.66251 6.94821 9.42858 6 9.42858C5.05339 9.42858 4.28571 8.66251 4.28571 7.7143ZM10.5804 1.71429C11.4911 1.71429 12.3589 2.07537 13.0018 2.71876L22.4304 12.1447C23.7696 13.4839 23.7696 15.6589 22.4304 16.9982L15.2839 24.1447C13.9446 25.4839 11.7696 25.4839 10.4304 24.1447L1.00446 14.7161C0.361232 14.0732 0 13.2054 0 12.2947V4.28572C0 2.86554 1.15125 1.71429 2.57143 1.71429H10.5804ZM2.82268 12.9L12.2518 22.3286C12.5839 22.6661 13.1304 22.6661 13.4625 22.3286L20.6143 15.1768C20.9518 14.8447 20.9518 14.2982 20.6143 13.9661L11.1857 4.53697C11.025 4.37626 10.8054 4.28572 10.5804 4.28572H2.57143V12.2947C2.57143 12.5197 2.66196 12.7393 2.82268 12.9Z" fill="#158D38"/>
      </svg>
    </template>
  </div>
</template>

<script>
export default {
  name: 'DataIcon',

  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/styles/app.scss';

</style>
