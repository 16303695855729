<template lang="html">
  <div class="this-bottom-navbar">
    <div class="this-footer-nav d-flex justify-content-around">
      <div class="this-nav-item" :class="{ 'this-nav-item-active': currentView.rooms }" style="margin-top: 11px;">
        <b-link to="/rooms" class="">
          <RoomIcon class="this-link-icon" :isActive="currentView.rooms" />
          <div class="this-link-text">Home</div>
        </b-link>
      </div>
      <div class="this-nav-item" :class="{ 'this-nav-item-active': currentView.data }" style="margin-top: 14px;">
        <b-link to="/data" class="text-center">
          <DataIcon class="this-link-icon" :isActive="currentView.data" />
          <div class="this-link-text">Data</div>
        </b-link>
      </div>
      <div class="this-nav-item" :class="{ 'this-nav-item-active': currentView.charts }" style="margin-top: 14px;">
        <b-link to="/charts" class="">
          <ChartIcon class="this-link-icon" :isActive="currentView.charts" />
          <div class="this-link-text">Charts</div>
        </b-link>
      </div>
      <div v-if="isAdmin" class="this-nav-item" :class="{ 'this-nav-item-active': currentView.charts }"
        style="margin-top: 0px;">
        <b-link to="/deviceHandler" class="">
          <DeviceIcon class="this-link-icon" :isActive="currentView.deviceHandler" />
          <div class="this-link-text">Device Handler</div>
        </b-link>
      </div>
      <div v-if="isAdmin" class="this-nav-item this-desktop-element"
        :class="{ 'this-nav-item-active': currentView.admin }">
        <b-link to="/admin" class="">
          <DataIcon class="this-link-icon" :isActive="currentView.admin" />
          <div class="this-link-text">Admin</div>
        </b-link>
      </div>
    </div>
  </div>
</template>

<script>
import GeneralMixin from '@/mixins/GeneralMixin.vue'
import RoomIcon from '@/assets/RoomIcon.vue'
import ChartIcon from '@/assets/ChartIcon.vue'
import DataIcon from '@/assets/DataIcon.vue'
import DeviceIcon from '@/assets/DeviceIcon.vue'

export default {
  name: 'AppFooter',

  components: {
    RoomIcon,
    ChartIcon,
    DataIcon,
    DeviceIcon
  },

  mixins: [GeneralMixin],

  data () {
    return {
    }
  },

  computed: {
    mediaType: function () { return this.$store.getters['options/mediaType'] }
  }

}
</script>

<style lang="scss" scoped>
@import '@/styles/app.scss';

.this-bottom-navbar {
  display: none;
  background-color: $ndt-color-3;
  position: fixed;
  bottom: 0rem;
  width: 100%;
  height: 72px;
  z-index: 1000;
  /*  overflow: auto; */
  // I have put this on the data tab ul element for now
}

@media (max-width: $tablet-breakpoint) {
  .this-bottom-navbar {
    display: block;
  }
}

.this-footer-nav {
  margin-top: 0px;
}

.this-nav-item {
  margin-top: 10px;
  border-radius: 8px;
  margin-bottom: 0px;
  padding: 5px;
}

.this-nav-item-active {
  background: $ndt-color-4;
}

.this-link-icon {
  margin-left: 0px;
  margin-top: 0px;
}

.this-link-text {
  margin-left: 0px;
  margin-top: 2px;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFF;
}

.this-desktop-element {
  display: block;
}

@media (max-width: $tablet-breakpoint) {
  .this-desktop-element {
    display: none;
  }
}
</style>
