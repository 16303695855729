// import axios from 'axios'
// import api from '@/utils/api.js'

const state = {
  farmsActive: false,
  fieldsActive: false,
  zonesActive: false,
  stationsActive: true
}

const mutations = {
  farmsActiveSet (state, flag) {
    state.farmsActive = flag
  },
  fieldsActiveSet (state, flag) {
    state.fieldsActive = flag
  },
  zonesActiveSet (state, flag) {
    state.zonesActive = flag
  },
  stationsActiveSet (state, flag) {
    state.stationsActive = flag
  }
}

const getters = {
  farmsActive: state => state.farmsActive,
  fieldsActive: state => state.fieldsActive,
  zonesActive: state => state.zonesActive,
  stationsActive: state => state.stationsActive
}

const actions = {
  setFarmsActive ({ commit }, flag) {
    commit('farmsActiveSet', flag)
  },
  setFieldsActive ({ commit }, flag) {
    commit('fieldsActiveSet', flag)
  },
  setZonesActive ({ commit }, flag) {
    commit('zonesActiveSet', flag)
  },
  setStationsActive ({ commit }, flag) {
    commit('stationsActiveSet', flag)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
