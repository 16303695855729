import { render, staticRenderFns } from "./RoomIcon.vue?vue&type=template&id=5f54991a&scoped=true&"
import script from "./RoomIcon.vue?vue&type=script&lang=js&"
export * from "./RoomIcon.vue?vue&type=script&lang=js&"
import style0 from "./RoomIcon.vue?vue&type=style&index=0&id=5f54991a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f54991a",
  null
  
)

export default component.exports