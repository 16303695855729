<template>
  <div>
    <template v-if="isActive">
      <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.625 0C2.52383 0 3.25 0.726172 3.25 1.625V18.6875C3.25 19.1344 3.61562 19.5 4.0625 19.5H24.375C25.2738 19.5 26 20.2262 26 21.125C26 22.0238 25.2738 22.75 24.375 22.75H4.0625C1.81797 22.75 0 20.932 0 18.6875V1.625C0 0.726172 0.726172 0 1.625 0ZM8.125 9.75C9.02383 9.75 9.75 10.4762 9.75 11.375V14.625C9.75 15.5238 9.02383 16.25 8.125 16.25C7.22617 16.25 6.5 15.5238 6.5 14.625V11.375C6.5 10.4762 7.22617 9.75 8.125 9.75ZM14.625 6.5V9.75C14.625 10.6488 13.8988 11.375 13 11.375C12.1012 11.375 11.375 10.6488 11.375 9.75V6.5C11.375 5.60117 12.1012 4.875 13 4.875C13.8988 4.875 14.625 5.60117 14.625 6.5ZM17.875 1.625C18.7738 1.625 19.5 2.35117 19.5 3.25V6.5C19.5 7.39883 18.7738 8.125 17.875 8.125C16.9762 8.125 16.25 7.39883 16.25 6.5V3.25C16.25 2.35117 16.9762 1.625 17.875 1.625ZM24.375 3.25V14.625C24.375 15.5238 23.6488 16.25 22.75 16.25C21.8512 16.25 21.125 15.5238 21.125 14.625V3.25C21.125 2.35117 21.8512 1.625 22.75 1.625C23.6488 1.625 24.375 2.35117 24.375 3.25Z" fill="#64F08C"/>
      </svg>
    </template>
    <template v-else>
      <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.625 0C2.52383 0 3.25 0.726172 3.25 1.625V18.6875C3.25 19.1344 3.61562 19.5 4.0625 19.5H24.375C25.2738 19.5 26 20.2262 26 21.125C26 22.0238 25.2738 22.75 24.375 22.75H4.0625C1.81797 22.75 0 20.932 0 18.6875V1.625C0 0.726172 0.726172 0 1.625 0ZM8.125 9.75C9.02383 9.75 9.75 10.4762 9.75 11.375V14.625C9.75 15.5238 9.02383 16.25 8.125 16.25C7.22617 16.25 6.5 15.5238 6.5 14.625V11.375C6.5 10.4762 7.22617 9.75 8.125 9.75ZM14.625 6.5V9.75C14.625 10.6488 13.8988 11.375 13 11.375C12.1012 11.375 11.375 10.6488 11.375 9.75V6.5C11.375 5.60117 12.1012 4.875 13 4.875C13.8988 4.875 14.625 5.60117 14.625 6.5ZM17.875 1.625C18.7738 1.625 19.5 2.35117 19.5 3.25V6.5C19.5 7.39883 18.7738 8.125 17.875 8.125C16.9762 8.125 16.25 7.39883 16.25 6.5V3.25C16.25 2.35117 16.9762 1.625 17.875 1.625ZM24.375 3.25V14.625C24.375 15.5238 23.6488 16.25 22.75 16.25C21.8512 16.25 21.125 15.5238 21.125 14.625V3.25C21.125 2.35117 21.8512 1.625 22.75 1.625C23.6488 1.625 24.375 2.35117 24.375 3.25Z" fill="#158D38"/>
      </svg>
    </template>
  </div>
</template>

<script>
export default {
  name: 'DataIcon',

  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/styles/app.scss';

</style>
