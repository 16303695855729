<template>
  <!-- :class="{ 'this-nav-active': currentView.home }" -->
  <div class="this-sidebar" :class="{ 'this-sidebar-collapse': isCollapsed }">

    <div class="this-sidebar-header" :class="{ 'this-sidebar-header-collapsed': isCollapsed }" >
      <div v-if="isCollapsed" class="this-logo-container-collapsed">
        <LogoNoText/>
      </div>
      <div v-else class="this-logo-container">
        <LogoWhite/>
      </div>
      <div v-if="!isCollapsed" class="this-account-select-container">
        <AccountSelector/>
      </div>
    </div>

    <!-- <div class="this-toggler"  :class="{ 'this-toggler-collapse': isCollapsed }" @click="collapseSidebar">
     <b-icon v-if="isCollapsed" icon="chevron-double-right" font-scale="1.5"></b-icon>
     <b-icon v-else icon="chevron-double-left" font-scale="1.5"></b-icon>
    </div> -->
    <div class="this-toggler"  :class="{ 'this-toggler-collapse': isCollapsed }" @click="collapseSidebar">
     <b-icon v-if="isCollapsed" icon="caret-right-fill" font-scale="1.8"></b-icon>
     <b-icon v-else icon="caret-left-fill" font-scale="1.8"></b-icon>
    </div>
    <div class="this-sidebar-nav">
      <div class="this-nav-item" :class="{ 'this-nav-item-active' : currentView.rooms, 'this-nav-item-collapse' : isCollapsed }">
        <b-link to="/rooms" class="d-flex">
          <RoomIcon class="this-link-icon" :isActive="currentView.rooms"/>
          <div v-if="!isCollapsed" class="this-link-text">Home</div>
        </b-link>
      </div>
      <div class="this-nav-item" @click="toggleDataDropDown()"
       :class="{ 'this-nav-item-active' : currentView.data || currentView.analyzer || currentView.labreports || currentView.quickloggertable, 'this-nav-item-collapse' : isCollapsed }">
        <b-link class="d-flex">
          <DataIcon class="this-link-icon" :isActive="currentView.data"/>
          <div v-if="!isCollapsed" class="this-link-text">Data</div>
          <b-icon class = "this-data-dropdown-toggle-icon"  v-if="!showDataDropDown && !isCollapsed" font-scale="1.3" icon="chevron-right"></b-icon>
          <b-icon class = "this-data-dropdown-toggle-icon"  v-if="showDataDropDown && !isCollapsed" font-scale="1.3" icon="chevron-down"></b-icon>
        </b-link>

      </div>

        <div v-if="showDataDropDown"
        class="this-nav-item" :class="{ 'this-nav-item-active' : currentView.analyzer, 'this-nav-item-collapse' : isCollapsed }">
        <b-link to="/data/analyzer" class="d-flex">
          <DataIcon class="this-sub-link-icon" :isActive="currentView.analyzer"/>
          <div v-if="!isCollapsed" class="this-link-text" >Analyzer</div>
        </b-link>
        </div>
        <div v-if="showDataDropDown"
        class="this-nav-item" :class="{ 'this-nav-item-active' : currentView.labreports, 'this-nav-item-collapse' : isCollapsed }">
        <b-link to="/data/labreports" class="d-flex">
          <DataIcon class="this-sub-link-icon" :isActive="currentView.labreports"/>
          <div v-if="!isCollapsed" class="this-link-text" >Lab Reports</div>
        </b-link>
        </div>
        <div v-if="showDataDropDown"
        class="this-nav-item" :class="{ 'this-nav-item-active' : currentView.quickloggertable, 'this-nav-item-collapse' : isCollapsed }">
        <b-link to="/data/quickloggertable" class="d-flex">
          <DataIcon class="this-sub-link-icon" :isActive="currentView.quickloggertable"/>
          <div v-if="!isCollapsed" class="this-link-text">pH/EC</div>
        </b-link>
      </div>
      <div class="this-nav-item" @click="toggleChartDropDown()"
      :class="{ 'this-nav-item-active' : currentView.charts || currentView.chartsanalyzer || currentView.lab || currentView.quickloggerchart, 'this-nav-item-collapse' : isCollapsed }">
        <b-link class="d-flex">
          <ChartIcon class="this-link-icon" :isActive="currentView.charts"/>
          <div v-if="!isCollapsed" class="this-link-text">Charts</div>
          <b-icon class = "this-chart-dropdown-toggle-icon"  v-if="!showChartDropDown && !isCollapsed" font-scale="1.3" icon="chevron-right"></b-icon>
          <b-icon class = "this-chart-dropdown-toggle-icon"  v-if="showChartDropDown && !isCollapsed" font-scale="1.3" icon="chevron-down"></b-icon>
        </b-link>
        </div>
        <div v-if="showChartDropDown"
        class="this-nav-item" :class="{ 'this-nav-item-active' : currentView.chartsanalyzer, 'this-nav-item-collapse' : isCollapsed }">
        <b-link to="/charts/analyzer" class="d-flex">
          <ChartIcon class="this-sub-link-icon" :isActive="currentView.chartsanalyzer"/>
          <div v-if="!isCollapsed" class="this-link-text">Analyzer</div>
        </b-link>
        </div>
        <div v-if="showChartDropDown"
        class="this-nav-item" :class="{ 'this-nav-item-active' : currentView.lab, 'this-nav-item-collapse' : isCollapsed }">
        <b-link to="/charts/lab" class="d-flex">
          <ChartIcon class="this-sub-link-icon" :isActive="currentView.lab"/>
          <div v-if="!isCollapsed" class="this-link-text">Lab Reports</div>
        </b-link>
        </div>
        <div v-if="showChartDropDown"
        class="this-nav-item" :class="{ 'this-nav-item-active' : currentView.quickloggerchart, 'this-nav-item-collapse' : isCollapsed }">
        <b-link to="/charts/quickloggerchart" class="d-flex">
          <ChartIcon class="this-sub-link-icon" :isActive="currentView.quickloggerchart"/>
          <div v-if="!isCollapsed" class="this-link-text">pH/EC</div>
        </b-link>
      </div>
      <div v-if="isAdmin" class="this-nav-item this-desktop-element"
        :class="{ 'this-nav-item-active' : currentView.admin, 'this-nav-item-collapse' : isCollapsed }">
        <b-link to="/admin" class="d-flex">
          <DataIcon class="this-link-icon" :isActive="currentView.admin"/>
          <div v-if="!isCollapsed" class="this-link-text">Admin</div>
        </b-link>
      </div>

<!-- ///////////////////////////////// -->
  <div class="this-nav-item" @click="toggleDeviceHandlerDropdown()"
  :class="{ 'this-nav-item-active' : currentView.deviceHandler || currentView.devicedashboard  || currentView.wificonfig, 'this-nav-item-collapse' : isCollapsed }">
    <b-link class="d-flex">
      <DeviceIcon class="this-device-link-icon" />
      <div v-if="!isCollapsed" class="this-link-text">Device</div>
      <b-icon class = "this-device-handler-dropdown-toggle-icon"  v-if="!showDeviceHandlerDropdown && !isCollapsed" font-scale="1.3" icon="chevron-right"></b-icon>
      <b-icon class = "this-device-handler-dropdown-toggle-icon"  v-if="showDeviceHandlerDropdown && !isCollapsed" font-scale="1.3" icon="chevron-down"></b-icon>
    </b-link>
    </div>
    <div v-if="showDeviceHandlerDropdown"
    class="this-nav-item" :class="{ 'this-nav-item-active' : currentView.devicedashboard, 'this-nav-item-collapse' : isCollapsed }">
    <b-link to="/deviceHandler/devicedashboard" class="d-flex">
      <DeviceIcon class="this-device-sub-link-icon" :isActive="currentView.devicedashboard"/>
      <div v-if="!isCollapsed" class="this-link-text">Dashboard</div>
    </b-link>
    </div>
    <div v-if="showDeviceHandlerDropdown"
    class="this-nav-item" :class="{ 'this-nav-item-active' : currentView.wificonfig, 'this-nav-item-collapse' : isCollapsed }">
    <b-link to="/deviceHandler/wificonfig" class="d-flex">
      <DeviceIcon class="this-device-sub-link-icon" :isActive="currentView.wificonfig"/>
      <div v-if="!isCollapsed" class="this-link-text">Configure WiFi</div>
    </b-link>
    </div>
<!-- ///////////////////////////////// -->

      <!-- <div v-if="isAdmin"
        class="this-nav-item" :class="{ 'this-nav-item-active' : currentView.deviceHandler, 'this-nav-item-collapse' : isCollapsed }">
        <b-link to="/deviceHandler" class="d-flex">
          <DeviceIcon class="this-link-icon" :isActive="currentView.deviceHandler"/>
          <div v-if="!isCollapsed" class="this-link-text">Device Handler</div>
        </b-link>
        </div> -->
    </div>

    <div v-if="!isCollapsed && sidebarVisible" class="this-person-container">
      <PersonMenuDesktop/>
    </div>

    <div v-if="isCollapsed" class="this-person-container">
      <PersonMenu/>
    </div>

    <div v-if="!sidebarVisible" class="this-person-container">
      <PersonMenu/>
    </div>
  </div>
</template>

<script>
import GeneralMixin from '@/mixins/GeneralMixin.vue'
import { eventsBus } from '@/main'
import LogoWhite from '@/assets/LogoWhite.vue'
import LogoNoText from '@/assets/LogoNoText.vue'
// import ChevronDown from '@/assets/ChevronDown.vue'
import RoomIcon from '@/assets/RoomIcon.vue'
// import AvatarIcon from '@/assets/AvatarIcon.vue'
import ChartIcon from '@/assets/ChartIcon.vue'
import DeviceIcon from '@/assets/DeviceIcon.vue'
import DataIcon from '@/assets/DataIcon.vue'
import AccountSelector from '@/components/AccountSelector.vue'
import PersonMenu from '@/components/PersonMenu.vue'
import PersonMenuDesktop from '@/components/PersonMenuDesktop.vue'

export default {
  name: 'TheSidebar',

  components: {
    LogoWhite,
    LogoNoText,
    // ChevronDown,
    RoomIcon,
    ChartIcon,
    DataIcon,
    // AvatarIcon,
    AccountSelector,
    PersonMenu,
    PersonMenuDesktop,
    DeviceIcon
  },

  mixins: [GeneralMixin],

  props: {
  },

  data () {
    return {
      accountSelect: this.$store.getters['options/activeAccount'].id,
      accountFormMessage: 'Choose an Account',
      changeSuccessful: false,
      errorText: null,
      counter: 0,
      isCollapsed: false,
      showDataDropDown: false,
      showChartDropDown: false,
      showDeviceHandlerDropdown: false
    }
  },

  computed: {
    currentView: function () {
      const view = this.$store.getters['options/currentView']
      const retValue = {}
      retValue[view] = true
      return retValue
    },
    currentMain: function () {
      const view = this.$store.getters['options/currentView']
      console.log('***View is', view)
      if (['sample', 'station', 'production', 'batch', 'target', 'data'].indexOf(view) > -1) {
        return 'manage'
      } else if (['adminAnalysis', 'analyte', 'device', 'clientEvent', 'analytics', 'admin'].indexOf(view) > -1) {
        return 'admin'
      } else {
        return ''
      }
    },
    accountOptions: function () {
      return this.$store.getters['data/accounts']
    }
  },

  mounted () {
    this.logDebug(`mounted ${this.$options.name}`)
  },

  created () {
  },

  methods: {
    toggleDataDropDown: function () {
      this.showDataDropDown = !this.showDataDropDown
      if (this.showDataDropDown) {
        this.showDeviceHandlerDropdown = false
        this.showChartDropDown = false
      }
      console.log('toggleDataDropDown clicked!')
    },

    toggleChartDropDown: function () {
      this.showChartDropDown = !this.showChartDropDown
      if (this.showChartDropDown) {
        this.showDeviceHandlerDropdown = false
        this.showDataDropDown = false
      }
      console.log('toggleChartDropDown clicked!')
    },
    toggleDeviceHandlerDropdown: function () {
      this.showDeviceHandlerDropdown = !this.showDeviceHandlerDropdown
      if (this.showDeviceHandlerDropdown) {
        this.showDataDropDown = false
        this.showChartDropDown = false
      }
      console.log('toggleDeviceHandlerDropdown clicked!')
    },
    collapseSidebar: function () {
      // if (!this.isCollapsed) {
      //   this.showDataDropDown = false
      //   this.showChartDropDown = false
      // }
      this.showDataDropDown = false
      this.showChartDropDown = false
      this.showDeviceHandlerDropdown = false
      this.isCollapsed = !this.isCollapsed
      this.$store.dispatch('options/setSidebarCollapsed', this.isCollapsed)
    },

    setAccount: async function () {
      // https://stackoverflow.com/questions/41160084/return-dictionary-object-in-an-array-by-searching-for-a-certain-value
      const data = this.accountOptions.filter(obj => obj.id === this.accountSelect)
      console.log('!!!!!setting account data accountSelect', data, this.accountSelect)
      // note data is a list in this context
      await this.$store.dispatch('options/setActiveAccount', data)
      this.accountFormMessage = 'Change Account'
      eventsBus.$emit('accountChanged', '')
      this.changeSuccessful = true
    },

    userLogout: function (event) {
      console.log('***LOG OFF')
      this.$store.dispatch('auth/logout')
        .then(() => {
          this.$router.push('/')
        })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/app.scss';

.this-sidebar {
  width: $expanded-sidebar-width;     // 257px
  /* Green */
  display: block;
  background: #003232;
  height: 100%;
  width: $sidebar-width;
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  overflow-x: visible;
}

.this-sidebar-collapse {
  width: $collapsed-sidebar-width;
}

.this-sidebar-header {
  margin-top: 28px;
  width: 257px;
  border-bottom: 1px solid #053C37;
}

.this-sidebar-header-collapsed {
  margin-top: 28px;
  width: 50px;
}

.this-logo-container {
  /* margin-left: 20.5px; */
  text-align: center;
  margin-bottom: 32px;
}

.this-logo-container-collapsed {
  margin-left: 10px;
  text-align: center;
  margin-bottom: 32px;
}

.this-account-select-container {
  margin-left: 26.5px;
  cursor: pointer;
}

.this-form-select {
  width: 204px;
  height: 43px;    /* changed from 44.21 */
  background: #053C37;
  border: 1px solid #002323;
  border-radius: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  margin-bottom: 16px;
}

.this-toggler {
  margin-top: 25px;
  color: #64F08C;
}

.this-toggler-collapse {
  margin-top: 100px;
}

.this-sidebar-nav {
  margin-top: 34px;
  margin-left: 0px;
  width: 257px;
  height: 428.26px;
}

.this-nav-item {
  height: 55px;
  border-radius: 8px;
  margin-bottom: 0px;
  // margin-top: 5px;
}

.this-nav-item-active {
  background: #0D3C3C;
}

.this-data-sub-link{
margin-left: 15px
}
.this-nav-item-collapse {
  background: none;
}

.this-link-icon {
  margin-left: 24px;
  margin-top: 16px;
}

.this-device-link-icon {
  margin-left: 20px;
  margin-top: 9px;
  margin-right: -10px;
}

.this-sub-link-icon {
  margin-left: 38px;
  margin-top: 16px;
}

.this-device-sub-link-icon {
  margin-left: 38px;
  margin-top: 9px;
}

.this-link-text {
  margin-left: 12px;
  margin-top: 17.9px;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFF;
}

.this-person-container {
  display: block;
  background-color: $ndt-debug-color;
  position: fixed;
  bottom: 28px;
  left: 16px;
  cursor: pointer;
}

.this-person-icon {
  width: 40px;
  height: 40px;
  /* background: green; */
  border-radius: 8px;
}

.this-person-text {
  /*width: 131px;
  height: 19px;*/
  margin-top: 10.5px;
  margin-left: 12px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
}

.this-person-dropdown-content {
  display: none;
  position: absolute;
  left: 45px;
  bottom: 0px;
  background-color: $ndt-color-4;
  width: 130px;
  height: 40px;
  border: 1px solid $ndt-color-4;
  border-radius: 0.25rem;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 2px;
  text-align: left;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: $ndt-color-1
}

.this-person-dropdown-content-item {
  cursor: pointer;
  margin-top: 8px;
  margin-left: 8px;
}

.this-person-dropdown-container {
  position: relative;
  padding-right: 40px;
}

.this-person-dropdown-container:hover .this-person-dropdown-content {
  display: block;
}

@media (max-width: $tablet-breakpoint) {
  .this-sidebar {
    display: none;
  }
}

.this-desktop-element {
  display: block;
}

@media (max-width: $tablet-breakpoint) {
  .this-desktop-element {
    display: none;
  }
  .this-mobile-element {
    display: block;
  }
}

.this-data-dropdown-toggle-icon{
    margin-top: 7%;
    margin-left: 129px;
    color: #64F08C;
}

.this-chart-dropdown-toggle-icon{
    margin-top: 7%;
    margin-left: 117px;
    color: #64F08C;

}
.this-device-handler-dropdown-toggle-icon{
  margin-top: 7%;
  margin-left: 45%;
  color: #64F08C;
}

.this-mobile-element {
    display: none;
  }
</style>
