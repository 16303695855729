<template lang="html">
  <div>
    <div class="mt-3 ndt-debug" v-if="debugOn">
      {{getParentFileName}} / <strong>{{getFileName}}</strong>
    </div>

    <b-card v-if="createSuccessful" class="text-center ndt-borderless-card">
      <h5>Data uploaded</h5>
      <button @click="$emit('closeMe')" class="ndt-button-done-small">Ok</button>
    </b-card>

    <b-card v-else class="mt-5 mb-3 this-form-container" align="left">
      <b-card-text>
        <div class="text-left mb-4">
         <strong>Log pH/EC</strong>
        </div>
          <b-card class="ndt-error-text" v-if="errorText">
            <strong>{{errorText}}</strong>
          </b-card>

          <b-form v-on:submit.prevent="submit">

            <div v-for="item in genericVars" :key="item.id">
              <b-form-group v-if="item.name == 'pH'" :label="item.name" label-for="input1" >
                <b-form-input id="input1" :placeholder="'Enter ' + item.name" type="number" step="0.01" name="input1" v-model="values[item.id]"
                    autoFocus autoComplete="off" />
              </b-form-group>
              <b-form-group v-else :label="item.name + ' (' + item.unit + ')'" label-for="input1" >
                <b-form-input id="input1" :placeholder="'Enter ' + item.name" type="number" step="0.01" name="input1" v-model="values[item.id]"
                    autoFocus autoComplete="off" />
              </b-form-group>
            </div>

            <b-form-group label="Sampling station" label-for="stationselect" label-size="lg">
              <b-form-select
                style="font-size: 1rem"
                v-model="stationSelect"
                :options="stations"
                name="stationselect"
                value-field="id"
                text-field="name">
                <template #first>
                  <b-form-select-option value="null">-- Select a station from the list --</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>

            <button class="ndt-button-done-small" type="submit">Submit</button>
            <span class="ml-2">
              <button class="ndt-button-cancel-small" @click="$emit('closeMe')">
                Cancel
              </button>
            </span>

          </b-form>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import GeneralMixin from '@/mixins/GeneralMixin.vue'

export default {
  name: 'QuickLog',

  mixins: [GeneralMixin],

  components: {
  },

  data () {
    return {
      errorText: null,
      stationSelect: null,
      createSuccessful: false,
      values: {},
      spinnerOn: true
    }
  },

  mounted () {
    this.logEvent('Upload view mounted')
  },

  computed: {
    thisAccountPK: function () {
      const acc = this.$store.getters['options/activeAccount']
      if (acc) {
        return acc.id
      }
      return null
    },

    genericVars: function () {
      const data = this.$store.getters['data/genericPredefinedVars']
      // console.log('****GENERICVARS', data)
      return data
    },

    stations: function () {
      const data = this.$store.getters['data/stations']
      return data
    }
  },

  methods: {

    reset: function () {
      this.createSuccessful = false
      this.stationSelect = null
      this.values = []
      this.errorText = null
    },

    submit: function () {
      // postData will be a list of dictionaries
      // Go through each value in the values dictionary and create
      // Date() creates a string (not a Date object) at time now
      const postData = []
      const fixedItem = { location: this.stationSelect, account: this.thisAccountPK, collected: new Date() }
      if (!this.stationSelect) {
        this.errorText = 'You must choose a station for uploading data'
        return
      }
      if (!this.thisAccountPK) {
        this.errorText = 'You must choose an account before using this function'
        return
      }
      for (const [key, value] of Object.entries(this.values)) {
        const postItem = Object.assign({}, { variable: key, numeric_value: value }, fixedItem)
        postData.push(postItem)
      }
      console.log('***POSTDATA = ', postData)
      this.$api.postGenericData(postData).then(data => {
        this.errorText = null
        this.createSuccessful = true
        this.$store.dispatch('data/getGenericData')
      }).catch((error) => {
        if (error.non_field_errors) {
          this.errorText = error.non_field_errors[0]
        } else {
          this.errorText = error
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/styles/app.scss';

  .this-form-container {
    margin-left: 37px;
    margin-right: 118px;
    margin-top: 0rem;
    background-color: $ndt-color-1;
    border: none;
  }

</style>
