<template>
  <div>
  <div class="ndt-debug" v-if="debugOn">
    {{getParentFileName}} / <strong>{{getFileName}}</strong>
  </div>

    <b-card v-if="thisAccount === undefined" class="text-center ndt-borderless-card">
      <h6>You must first choose an account</h6>
      <button class="ndt-button-done-small" @click="$emit('closeMe')">Ok</button>
    </b-card>

    <b-card v-if="createSuccessful" class="text-center ndt-borderless-card">
      <h6 v-if="action.create"> {{ createdName }} was successfully created</h6>
      <h6 v-if="action.update"> {{ createdName }} was successfully updated</h6>
      <button class="ndt-button-done-small" @click="$emit('closeMe')">Ok</button>
    </b-card>

    <b-card v-if="thisAccount && !createSuccessful" class="ndt-borderless-card">
      <template v-if="thisObject">
        <h6 v-if="action.create">Add a child production unit inside {{thisObject.type}} {{thisObject.name}}</h6>
        <h6 v-if="action.update">Editing {{ thisObject.name }}</h6>
      </template>
      <template v-else>
        <div v-if="action.create">Creating new room in account {{ thisAccount }}</div>
        <h6 v-if="action.update">Editing {{ form.name }}</h6>
      </template>

      <b-card class="ndt-error-text" v-if="errorText">
        {{errorText}}
      </b-card>
      <template v-if="!prodObjectOptionsLoaded || !prodObjectTypeOptionsLoaded">
        <b-spinner style="width: 2rem; height: 2rem; margin-top: 0.5rem; margin-right: 0.5rem" label="Large Spinner"></b-spinner>
        <span>Loading data</span>
      </template>
      <template v-else>
        <b-form v-on:submit.prevent="submit">

          <b-form-group label="Name" label-for="input1">
            <b-form-input id="input1" placeholder="Enter name" type="text" name="id" v-model="form.name"
                autoFocus autoComplete="off" />
          </b-form-group>

          <!-- <b-form-group label="Type" label-for="input2" :description="typeSelectMessage">
            <b-form-select
              v-model="typeId"
              :options="prodObjectTypeOptions"
              value-field="id"
              text-field="name">
              <template #first>
                <b-form-select-option :value="null">-- Select from the list --</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group> -->

          <!-- <b-form-group label="Parent Object" label-for="input2" :description="prodObjectSelectMessage"> -->
          <!-- <b-form-group label-for="input2">
            <div slot="label">Parent object
              <span style="display: inline-block">
                <HelpIconSlot helpTarget="target-2">
                  Production units are nested inside other units. For example a grow-bench can be in a section, which in turn can be in a greenhouse.
                  If you are creating the top level production unit, then there will not be a parent and you leave this field blank.
                </HelpIconSlot>
              </span>
            </div>
            <b-form-select
              v-model="parentId"
              :options="prodObjectOptions"
              value-field="id"
              text-field="name">
              <template #first>
                <b-form-select-option :value="null">-- Select from the list --</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group> -->

          <!-- <b-form-group label="Description" label-for="input3">
            <b-form-input id="input3" placeholder="Enter a comment" type="text" name="tag1" v-model="form.tag"
                autoComplete="off" />
          </b-form-group> -->

          <button class="ndt-button-done-small" type="submit">Submit</button>
          <span class="ml-2"><button class="ndt-button-cancel-small" @click.prevent="$emit('closeMe')">Cancel</button></span>
        </b-form>
      </template>
    </b-card>

  </div>
</template>

<script>
import GeneralMixin from '@/mixins/GeneralMixin.vue'
// import HelpIconSlot from '@/assets/HelpIconSlot.vue'

export default {
  name: 'ProdObjectCreateAndUpdate',

  mixins: [GeneralMixin],

  data () {
    return {
      form: {
        // name: this.data.name,
        // tag: this.data.tag,
        name: '',
        tag: '',
        account: this.$store.getters['options/activeAccount'].id,
        parent: null,
        type: null
      },
      location: 'ProdObjectCreateAndUpdate.vue',
      objName: 'Production Unit',
      errorText: null,
      accountOptions: [],
      prodObjectOptions: [],
      prodObjectSelectMessage: '',
      typeSelectMessage: '',
      createSuccessful: false,
      createdName: null,
      parentId: null,
      prodObjectTypeOptions: [],
      typeId: null,
      prodObjectOptionsLoaded: false,
      prodObjectTypeOptionsLoaded: false
    }
  },

  components: {
    // HelpIconSlot
  },

  props: {
    action: {
      type: Object,
      default () {
        return { none: true, haveData: false }
      }
    },
    pk: {
      type: Number,
      default: null
    },
    data: {
      type: Object,
      default () {
        return { name: '', tag: '', type: null, parent: '' }
      }
    },
    thisObject: {
      type: Object,
      default () {
        return null
      }
    }
  },

  mounted () {
    if (this.action.create || (this.action.update && this.action.haveData)) {
      // console.log('****No need to call server')
      this.form.name = this.data.name
      this.form.tag = this.data.tag
      this.getProdObjectOptions()
      this.getProdObjectTypeOptions()
      this.setupForm()
    } else {
      console.log('****Need to call server to populate fields')
      this.populateFromServer()
      // this.getProdObjectOptions()
      // this.getProdObjectTypeOptions()
      this.setupForm()
    }
  },

  methods: {

    submit: function () {
      if (this.action.create) {
        this.createSubmit()
      } else {
        this.updateSubmit()
      }
    },

    getProdObjectOptions: function () {
      this.prodObjectOptionsLoaded = false
      this.$api.getProdObjectListMin()
        .then(response => {
          this.prodObjectOptions = response
          if (this.action.update) {
            const obj = response.find(o => o.name === this.data.parent)
            if (obj) { this.parentId = obj.id }
          } else {
            if (this.thisObject) {
              this.parentId = this.thisObject.id
            }
          }
          this.prodObjectOptionsLoaded = true
        })
        .catch(error => {
          console.log('***Error in getProdObjectOptions', this.location, ' : ', error)
          this.errorText = error
        })
    },

    getProdObjectTypeOptions: function () {
      this.prodObjectTypeOptionsLoaded = false
      this.$api.getProdObjectTypeList()
        .then(response => {
          this.prodObjectTypeOptions = response
          if (this.action.update) {
            if (this.data.type) {
              const obj = response.find(o => o.id === this.data.type.id)
              if (obj) { this.typeId = obj.id }
            } else {
              this.typeId = null
            }
          }
          this.prodObjectTypeOptionsLoaded = true
        })
        .catch(error => {
          console.log('***Error in ', this.location, ' : ', error)
          this.errorText = error
        })
    },

    setupForm: function () {
      if (this.layout === 'outdoor') {
        this.objName = 'Area'
        this.types = ['Farm', 'Field', 'Zone']
        this.prodObjectSelectMessage = 'Choose a parent object: a typically a greenhouse or warehouse'
        this.typeSelectMessage = 'The available types for outdoor applications farms, fields, and zones'
      } else {
        this.objName = 'Production Unit'
        this.prodObjectSelectMessage = 'Choose a parent object: a typically a greenhouse or warehouse'
      }
    },

    isEmpty: function (input) {
      return !input || !input.trim()
    },

    createSubmit: function (e) {
      this.form.parent = this.parentId
      // TODO remove hardcoded typeId
      // this.form.type = this.typeId
      this.form.type = this.roomPk
      this.$api.postProdObject(this.form).then(data => {
        this.createdName = data.name
        this.errorText = null
        this.$emit('success')
        this.createSuccessful = true
      }).catch((error) => {
        if (error.non_field_errors) {
          this.errorText = error.non_field_errors[0]
        } else {
          this.errorText = error
        }
      })
    },

    updateSubmit: function (e) {
      this.form.parent = this.parentId
      this.form.type = this.roomPk
      this.$api.patchProdObjectDetail(this.pk, this.form).then(data => {
        this.createdName = data.name
        this.errorText = null
        this.$emit('success')
        this.createSuccessful = true
      }).catch((error) => {
        if (error.non_field_errors) {
          this.errorText = error.non_field_errors[0]
        } else {
          this.errorText = error
        }
      })
    },

    populateFromServer: function () {
      // console.log('***populating from server with pk', this.pk)
      this.$api.getProdObjectDetail(this.pk).then(data => {
        const form = {
          name: data.name,
          tag: data.tag,
          account: data.account,
          parent: data.parent,
          type: data.type
        }
        this.data.parent = data.parent
        this.data.type = data.type
        this.form = form
        this.getProdObjectOptions()
        this.getProdObjectTypeOptions()
      }).catch((error) => {
        this.errorText = error
      })
    }

  }
}
</script>

<style scoped lang="scss">
  @import '@/styles/app.scss';
</style>
