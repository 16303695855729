<template lang="html">
  <div>
    <div class="ndt-debug mt-3" v-if="debugOn">
      {{getParentFileName}} / <strong>{{getFileName}}</strong>
    </div>
    <div class="text-center">
        <template v-if="isInitial || isFailed">
          <div class="text-center mb-4">
           <strong>Upload PDF formatted lab reports</strong>
          </div>
        </template>
        <div v-if="!responseMessage" class="text-left mb-4">
         <div v-if="isSaving" class="text-center">
           <b-spinner style="width: 2rem; height: 2rem; margin-top: 0.5rem; margin-right: 0.5rem" label="Large Spinner">
           </b-spinner>
           <div v-if="fileCount > 1">Processing {{ fileCount}} files</div>
           <div v-else>Processing {{ fileCount}} file</div>
           <div v-if="fileLimitExceeded">The number of files chosen exceeded the maximum of 5.
           Only the first 5 files will be processed</div>
         </div>
         <form enctype="multipart/form-data" novalidate v-if="isInitial || isSuccess || isFailed">
           <div class="this-dropbox">
             <input type="file" multiple :name="uploadFieldName" :disabled="isSaving" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
               accept="image/*,application/pdf" class="this-input-file">
               <p v-if="isInitial">
                 Drag your file(s) here to begin<br> or click to browse.
                 <br/>
                 A maximum of 5 files can be processed at any one time.
               </p>
           </div>
         </form>
         <div v-if="fileData">
           {{fileData}}
         </div>
        </div>

        <div v-else class="this-pdf-response">  <!-- responseMessage -->
          <!-- {{responseMessage}} -->
          <strong>{{responseMessage.totalFiles}} file(s) were processed, {{responseMessage.successCount}} succesfully.</strong><br/>
          <div class="row">
            <div class="col"><strong>FileName</strong></div>
            <div class="col"><strong>Laboratory</strong></div>
            <div class="col"><strong>no. pages</strong></div>
            <div class="col"><strong>Analyses per page</strong></div>
            <div class="col"><strong>Status</strong></div>
          </div>
          <div class="row" v-for="(file, index) in responseMessage.fileInfo" :key="index">
            <div class="col">{{file.name}}</div>
            <div class="col">{{file.lab}}</div>
            <div class="col">{{file.numpages}}</div>
            <div class="col">{{file.analysisCount}}</div>
            <div class="col">{{file.analysisStatus}}</div>
          </div>
          <br/><br/>
          <strong>We have extracted the following analytical data from the submitted files:</strong>
          <b-table
            hover
            sticky-header="50vh"
            responsive
            :items="analyses"
            :fields="fields"
            primary-key="data_pk"
            ref="sampleTable"
            >

            <template #cell(edit)="data">
              <template v-if="!formDisabled">
                <b-icon icon="pencil" shift-v="-8"
                @click="edit(data.item)"></b-icon>
              </template>
            </template>

            <template #head(edit)="data">
              <span style="white-space: nowrap;">
                {{ data.label }}
                <span v-b-tooltip.hover.topright="{ customClass: 'ndt-tooltipx', boundary: 'document' }"
                title="You can edit the concentrations here. Note: This should very rarely be required - if you detect a deviation from the reported
                concentration in the report then we strongly recommend that you send a copy of the document to support@nordetect.com">
                  <b-icon icon="question-circle-fill" font-scale="1.2"></b-icon>
                </span>
              </span>
            </template>

            <template #cell(sample_id)="data">
              <b-form-input id="input1" type="text" name="sample_id" :placeholder="data.item.sample_id" v-model="analysisFormData[data.item.index].sample_id"
                autoFocus autoComplete="off" :disabled="formDisabled" />
            </template>

            <template #head(sample_id)="data">
              <span style="white-space: nowrap;">
                {{ data.label }}
                <span v-b-tooltip.hover.top="{ customClass: 'ndt-tooltipx', boundary: 'document' }"
                title="The sample_id has been extracted from the document based on a best estimate for the location of this variable. The NAP platform requires
                that sample_id must contain only alphanumeric characters, dashes and underscores. Where possible required substitutions have already been made.">
                  <b-icon icon="question-circle-fill" font-scale="1.2"></b-icon>
                </span>
              </span>
            </template>

            <template #cell(tag)="data">
              <b-form-input id="input2" type="text" name="tag" :placeholder="data.item.tag" v-model="analysisFormData[data.item.index].tag"
                autoFocus autoComplete="off" :disabled="formDisabled" />
            </template>

            <template #head(station)="data">
              <span style="white-space: nowrap;">
                {{ data.label }}
                <span v-b-tooltip.hover.top="{ customClass: 'ndt-tooltipx', boundary: 'document' }"
                title="Assign to a station. This field is optional, but assigning samples and analyses to stations allows
                them to be related to other data resources in your project">
                  <b-icon icon="question-circle-fill" font-scale="1.2"></b-icon>
                </span>
              </span>
            </template>

            <template #cell(station)="data">
              <b-form-select
                v-model="analysisFormData[data.item.index].station"
                :options="stations"
                value-field="id"
                text-field="name"
                :disabled="formDisabled">
                <template #first>
                  <b-form-select-option :value="null">-- Select from the list --</b-form-select-option>
                </template>
              </b-form-select>
            </template>

            <template #cell(collected)="data">
              <b-form-input id="input2" placeholder="" type="datetime-local" name="collected" v-model="analysisFormData[data.item.index].collected"
                autoFocus autoComplete="off" :disabled="formDisabled" />
            </template>

            <template #head(collected)="data">
              <span style="white-space: nowrap;">
                {{ data.label }}
                <span v-b-tooltip.hover.topleft="{ customClass: 'ndt-tooltipx', boundary: 'document' }"
                title="This is the date and time that the sample was collected. It is not pre-filled because possible extractable values from the document are usually ambiguous.
                This field is optional but is required if the analysis is to be plotted on time-series charts.">
                  <b-icon icon="question-circle-fill" font-scale="1.2"></b-icon>
                </span>
              </span>
            </template>

            <template #head(unit)="data">
                <span style="white-space: nowrap;">{{ data.label }}
                  <span v-b-tooltip.hover.topleft="{ customClass: 'ndt-tooltipx', boundary: 'document' }"
                  title="The units are either ppm or mmmol/L based on the primary unit reported in the document, We equate mg/L with ppm. Trace elements:
                  Fe, Mn, Zn, B, Cu, Mo, Al
                  are, however, reported as micrommoles/L or ppb. Analytes that were reported as below detection limits are
                  set to 0 in this table">
                    <b-icon icon="question-circle-fill" font-scale="1.2"></b-icon>
                  </span>
                </span>
            </template>

            <template #head(PO4)="data">
              <span style="white-space: nowrap;">
                {{ data.label }}
                <span v-b-tooltip.hover.topleft="{ customClass: 'ndt-tooltipx', boundary: 'document' }"
                title="PO4 is the default analyte for phosphorous in the NAP platform. Where necessary concentrations reported in the document have been converted.">
                  <b-icon icon="question-circle-fill" font-scale="1.2"></b-icon>
                </span>
                <br/>
                {{ data.field.unitName }}
              </span>
            </template>

            <template #head(SO4)="data">
              <span style="white-space: nowrap;">
                {{ data.label }}
                <span v-b-tooltip.hover.topleft="{ customClass: 'ndt-tooltipx', boundary: 'document' }"
                title="SO4 is the default analyte for phosphorous in the NAP platform. Where necessary concentrations reported in the document have been converted.">
                  <b-icon icon="question-circle-fill" font-scale="1.2"></b-icon>
                </span>
                <br/>
                {{ data.field.unitName }}
              </span>
            </template>

            <template #head()="data">
              <span style="white-space: nowrap;">
                {{ data.label }} <br/>
                {{ data.field.unitName }}
              </span>
            </template>

          </b-table>

          <!-- <div>
          analysisFormData: {{analysisFormData}}
          </div> -->

          <div v-if="validationResults">
            <strong>Your file contains the following errors, please fix and press reset to read again</strong><br/>
            <div v-for="result in validationResults" :key="result.sample_id">
              {{result.sample_id}}: {{result.text}}
            </div><br/>
          </div>

          <div v-if="saveButtonVisible">
            <button class="ndt-button-done-small" @click="saveData">Save</button>
            <span class="ml-2">
              <button class="ndt-button-cancel-small" @click.prevent="reset">
                Cancel
              </button>
            </span>
          </div>

          <div v-if="showUploadSpinner">
            <b-spinner style="width: 2rem; height: 2rem; margin-top: 0.5rem; margin-right: 0.5rem" label="Large Spinner"></b-spinner>
            <span>Uploading analyses</span>
          </div>
          <div v-if="uploadResults">
            <strong>The following analyses have been uploaded</strong><br/>
            <div v-for="result in uploadResults" :key="result.sample_id">
              {{result.sample_id}} | {{result.data_id}} : {{result.text}}
            </div><br/>
            <button class="ndt-button-cancel-small" @click="reset()">
              OK
            </button>
          </div>

        </div>  <!-- v-else -->

        <div v-if="uploadError">
          <strong>{{uploadError}}</strong>
        </div>

      <template v-if="!saveButtonVisible">
        <button class="ndt-button-done-small"  @click="$emit('closeMe')">Done</button>
        <span class="ml-2">
          <button class="ndt-button-cancel-small" @click="$emit('closeMe')">
            Cancel
          </button>
        </span>
      </template>
    </div>

    <b-modal
      v-model="editModalVisible"
      size="lg"
      content-class="ndt-card-1"
      centered
      scrollable
      no-close-on-backdrop
      no-close-on-escape
      hide-header-close
      hide-header
      hide-footer
    >
    <!-- {{analyses}}<br/><br/>{{analysisFormData}} -->
    <div class="mt-3 text-center">
    <strong>Analysis Correction</strong>
    </div>
    <div class="mt-2 mb-2">
    Use this form to make any corrections to the analytical values.
    <br/><strong>Note:</strong> It is highly unlikely that you need to use this form and we recommend
    that you consider sending your lab-report to support@nordetect.com so that we can investigate the cause of
    the discrepancy.
    </div>
      <b-form v-on:submit.prevent="updateRow">
        <div v-for="(key) in Object.keys(editForm)" :key="key">
          <div class="d-flex">
            <b-form-group :label="key" :label-for="key" class="ml-1 mr-1">
              <b-form-input :id="key" placeholder="" type="number" step="0.001" :name="key" v-model="editForm[key]"
                  autoComplete="off" />
            </b-form-group>
          </div>
        </div>
        <!-- <br/>{{editForm}} -->
        <button class="ndt-button-done-small" type="submit">Update</button>
        <span class="ml-2">
          <button class="ndt-button-cancel-small" @click.prevent="editModalVisible = false">
            Cancel
          </button>
        </span>
      </b-form>
    </b-modal>

  </div>
</template>

<script>
import GeneralMixin from '@/mixins/GeneralMixin.vue'
// import HelpIconSlot from '@/assets/HelpIconSlot.vue'

const STATUS_INITIAL = 0
const STATUS_SAVING = 1
const STATUS_SUCCESS = 2
const STATUS_FAILED = 3

export default {
  name: 'PdfUpload',

  // Optional chaining (?.) and nullish coalescing (??)

  mixins: [GeneralMixin],

  data () {
    return {
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      returnMessage: null,
      responseMessage: null,
      uploadFieldName: 'photos',
      fileData: null,
      analyses: null,
      fields: null,
      // variables below relate to final upload
      tableFields: null,
      analytes: null,
      analyteNames: null,
      saveButtonVisible: false,
      showUploadSpinner: false,
      uploadResults: null,
      analysisFormData: null,
      validationResults: null,
      editModalVisible: false,
      editForm: [],
      editRowIndex: null,
      formDisabled: false,
      fileLimitExceeded: false,
      unitGlobalName: ''
    }
  },

  components: {
    // HelpIconSlot
  },

  computed: {
    isInitial () {
      return this.currentStatus === STATUS_INITIAL
    },
    isSaving () {
      return this.currentStatus === STATUS_SAVING
    },
    isSuccess () {
      return this.currentStatus === STATUS_SUCCESS
    },
    isFailed () {
      return this.currentStatus === STATUS_FAILED
    },

    thisAccountPK: function () {
      const acc = this.$store.getters['options/activeAccount']
      if (acc) {
        return acc.id
      }
      return null
    },

    stations: function () {
      const data = this.$store.getters['data/stations']
      return data
    }
  },

  methods: {

    edit (obj) {
      const editForm = {}
      const excludeList = [
        'sample_id',
        'station',
        'collected',
        'dilution',
        'index',
        'unit',
        'tag',
        'sample_date',
        'sample_number'
      ]
      Object.keys(obj).forEach((key, index) => {
        if (!excludeList.includes(key)) {
          editForm[key] = obj[key]
        }
      })
      this.editRowIndex = obj.index
      this.editForm = editForm
      this.editModalVisible = true
    },

    updateRow () {
      const rowIndex = this.editRowIndex
      Object.keys(this.editForm).forEach((key, index) => {
        this.analyses[rowIndex][key] = this.editForm[key]
        this.analysisFormData[rowIndex][key] = this.editForm[key]
      })
      this.editModalVisible = false
    },

    reset () {
    // reset form to initial state
      this.currentStatus = STATUS_INITIAL
      this.uploadedFiles = []
      this.uploadError = null
      this.uploadResults = null
      this.formDisabled = false
      this.responseMessage = null
      this.validationResults = null
      this.fileLimitExceeded = false
      this.saveButtonVisible = false
    },

    // This is the main inspiration
    // https://www.digitalocean.com/community/tutorials/how-to-handle-file-uploads-in-vue-2

    // https://www.javascripttutorial.net/web-apis/javascript-filereader/#:~:text=Introduction%20to%20the%20JavaScript%20FileReader%20API&text=And%20JavaScript%20uses%20the%20FileList,drag%20%26%20drop%20or%20file%20input.
    // google: using javascript FileReader

    // Lots of stuff about actually reading the files from input
    // https://web.dev/read-files/
    // google: javascript reading files from a filelist

    save (formData) {
      // upload data to the server
      this.currentStatus = STATUS_SAVING
      // console.log('***In save formData = ', formData, formData.entries())
      /* for (const pair of formData.entries()) {
        console.log(pair[0] + ', ' + pair[1])
      } */
      // the following would be the way to go if we were sending it to the server
      this.$api.postPdfExtract(formData)
        .then(resp => {
          this.uploadedFiles = [].concat(resp)
          this.currentStatus = STATUS_SUCCESS
          const respArray = JSON.parse(resp)
          this.setupTable(respArray)
          this.createResponseMessage(respArray)
        })
        .catch(err => {
          this.uploadError = err.response
          this.currentStatus = STATUS_FAILED
          console.log('***Upload error', this.uploadError, err)
        })
    },

    filesChange (fieldName, fileList) {
      // console.log('***filesChange ', fieldName, fileList)
      // handle file changes

      /* This whole section deals with processing files locally
      const fr = new FileReader()
      fileList.forEach((item) => {
        // fr.readAsText(item)
        fr.readAsArrayBuffer(item)
        // fr.readAsDataURL(item)
      })
      // we handle the load event of the FileReader
      let fileData = null
      fr.addEventListener('load', (e) => {
        fileData = e.target.result
        // do something with the fileData
        console.log('***File data =', fileData)
      })
      this.fileData = fileData
      */

      const formData = new FormData()

      if (!fileList.length) return

      let processLimit = fileList.length
      if (processLimit > 5) {
        this.fileLimitExceeded = true
        processLimit = 5
      }

      // append the files to FormData
      Array
        .from(Array(processLimit).keys())
        .map(x => {
          formData.append(fieldName, fileList[x], fileList[x].name)
        })
        // save it
      this.save(formData)
    },

    createResponseMessage (data) {
      const message = {
        totalFiles: data.length,
        successCount: null,
        failureCount: null,
        fileInfo: []
      }

      let success = 0
      let fail = 0

      data.forEach((item) => {
        message.fileInfo.push({
          name: item.name,
          numpages: item.numpages,
          lab: item.report_type,
          analysisCount: 1,
          analysisStatus: item.message
        })
        if (item.statusOk) {
          success++
        } else {
          fail++
        }
      })

      message.successCount = success
      message.failureCount = fail

      this.responseMessage = message
    },

    setupTable (data) {
      console.log('***XXX data and analysis', data)
      const analyses = []
      const analysisFormData = []
      let index = 0
      data.forEach((item) => {
        if (item.statusOk) {
          item.analyses.forEach((result) => {
            const analysis = {
              index: index,
              // sample_id: item.analysis['Sample Date'],
              // tag: item.analysis['Sample Date']
              sample_id: result.sample_id,
              station: null,
              collected: '',
              dilution: 1,
              tag: 'Insert a comment',
              unit: result.unit
            }
            const analysisFormItem = {
              sample_id: result.sample_id,
              tag: '',
              station: null,
              collected: null,
              dilution: 1,
              analyzed: null,
              unit: result.unit
            }
            this.unitGlobalName = result.unit
            const micros = ['Fe', 'Mn', 'Zn', 'B', 'Cu', 'Mo', 'Al']
            result.results.forEach((analyte) => {
              const isMicro = (micros.indexOf(analyte.analyte) > -1)
              let multiplyer = 1
              if (isMicro) { multiplyer = 1000 }
              analysis[analyte.analyte] = (analyte.value * multiplyer).toFixed(2)
              analysisFormItem[analyte.analyte] = (analyte.value * 1).toFixed(5)
              // console.log('***SETTING ANALYTE VALUE analyte, analyte.analyte, analyte.value', analyte, analyte.analyte, analyte.value)
              // console.log('***RESULTS analysis, analysisFormItem ', analysis[analyte.analyte], analysisFormItem[analyte.analyte])
            })
            analyses.push(analysis)
            analysisFormData.push(analysisFormItem)
            index++
          })
        }
      })

      const fixedFields = [
        {
          key: 'edit',
          sortable: false,
          label: 'Edit concentrations'
          // tdClass: 'ndt-tdclass-10'
        },
        {
          key: 'sample_id',
          sortable: false,
          label: 'Sample_id',
          tdClass: 'ndt-tdclass-10'
        },
        {
          key: 'station',
          sortable: false,
          label: 'Station',
          tdClass: 'ndt-tdclass-10'
        },
        {
          key: 'collected',
          sortable: false,
          label: 'Collected',
          tdClass: 'ndt-tdclass-10'
        },
        {
          key: 'tag',
          sortable: false,
          label: 'Comment',
          tdClass: 'ndt-tdclass-10'
        },
        {
          key: 'unit',
          sortable: false,
          label: 'Unit'
        }
      ]

      const variableFields = []
      const analyteKeys = ['EC', 'pH', 'NH4', 'K', 'Na', 'Ca', 'Mg', 'Si', 'NO3', 'Cl', 'SO4', 'HCO3', 'PO4', 'Fe', 'Mn', 'Zn', 'B', 'Cu', 'Mo', 'Al']
      const micros = ['Fe', 'Mn', 'Zn', 'B', 'Cu', 'Mo', 'Al']
      for (const key of analyteKeys) {
        let unitName = this.unitGlobalName
        const isMicro = (micros.indexOf(key) > -1)
        if (isMicro) {
          if (unitName === 'ppm') { unitName = 'ppb' }
          if (unitName === 'mmol/L') { unitName = 'micromole/L' }
          if (unitName === 'ppm as element') { unitName = 'ppb as element' }
        }
        if (key === 'EC') { unitName = 'ms/S' }
        if (key === 'pH') { unitName = '' }
        const field = {
          key: key,
          sortable: false,
          label: key,
          unitName: unitName
        }
        variableFields.push(field)
      }

      this.fields = [...fixedFields, ...variableFields]
      this.analyses = analyses
      this.analysisFormData = analysisFormData
      this.saveButtonVisible = true
    },

    /* all the methods below are common with ImportAnalyses.vue
    getAnalytes is called in created
    it populates this.analytes and this.tableFields

    saveData is called from a user activated button, it loops through
    the table of analysis data and validates

    saveData calls processAnalysis which controlsupload of single samples/analyses

    processAnalysis calls getSampleBySampleId, postSample, and postAnalysis. These all
    call functins on this.$api
    */

    getAnalytes: function () {
      const analytes = this.$store.getters['data/analytes']
      this.analytes = analytes
      const analyteNames = analytes.map(a => a.name)
      const fixedFields = ['sample_id', 'collected', 'analyzed', 'tag', 'dilution', 'unit']
      // this.fields = fixedFields.push(...analyteNames)
      this.tableFields = fixedFields.concat(analyteNames)
      this.analyteNames = analyteNames
    },

    saveData: async function () {
      this.saveButtonVisible = true
      if (this.analysisFormData) {
        const validationStatus = []
        let validateOK = true
        for (const analysis of this.analysisFormData) {
          let validateText = null
          // sample_id character check
          const sampleIdOK = !/[^a-z0-9-_]/i.test(analysis.sample_id)
          if (!(sampleIdOK && analysis.sample_id.length > 0)) {
            validateOK = false
            validateText = {
              sample_id: analysis.sample_id,
              text: 'Sample_id must contain only alphanumeric characters, dashes and hyphens, no spaces'
            }
          }
          if (validateOK && analysis.unit !== 'ppm') {
            if (analysis.unit === 'mmol/L') {
              this.analytes.forEach((analyte) => {
                // console.log('***analysis name', analyte.name, analysis[analyte.name])
                if ((analyte.name in analysis) && analysis[analyte.name]) {
                  analysis[analyte.name] = (analysis[analyte.name] * analyte.molecular_weight).toFixed(3)
                }
              })
            } else if (analysis.unit === 'ppm_as_element') {
              this.analytes.forEach((analyte) => {
                if ((analyte.name in analysis) && analysis[analyte.name]) {
                  analysis[analyte.name] = (analysis[analyte.name] / analyte.second_conv).toFixed(3)
                }
              })
            } else {
              validateOK = false
              validateText = {
                sample_id: analysis.sample_id,
                text: 'Invalid unit: allowed values are ppm, mmol/L and ppm_as_element'
              }
            }
          }
          if (validateText) {
            validationStatus.push(validateText)
          }
          // console.log('***validation status:', validationStatus)
        }

        if (validateOK) {
          this.saveButtonVisible = false
          this.validationResults = null
          this.showUploadSpinner = true
          const status = []
          for (const analysis of this.analysisFormData) {
            const statusText = await this.processAnalysis(analysis)
            status.push(statusText)
          }
          this.resetButtonVisible = true
          this.uploadResults = status
          this.showUploadSpinner = false
          this.formDisabled = true
          // console.log('***PDF UPLOAD CALLING getAnalysesCompact')
          const payload = { unit: this.unit, pag: 0, noStaff: false }
          this.$store.dispatch('data/getAnalysesCompact', payload)
        } else {
          this.validationResults = validationStatus
          // console.log('***FINAL validation status:', this.validationResults, validationStatus)
          this.saveButtonVisible = true
        }
      }
      // this.saveButtonVisible = false
    },

    processAnalysis: async function (analysis) {
      // console.log('***Starting loop for:', analysis.sample_id)
      // console.log('***Analysis Data :', analysis)
      let statusText = {}
      let samplePk = null
      const sampleGetResult = await this.getSampleBySampleId(analysis.sample_id)
      if (sampleGetResult.exists === 'YES') {
        samplePk = sampleGetResult.id
        // console.log('***Found Sample for:', analysis.sample_id, samplePk)
        // if sampleGetResults fails we are carrying on. Who knows, the post might still work!
      } else {
        // console.log('***Creating Sample for:', analysis.sample_id)
        // console.log('***Post Data :', analysis)
        const samplePostResult = await this.postSample(analysis)
        if (samplePostResult.status === 'OK') {
          samplePk = samplePostResult.id
        } else {
          statusText = {
            sample_id: analysis.sample_id,
            text: 'error saving sample'
          }
        }
      }
      if (samplePk) {
        analysis.sample_pk = samplePk
        const analysisPostResult = await this.postAnalysis(analysis)
        if (analysisPostResult.status === 'OK') {
          statusText = {
            sample_id: analysis.sample_id,
            data_id: analysisPostResult.data_id,
            text: 'successfully saved'
          }
        } else {
          statusText = {
            sample_id: analysis.sample_id,
            text: 'error saving analysis' + analysisPostResult.reason,
            reason: analysisPostResult.reason
          }
        }
      }
      return statusText

      /* statusText = {
        sample_id: analysis.sample_id,
        data_id: 'test_data_id',
        text: 'successfully saved'
      }

      return statusText */
    },

    getSampleBySampleId: function (sampleId) {
      return new Promise((resolve, reject) => {
        let status = {}
        this.$api.getSampleById(sampleId)
          .then(response => {
            // console.log('***getSampleBySampleId response', response)
            if (response.length === 1) {
              // console.log('***found id', response[0].id)
              status = { exists: 'YES', id: response[0].id }
            } else if (response.length > 1) {
              status = { exists: 'NO', id: null, reason: 'Multiple samples found' }
            } else if (response.length < 1) {
              status = { exists: 'NO', id: null, reason: 'No sample found' }
            }
            resolve(status)
          }).catch((error) => {
            console.log('***Error in ImportAnalyses postSample: ', error)
            status = { exists: 'NO', id: null, reason: error }
            reject(status)
          })
      })
    },

    postSample: function (analysis) {
      return new Promise((resolve, reject) => {
        const postData = {
          sample_id: analysis.sample_id,
          account: this.thisAccountPK,
          location: analysis.station,
          collected: analysis.collected,
          tag: analysis.tag
        }
        let status = {}
        this.$api.postSample(postData)
          .then(response => {
            status = { status: 'OK', id: response.id }
            resolve(status)
          }).catch((error) => {
            let text = null
            if (error.non_field_errors) {
              text = error.non_field_errors[0]
            } else {
              text = error
            }
            console.log('***Error in ImportAnalyses postSample: ', text)
            status = { status: 'ERROR', id: null }
            // Note I am not using reject here because otherwise I have to catch the error in the calling function
            resolve(status)
          })
      })
    },

    postAnalysis: function (analysis) {
      return new Promise((resolve, reject) => {
        const postData = {
          sample: analysis.sample_pk,
          dilution: analysis.dilution,
          analyzed: analysis.analyzed,
          device: this.webAppPk,
          tag: analysis.tag
        }
        // console.log('***ANALYSIS =', analysis, postData)
        const analytePostData = []
        this.analytes.forEach((analyte) => {
          if (analyte.name in analysis) {
            const value = analysis[analyte.name]
            // console.log('***POST ANALYSIS analyte, analysis, value', analyte, analysis, value)
            // Important: we are only adding the analyte if it has a value
            // In addition this needs to be validated in Django at the data level
            console.log('****VALUE FIXED', value, Number(value).toFixed(3))
            const correctedValue = Number(value).toFixed(3)
            if (value) {
              analytePostData.push({ analyte: analyte.id, value: correctedValue })
            }
          }
        })
        let status = {}
        postData.analysis_analyte = analytePostData
        console.log('***SENDING ANALYSIS POSTDATA', postData)
        this.$api.postAnalysis(postData)
          .then(response => {
            status = { status: 'OK', data_id: response.data_id }
            resolve(status)
          }).catch((error) => {
            let text = null
            if (error.non_field_errors) {
              text = error.non_field_errors[0]
            } else {
              text = error
            }
            console.log('***Error in ImportAnalyses postAnalysis: ', text)
            status = { status: 'ERROR', data_id: null, reason: text }
            reject(status)
          })
      })
    }
  },

  created () {
    this.getAnalytes()
  },

  mounted () {
    this.logEvent('PdfUpload view mounted')
    this.reset()
  }

}

</script>

<style scoped lang="scss">
@import '@/styles/app.scss';

.this-pdf-response {
  font-size: 12px;
}

.this-form-container {
  margin-left: 37px;
  margin-right: 118px;
  margin-top: 0rem;
  background-color: $ndt-color-1;
  border: none;
}

.this-dropbox {
  margin: auto;
  box-sizing: border-box;
  width: 581px;
  height: 191px;
  background: rgba(100, 240, 140, 0.1);
  border: 4px dashed #D2EDDA;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
 }

 .this-input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
 }

 .this-dropbox:hover {
   background: lightblue; /* when mouse over to the drop zone, change color */
 }

 .this-dropbox p {
   font-size: 1.2em;
   text-align: center;
   padding: 50px 0;
 }
</style>
