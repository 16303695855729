<template>
  <div class="modal-overlay" @click.self="closeModal">
    <div class="modal-content">
      <button class="close-button" @click="closeModal">×</button>

      <h1 class="modal-heading">{{ rowData.sample_id }}</h1>

      <div class="field-pairs">
        <div class="field-pair">
          <div class="field"><strong>Collected:</strong><br>{{ formattedCollected }}</div>
          <div class="field"><strong>Dilution:</strong><br>{{ rowData.dilution }}</div>
        </div>
        <div class="field-pair">
          <div class="field"><strong>Station Name:</strong><br>{{ rowData.station_name }}</div>
          <div class="field"><strong>Device Name:</strong><br>{{ rowData.device_name }}</div>
        </div>
        <div class="field-pair">
          <div class="field"><strong>Comment:</strong><br>{{ rowData.comment || 'N/A' }}</div>
        </div>
      </div>

      <!-- <hr> -->

      <div class="table-container" v-if="Object.keys(nutrients).length">
        <table>
          <thead>
            <tr>
              <th>Nutrient</th>
              <th>Value ({{ unitName }})</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, key) in nutrients" :key="key">
              <td>{{ key }}</td>
              <td>{{ value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <p>No nutrients available for this sample.</p>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import GeneralMixin from '@/mixins/GeneralMixin.vue'

export default {
  name: 'RowDetailModal',
  mixins: [GeneralMixin],
  props: {
    rowData: {
      type: Object,
      required: true
    }
  },
  computed: {
    formattedCollected() {
      const date = new Date(this.rowData.collected)
      return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
    },
    unitName() {
      return this.unit === 'second' ? 'ppm as analyte' : this.unit
    },
    nutrients() {
      let nutrientKeys = []

      // Determine which keys to use based on the unit
      if (this.unit === 'ppm' || this.unit === 'mmol') {
        nutrientKeys = [
          'Electroconductivity', 'Nitrate', 'Ammonium', 'Nitrite', 'Phosphate', 'Potassium',
          'Calcium', 'Magnesium', 'Sulphate', 'Sodium', 'Chlorine', 'Bicarbonate', 'Iron',
          'Copper', 'Zinc', 'Manganese', 'Molybdenum', 'Boron', 'Aluminium', 'Silicon'
        ]
      } else if (this.unit === 'second') {
        nutrientKeys = [
          'Al', 'Mo', 'Cu', 'B', 'Zn', 'Mn', 'Fe', 'Ca', 'Mg', 'PO4-P',
          'K', 'NO3-N', 'Na', 'SO4-S'
        ]
      }

      const nutrients = {}
      nutrientKeys.forEach(key => {
        if (this.rowData[key] !== undefined) {
          nutrients[key] = this.rowData[key]
        }
      })

      return nutrients
    }
  },
  methods: {
    closeModal() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  /* Subtract 72px (footer height) + some additional margin (10px) */
  max-height: calc(90vh - 72px - 10px);
  overflow-y: auto;
  position: relative;
  margin-bottom: 10px; /* Space between modal and the footer */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-family: "Helvetica Neue";
  padding-bottom: 92px; /* Reserve space for the footer */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.modal-heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.field-pairs {
  margin-bottom: 20px;
}

.field-pair {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  gap: 20px;
  /* Space between fields */
}

.field {
  flex: 1;
  /* Adjust based on your needs */
}

.table-container {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px 12px;
  border: 1px solid #ddd;
}

th {
  background-color: #f4f4f4;
}

tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}
</style>
