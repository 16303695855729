<template>
  <div @click="$emit('clicked', true)">
    <template>
      <div class="this-button-large d-flex">
        <div style="margin-left: 10px"><slot></slot></div>
        <div style="margin-left: 10px; margin-top: -3px">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1039_99)">
            <g filter="url(#filter0_d_1039_99)">
            <path d="M22.5 16.5H16.2188L14.1094 18.6562C13.5469 19.2188 12.7969 19.5 12 19.5C11.1562 19.5 10.4062 19.2188 9.84375 18.6562L7.73438 16.5H1.5C0.65625 16.5 0 17.2031 0 18V22.5C0 23.3438 0.65625 24 1.5 24H22.5C23.2969 24 24 23.3438 24 22.5V18C24 17.2031 23.2969 16.5 22.5 16.5ZM20.25 21.375C19.5938 21.375 19.125 20.9062 19.125 20.25C19.125 19.6406 19.5938 19.125 20.25 19.125C20.8594 19.125 21.375 19.6406 21.375 20.25C21.375 20.9062 20.8594 21.375 20.25 21.375ZM10.9219 17.5781C11.2031 17.8594 11.5781 18 12 18C12.375 18 12.75 17.8594 13.0312 17.5781L19.0312 11.5781C19.6406 11.0156 19.6406 10.0312 19.0312 9.46875C18.4688 8.85938 17.4844 8.85938 16.9219 9.46875L13.5 12.8906V1.5C13.5 0.703125 12.7969 0 12 0C11.1562 0 10.5 0.703125 10.5 1.5V12.8906L7.03125 9.46875C6.46875 8.85938 5.48438 8.85938 4.92188 9.46875C4.3125 10.0312 4.3125 11.0156 4.92188 11.5781L10.9219 17.5781Z" fill="white"/>
            </g>
            </g>
            <defs>
            <filter id="filter0_d_1039_99" x="-4" y="0" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="4"/>
            <feGaussianBlur stdDeviation="2"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1039_99"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1039_99" result="shape"/>
            </filter>
            <clipPath id="clip0_1039_99">
            <rect width="24" height="24" fill="white"/>
            </clipPath>
            </defs>
            </svg>
        </div>
      </div>
    </template>
    <!-- <template v-else>
      <div class="this-button-large d-flex">
        <div><slot></slot></div>
        <div><CloudIcon/></div>
      </div>
    </template> -->
  </div>
</template>

<script>
import GeneralMixin from '@/mixins/GeneralMixin.vue'
// import CloudIcon from '@/assets/CloudIcon.vue'

export default {
  name: 'CloudIcon',

  components: {
    // CloudIcon
  },

  mixins: [GeneralMixin],

  props: {
    type: {
      type: String,
      default: null
    },
    colour: {
      type: String,
      default: null
    },
    fill: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    darkMode: {
      type: Boolean,
      default: false
    },
    iconPosition: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: true
    }
  }
}

</script>

<style lang="scss" scoped>
@import '@/styles/app.scss';

.this-button-large {
  /* Style=Primary, Small=False, Dark mode=False, Icon position=Leading */
  cursor: pointer;
  padding-top: 10px;
  width: 203px;
  height: 40px;
  /* Lighter green */
  background: #0D3C3C;
  /* Lighter green */
  border: 1px solid #0D3C3C;
  border-radius: 72px;
  /* text */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $ndt-color-1;
}

.this-button-small {
  /* Style=Primary, Small=True, Dark mode=False, Icon position=Trailing */
  width: 109px;
  height: 32px;
  background: #0D3C3C;
  /* Lighter green */
  border: 1px solid #0D3C3C;
  border-radius: 144px;
  /* Button */
  width: 49px;
  height: 23px;
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 150%;
  /* White */
  color: #FFFFFF;
}
</style>
