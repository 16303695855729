<template>
  <div id="mainTableDiv" class="tablePadding">
    <div>
      <button type="button" class="mr-3 this-button-done-small" :class="{ 'disabled-button': selectedRows.length === 0 }" v-on:click="downloadSelectedRows()"
        :disabled="selectedRows.length==0">Download</button>
      <b-form-select :disabled="selectedRows.length==0" class="this-form-select" id="assignStationInput"
        v-model="selectedStation" @change="handleAssignStationInput">
        <option :value="null">Assign Station [OPTIONAL]</option>
        <option v-for="station in stations" :value="station" :key="station.id">
          {{ station.name }}
        </option>
      </b-form-select>
      <button type="button" class="ml-3 this-button-done-small" :class="{ 'disabled-button': selectedRows.length === 0 }" v-on:click="handleSaveAssignedStation()"
        :disabled="selectedRows.length == 0 && saveButtonVisible">Upload</button>
      <br>
      <button type="button" class="this-button-done-small" :class="{ 'disabled-button': selectedRows.length === 0 }" v-on:click="handleDeleteRows()"
        :disabled="selectedRows.length==0">Delete</button>
      <br> <br>
      <div v-if="showUploadSpinner">
        <b-spinner style="width: 2rem; height: 2rem; margin-top: 0.5rem; margin-right: 0.5rem" label="Large Spinner">
        </b-spinner>
      </div>
      <div v-if="validationResults" style="
      color: red;
  ">
        <strong>Please change Sample ID according to instructions.</strong><br/>
        Sample ID must only contain letters, numbers, dashes, no spaces and cannot be 'SAMPLE_ID'.

        <!-- <div v-for="result in validationResults" :key="result.sample_id">
          {{ result.sample_id }}: {{ result.text }}
        </div><br /> -->
      </div>
      <div v-if="uploadResults">
        <strong>The results have successfully been uploaded</strong><br/>
        <!-- <div v-for="result in uploadResults" :key="result.sample_id">
          {{ result.sample_id }} | {{ result.data_id }} : {{ result.text }}
        </div> -->
        <br />
        <!-- <button class="ndt-button-cancel-small" @click="reset()">
              OK
            </button> -->
      </div>
      <!-- :style="{ ...styleComputed }" -->
      <br>

      <div>
        <h3>Unsaved Results</h3>
        <br><br><br>
        <div class="uploadInstruction"><strong>Select result(s) from the table below to upload.</strong><br>
          <div class="uploadInstruction" style="font-size:14px;">
            Change Sample ID and dilution by double clicking the respective text fields.
          </div>
        </div>
      </div> <br>
      <ag-grid-vue class="ag-theme-alpine" :columnDefs="columnDefs" :rowSelection="rowSelection" stopEditingWhenGridLosesFocus="true"
        :rowMultiSelectWithClick="rowMultiSelectWithClick" :suppressRowClickSelection="suppressRowClickSelection"
        :animateRows=true @selection-changed="onSelectionChanged" :rowData="rowData" @grid-ready="onGridReady"
        @cell-value-changed="onCellValueChanged" @row-value-changed="onRowValueChanged"
        :overlayLoadingTemplate="overlayLoadingTemplate" :overlayNoRowsTemplate="overlayNoRowsTemplate"
        @first-data-rendered="onFirstDataRendered" :domLayout='domLayout' :suppressColumnVirtualisation="true"
        :pagination="true" ::getRowId="getRowId">
      </ag-grid-vue>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import GeneralMixin from '@/mixins/GeneralMixin.vue'
import { eventsBus } from '@/main'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { AgGridVue } from 'ag-grid-vue'

export default {

  name: 'GameBoyResultsTableHolder',

  components: {
    AgGridVue
  },

  mixins: [GeneralMixin],

  props: {
    gameBoyResults: {
    },
    deviceID: {
    }
  },

  data() {
    return {
      displayTable: false,
      columnDefs: undefined,
      rowData: undefined,
      allTableData: null,
      uniqueColumnVariables: null,
      rowSelection: null,
      gridApi: null,
      rowMultiSelectWithClick: true,
      suppressRowClickSelection: true,
      uniqueStations: [],
      stationCellColors: {},
      gridColumnApi: null,
      overlayLoadingTemplate: null,
      overlayNoRowsTemplate: null,
      selectedRows: [],
      selectedStation: null,
      windowHeight: null,
      analytesWithUnits: {},
      currentAnalytes: null,
      domLayout: 'autoHeight',
      saveButtonVisible: true,
      showUploadSpinner: false,
      validationResults: null,
      analytes: null,
      uploadResults: null,
      getRowId: (params) => params.data.sample_id
    }
  },

  computed: {
    mediaType: function () { return this.$store.getters['options/mediaType'] },
    styleComputed: function () {
      const height = (this.windowHeight - 150).toString() + 'px'
      // eslint-disable-next-line
      return { 'height': height }
    },
    stations: function () {
      return this.$store.getters['data/stations']
    },
    thisAccountPK: function () {
      const acc = this.$store.getters['options/activeAccount']
      if (acc) {
        return acc.id
      }
      return null
    }
  },

  watch: {
    gameBoyResults: {
      handler: function (oldValue, newValue) {
        this.setupTable()
        // if (oldValue.length > 0 || newValue.length > 0) {
        //   this.getUniqueStations()
        //   this.setupTable()
        // }
        // if (oldValue.length === 0 || newValue.length === 0) {
        //   this.gridApi.hideOverlay()
        //   this.gridApi.showNoRowsOverlay()
        // }

      },
      deep: true
    },
    unit: {
      handler: function (oldValue, newValue) {
        this.setupTable()
        // if (oldValue.length > 0 || newValue.length > 0) {
        //   this.getUniqueStations()
        //   this.setupTable()
        // }
        // if (oldValue.length === 0 || newValue.length === 0) {
        //   this.gridApi.hideOverlay()
        //   this.gridApi.showNoRowsOverlay()
        // }

      },
      deep: true
    }
  },

  created() {
    this.logDebug(`mounted ${this.$options.name}`)
    this.getAnalytes()
    eventsBus.$on('accountChanged', (data) => {
      if (this.gridApi) {
        this.gridApi.showLoadingOverlay()
      }
    })
    this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Please wait while your data is loading</span>'
    this.overlayNoRowsTemplate = '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;">There is no data to show.</span>'
    // this.getRowId =
  },

  beforeMount() {
    this.setupTable()
  },

  mounted() {
    // instead of console.log we use
    this.logDebug(`mounted ${this.$options.name}`)
    // The following is typically used in views. It logs a message to the Console database ClientEvent table
    this.logEvent('Crops tab mounted')
    this.windowHeight = window.innerHeight
    window.addEventListener('resize', () => { this.windowHeight = window.innerHeight })
  },

  destroyed() {
    this.logDebug(`mounted ${this.$options.name}`)
  },

  methods: {
    submit: function (params) {
      this.logDebug(`submit called with params ${params}`)
    },

    onFirstDataRendered() {
      const allColumnIds = []
      this.gridColumnApi.getColumns().forEach((column) => {
        allColumnIds.push(column.getId())
      })
      const skipHeader = false
      this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader)
    },
    handleAssignStationInput() {
      this.selectedRows.forEach((row) => {
        row.station_name = this.selectedStation?.name || null
        row.station_pk = this.selectedStation?.id || null
      })
      this.gridApi.applyTransaction({ update: this.selectedRows })
      this.gridApi.redrawRows()
      // this.notifyUserAndDeselectRows()
    },
    handleSaveAssignedStation() {
      const excludedFields = ['analyte_unit', 'station_name']
      // Create a new list to store the merged data
      // console.log('executing selectedRows[0]')

      // console.log('selectedRows[0]', this.gameBoyResults)
      const selectedRowsBase = this.selectedRows.map(selectedRow => {
          // Find the corresponding row in gameBoyResults based on the collected field
          const correspondingRow = this.gameBoyResults.find(gameboyRow => gameboyRow.collected === selectedRow.collected);
          // If a corresponding row is found, merge the data
          if (correspondingRow) {
              // Create a new object with the merged data
              return {
                  ...selectedRow,
                  Ca: correspondingRow.Ca,
                  Mg: correspondingRow.Mg,
                  NO3: correspondingRow.NO3,
                  PO4: correspondingRow.PO4,
                  K: correspondingRow.K,
                  SO4: correspondingRow.SO4,
                  unit: 'ppm'
              };
          }
          // If no corresponding row is found, return the original selectedRow
          return selectedRow;
      });  
    const selectedRowsDeepCopy = JSON.parse(JSON.stringify(selectedRowsBase))
      selectedRowsDeepCopy.forEach((row) => {
        row.collected = this.uploadDateFormatter(row.collected)
        row.analyzed = row.collected
        if ('station_pk' in row) {
          row.station = row.station_pk
          delete row.station_pk
        }
        excludedFields.forEach((field) => {
          delete row[field]
        })
      })
      // console.log('handleSaveAssignedStation', selectedRowsDeepCopy)
      this.saveData(selectedRowsDeepCopy)
      // // this.$emit('assignStation', this.selectedRows)
      this.notifyUserAndDeselectRows()
    },
    notifyUserAndDeselectRows() {
      // notify here
      // this.gridApi.undoCellEditing()
      this.gridApi.deselectAll()
    },
    handleDeleteRows() {
      // this.selectedRows.forEach((row) => {
      //   this.$api.deleteSampleDetail(row.sample_pk).then((response) => { })
      // })
      this.gridApi.applyTransaction({ remove: this.selectedRows })
      this.gridApi.redrawRows()
      this.$emit('removeSamplesFromGameboy', this.selectedRows)
    },
    removeUploadedRows() {
      // this.selectedRows.forEach((row) => {
      //   this.$api.deleteSampleDetail(row.sample_pk).then((response) => { })
      // })
      const rowsToRemove = []
      console.log('this.uploadResults', this.uploadResults)
      this.uploadResults.forEach((result) => {
        // rowsToRemove.push({ sample_id: result.sample_id })
        rowsToRemove.push(this.rowData.filter((row) => row.sample_id === result.sample_id)[0])
      })
      console.log('rowsToRemove', rowsToRemove)
      this.gridApi.applyTransaction({ remove: rowsToRemove })
      this.gridApi.redrawRows()
      this.$emit('removeSamplesFromGameboy', rowsToRemove)
      // this.$emit('updateGameboyResults', this.rowData)
    },
    setupTable() {
      this.formatDataforTable()
      this.getUniqueColumnVariables()
      const allColumns = []
      this.getUniqueStations()
      this.calculateCellColorsForStations()
      const hiddenColumns = ['station_pk', 'sample_pk', 'device', 'data_id', 'analyte_unit', 'analysis_pk', 'analyzed', 'station', 'tag']
      this.uniqueColumnVariables = this.orderColumns()
      this.uniqueColumnVariables.forEach((variable) => {
        let commonColumnConfig = { resizable: true, sortable: true, filter: true, field: variable }
        if (variable === 'collected') {
          commonColumnConfig.valueFormatter = this.dateFormatter
          commonColumnConfig.sort = 'desc'
          allColumns.push(commonColumnConfig)
        } else if (variable === 'sample_id') {
          commonColumnConfig.headerName = 'Sample ID'
          commonColumnConfig.editable = true
          commonColumnConfig.checkboxSelection = true
          commonColumnConfig.headerCheckboxSelection = true
          if (this.sidebarVisible) {
            commonColumnConfig.pinned = 'left'
          }
          allColumns.push(commonColumnConfig)
        } else if (variable === 'station_name') {
          allColumns.push({
            headerName : 'Station Name',
            // cellStyle: params => {
            //   return { backgroundColor: params.value ? this.stationCellColors[params.value] : 'none', 'border-radius': '25px' }
            // },
            // editable: true,
            resizable: true,
            field: variable,
            sortable: true,
            filter: true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
              values: this.uniqueStations
            }
          })
        } else if (variable === 'device_name') {
          commonColumnConfig.headerName = 'Device-Name'
          allColumns.push(commonColumnConfig)
        } else if (variable in this.currentAnalytes) {
          const analyteUnit = 'ppm'
          commonColumnConfig.headerName = variable + ' (' + analyteUnit + ')'
          allColumns.push(commonColumnConfig)
        } else {
          if (variable === 'dilution') {
            commonColumnConfig = {
              editable: true,
              resizable: true,
              field: variable,
              sortable: true,
              filter: true,
              cellEditor: 'agNumberCellEditor',
              cellEditorParams: {
                min: 1,
                max: 2000,
                precision: 0
              }
            }
            // commonColumnConfig.cellEditor = 'agNumberCellEditor'
            // commonColumnConfig.editable = true
            // commonColumnConfig.cellEditorParams = {
            //   min: 1,
            //   max: 100,
            //   precision: 0
            // }
          } else if (hiddenColumns.includes(variable)) {
            commonColumnConfig.hide = true
          }
          allColumns.push(commonColumnConfig)
        }
      })
      this.columnDefs = allColumns
      this.rowData = [...new Set(this.allTableData)]

      // if (this.samples.length > 0) {
      this.rowSelection = 'multiple'
      // this.displayTable = true
      this.rowMultiSelectWithClick = true
      // }
    },
    orderColumns() {
      // const fixedColumnOrder = ['collected', 'dilution', 'station_name', 'device_name', 'comment', 'pH',
      //   'Electroconductivity', 'Nitrate', 'Ammonium', 'Nitrite', 'Phosphate', 'Potassium', 'Calcium',
      //   'Magnesium', 'Sulphate', 'Sodium', 'Chlorine', 'Bicarbonate', 'Iron',
      //   'Copper', 'Zinc', 'Manganese', 'Molybdenum', 'Boron', 'Aluminium', 'Silicon']
      const fixedColumnOrder = ['collected', 'dilution', 'station_name', 'Ca', 'Mg', 'NO3', 'PO4', 'K', 'SO4', 'tag']
      this.uniqueColumnVariables = Array.from(this.uniqueColumnVariables).sort((a, b) => fixedColumnOrder.indexOf(a) - fixedColumnOrder.indexOf(b))
      return this.uniqueColumnVariables
    },
    // deviceValueFormatter (params) {
    //   if (params.value === 3) {
    //     return 'Lab Report'
    //   }
    //   return 'Analyzer'
    // },
    uploadDateFormatter(timestampInSeconds) {
      const timestampInMilliseconds = timestampInSeconds * 1000 // Convert to milliseconds

      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: 'UTC' // Adjust to your desired time zone
      }

      const formattedDate = new Date(timestampInMilliseconds).toLocaleString('en-CA', options)

      // For upload format '2023-08-09T17:54'
      return formattedDate.replace(', ', 'T')
    },
    dateFormatter(params) {
      return new Date(params.value * 1000).toLocaleString('en-Ca', { hour12: false, timeZone: 'UTC' })
    },
    getUniqueStations() {
      const uniqueStations = this.stationsGlobal.map(item => item.name)
      // const unique = [...new Set(this.allTableData.map(item => item.station_name))]
      this.uniqueStations = uniqueStations
    },
    formatDataforTable() {
      const allTableData = []
      // [{ collected: 1691565207.75588, calcium: 22, magnesium: 23, nitrate: 44, phosphate: 33, pottasium: 10, sulphate: 45 }]
      this.gameBoyResults.forEach((sample) => { 
        const currentRow = {}
        // console.log('changed date', new Date(sample.collected * 1000).toLocaleString())
        currentRow.collected = sample.collected
        currentRow.analyzed = sample.collected
        // currentRow.sample_pk = ''
        // currentRow.analysis_pk = ''
        currentRow.station_name = null
        currentRow.sample_id = 'SAMPLE_ID'
        currentRow.tag = ''
        currentRow.dilution = sample.dilution || 1
        currentRow.analyzed = null
        currentRow.unit = this.unit
        currentRow.station = null
        // currentRow.device = ''
        // currentRow.device_name = ''
        const analytes = Object.keys(sample).filter((analyte) => analyte !== 'collected' && analyte !== 'dilution')
        this.currentAnalytes = analytes
        // console.log('analytes', analytes)
        analytes.forEach((analyte) => {
          // currentRow.dilution = sample.dilution || 1
          // console.log('currentRow.dilution', currentRow.dilution)
          currentRow[analyte] = sample[analyte] <= 0 ? 'N/A' : this.unitConv(analyte, sample[analyte] * currentRow.dilution)
          currentRow.analyte_unit = this.unit// analysis.analyte_unit
          allTableData.push(currentRow)
        })
        // }
      })
      this.allTableData = allTableData
    },
    getUniqueColumnVariables() {
      const uniqueColumnVariables = new Set()
      this.allTableData.forEach((row) => {
        Object.keys(row).forEach((key) => {
          uniqueColumnVariables.add(key)
        })
      })
      this.uniqueColumnVariables = uniqueColumnVariables
    },
    onSelectionChanged() {
      const selectedRows = this.gridApi.getSelectedRows()
      this.selectedRows = selectedRows
      this.$emit('setSelectedRows', selectedRows)
      // return selectedRows // for es lint-no-unused-var
      // emit these back for batch operations
    },
    onGridReady(params) {
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
      // if (this.globalAnalyses.length < 1) {
      //   this.gridApi.showLoadingOverlay()
      // }
    },
    downloadSelectedRows() {
      this.setCorrectStationPK(this.selectedRows)
      const columnsToExport = []
      const excludedColumns = ['analyte_unit', 'sample_pk', 'station_pk', 'device', 'analysis_pk']
      // const excludedColumns = ['analyte_unit', 'sample_id', 'sample_pk', 'station_pk', 'device', 'analysis_pk']
      this.uniqueColumnVariables.forEach((column) => {
        if (!excludedColumns.includes(column)) {
          columnsToExport.push(column)
        }
      })
      this.gridApi.exportDataAsCsv({ onlySelected: true, columnKeys: columnsToExport })
      this.notifyUserAndDeselectRows()
    },
    setCorrectStationPK(rows) {
      const uniqueStations = Object.assign({}, ...this.stationsGlobal.map((item) => ({ [item.name]: item.id })))
      rows.forEach((row) => {
        row.station_pk = uniqueStations[row.station_name]
      })
      if (rows.length > 0) {
        this.gridApi.applyTransaction({ update: rows })
        this.gridApi.redrawRows()
      }
      return rows
    },
    onCellValueChanged(event) {
      // console.log('onCellValueChanged', event)
      this.selectedRows = this.setCorrectStationPK(this.selectedRows)
      event.data.station_pk = this.setCorrectStationPK([event.data])[0].station_pk
      // emit this back for updation of any value in a row
      if (event.column.colId === 'comment') {
        this.$emit('updateTag', event.data)
      } else if (event.column.colId === 'station_name') {
        this.$emit('assignStation', event.data)
        // this.gridApi.deselectAll()
      } else if (event.column.colId === 'dilution') {
        this.gameBoyResults.filter(sample => sample.collected === event.data.collected).forEach((sample) => {
          sample.dilution = event.newValue
        })
        // console.log('before gameboyResults', this.gameBoyResults)

        this.setupTable()
        // console.log('after gameboyResults', this.gameBoyResults)

      }
    },
    onRowValueChanged(event) {
      // not needed for now
      // const data = event.data
      return event.data // for es lint-no-unused-var
    },
    generateAdditionalColors(requiredColors) {
      const colors = []
      while (colors.length < requiredColors) {
        const newColor = `rgb(${rand(0, 255)}, ${rand(0, 255)}, ${rand(0, 255)})`
        colors.push(newColor)
        this.chartColors.push(newColor)
      }
      // random number generator
      function rand(from, to) {
        return ~~(Math.random() * (to - from)) + from
      }
    },
    calculateCellColorsForStations() {
      // const colors = [ // [Google Material Design color hex codes(100) ]https://gist.github.com/kiley0/756bf555c5a7ae17b8d03596ae364712
      //   '#F44336', '#FFEBEE', '#FFCDD2', '#EF9A9A', '#E57373',
      //   '#EF5350', '#F44336', '#E53935', '#D32F2F', '#C62828',
      //   '#B71C1C', '#FF8A80', '#FF5252', '#FF1744', '#D50000',
      //   '#E91E63', '#FCE4EC', '#F8BBD0', '#F48FB1', '#F06292',
      //   '#EC407A', '#E91E63', '#D81B60', '#C2185B', '#AD1457',
      //   '#880E4F', '#FF80AB', '#FF4081', '#F50057', '#C51162',
      //   '#9C27B0', '#F3E5F5', '#E1BEE7', '#CE93D8', '#BA68C8',
      //   '#AB47BC', '#9C27B0', '#8E24AA', '#7B1FA2', '#6A1B9A',
      //   '#4A148C', '#EA80FC', '#E040FB', '#D500F9', '#AA00FF',
      //   '#673AB7', '#EDE7F6', '#D1C4E9', '#B39DDB', '#9575CD',
      //   '#7E57C2', '#673AB7', '#5E35B1', '#512DA8', '#4527A0',
      //   '#311B92', '#B388FF', '#7C4DFF', '#651FFF', '#6200EA',
      //   '#3F51B5', '#E8EAF6', '#C5CAE9', '#9FA8DA', '#7986CB',
      //   '#5C6BC0', '#3F51B5', '#3949AB', '#303F9F', '#283593',
      //   '#1A237E', '#8C9EFF', '#536DFE', '#3D5AFE', '#304FFE',
      //   '#2196F3', '#E3F2FD', '#BBDEFB', '#90CAF9', '#64B5F6',
      //   '#42A5F5', '#2196F3', '#1E88E5', '#1976D2', '#1565C0',
      //   '#0D47A1', '#82B1FF', '#448AFF', '#2979FF', '#2962FF',
      //   '#03A9F4', '#E1F5FE', '#B3E5FC', '#81D4FA', '#4FC3F7',
      //   '#29B6F6', '#03A9F4', '#039BE5', '#0288D1', '#0277BD']
      // eslint-disable-next-line
      let colors = ['#64F08C', '#78AB82', '#D6FADC', '#3B4472', '#00DAB2',
        '#00BFD3', '#00A1E1', '#545B8C', '#ECEEFF', '#8C8EB1',
        '#3C4A3E', '#A9AE8A', '#68EDCB', '#E8EAD8', '#78814E',
        '#C9A74E', '#907419', '#719A78', '#EEFDF0', '#9FAFA1',
        '#FF990F', '#AEEB77', '#FFDE82', '#FF6A6F', '#00D395',
        '#1ABA5B']
      // uncomment the below code to generate a random colors array if the above are not sufficient
      while (colors.length < this.uniqueStations.length) {
        colors.push(`rgb(${rand(0, 255)}, ${rand(0, 255)}, ${rand(0, 255)})`)
      }
      // random number generator
      function rand(from, to) {
        return ~~(Math.random() * (to - from)) + from
      }
      const cellColors = {}
      this.uniqueStations.forEach((station, index) => {
        cellColors[station] = colors[index]
      })
      this.stationCellColors = cellColors
    },

    getAnalytes: function () {
      const analytes = this.$store.getters['data/analytes']
      this.analytes = analytes
      const analyteNames = analytes.map(a => a.name)
      // const fixedFields = ['sample_id', 'collected', 'analyzed', 'tag', 'dilution', 'unit']
      // this.fields = fixedFields.push(...analyteNames)
      // this.tableFields = fixedFields.concat(analyteNames)
      this.analyteNames = analyteNames
    },

    saveData: async function (rows) {
      this.saveButtonVisible = true
      const validationStatus = []
      let validateOK = true
      for (const analysis of rows) {
        let validateText = null
        // sample_id character check
        const sampleIdOK = !/[^a-z0-9-_]/i.test(analysis.sample_id)
        console.log('sampleIdOK', sampleIdOK)
        if (!(sampleIdOK && analysis.sample_id.length > 0 && analysis.sample_id !== 'SAMPLE_ID')) {
          validateOK = false
          validateText = {
            sample_id: analysis.sample_id,
            text: 'Sample_id must contain only alphanumeric characters, dashes and hyphens, no spaces and cannot be SAMPLE_ID'
          }
        }
        if (validateOK && analysis.unit !== 'ppm') {
          if (analysis.unit === 'mmol/L') {
            this.analytes.forEach((analyte) => {
              // console.log('***analysis name', analyte.name, analysis[analyte.name])
              if ((analyte.name in analysis) && analysis[analyte.name]) {
                analysis[analyte.name] = (analysis[analyte.name] * analyte.molecular_weight).toFixed(3)
              }
            })
          } else if (analysis.unit === 'ppm_as_element') {
            this.analytes.forEach((analyte) => {
              if ((analyte.name in analysis) && analysis[analyte.name]) {
                analysis[analyte.name] = (analysis[analyte.name] / analyte.second_conv).toFixed(3)
              }
            })
          } else {
            validateOK = false
            validateText = {
              sample_id: analysis.sample_id,
              text: 'Invalid unit: allowed values are ppm, mmol/L and ppm_as_element'
            }
          }
        }
        if (validateText) {
          validationStatus.push(validateText)
        }
        // console.log('***validation status:', validationStatus)
      }

      if (validateOK) {
        this.saveButtonVisible = true
        this.validationResults = null
        this.showUploadSpinner = true
        const status = []
        for (const analysis of rows) {
          const statusText = await this.processAnalysis(analysis)
          status.push(statusText)
        }
        // this.resetButtonVisible = true
        this.uploadResults = status
        if (this.uploadResults) {
          this.removeUploadedRows()
        }
        this.showUploadSpinner = false
        // this.formDisabled = true
        // console.log('***PDF UPLOAD CALLING getAnalysesCompact')
        const payload = { unit: this.unit, pag: 0, noStaff: false }
        this.$store.dispatch('data/getAnalysesCompact', payload)
      } else {
        this.validationResults = validationStatus
        // console.log('***FINAL validation status:', this.validationResults, validationStatus)
        this.saveButtonVisible = true
      }
      // this.saveButtonVisible = false
    },

    processAnalysis: async function (analysis) {
      // console.log('***Starting loop for:', analysis.sample_id)
      // console.log('***Analysis Data :', analysis)
      let statusText = {}
      let samplePk = null
      const sampleGetResult = await this.getSampleBySampleId(analysis.sample_id)
      if (sampleGetResult.exists === 'YES') {
        samplePk = sampleGetResult.id
        // console.log('***Found Sample for:', analysis.sample_id, samplePk)
        // if sampleGetResults fails we are carrying on. Who knows, the post might still work!
      } else {
        // console.log('***Creating Sample for:', analysis.sample_id)
        // console.log('***Post Data :', analysis)
        const samplePostResult = await this.postSample(analysis)
        if (samplePostResult.status === 'OK') {
          samplePk = samplePostResult.id
        } else {
          statusText = {
            sample_id: analysis.sample_id,
            text: 'error saving sample'
          }
        }
      }
      if (samplePk) {
        analysis.sample_pk = samplePk
        const analysisPostResult = await this.postAnalysis(analysis)
        if (analysisPostResult.status === 'OK') {
          statusText = {
            sample_id: analysis.sample_id,
            data_id: analysisPostResult.data_id,
            text: 'successfully saved'
          }
        } else {
          statusText = {
            sample_id: analysis.sample_id,
            text: 'error saving analysis' + analysisPostResult.reason,
            reason: analysisPostResult.reason
          }
        }
      }
      return statusText

      /* statusText = {
        sample_id: analysis.sample_id,
        data_id: 'test_data_id',
        text: 'successfully saved'
      }

      return statusText */
    },

    getSampleBySampleId: function (sampleId) {
      return new Promise((resolve, reject) => {
        let status = {}
        this.$api.getSampleById(sampleId)
          .then(response => {
            // console.log('***getSampleBySampleId response', response)
            if (response.length === 1) {
              // console.log('***found id', response[0].id)
              status = { exists: 'YES', id: response[0].id }
            } else if (response.length > 1) {
              status = { exists: 'NO', id: null, reason: 'Multiple samples found' }
            } else if (response.length < 1) {
              status = { exists: 'NO', id: null, reason: 'No sample found' }
            }
            resolve(status)
          }).catch((error) => {
            console.log('***Error in ImportAnalyses postSample: ', error)
            status = { exists: 'NO', id: null, reason: error }
            reject(status)
          })
      })
    },

    postSample: function (analysis) {
      return new Promise((resolve, reject) => {
        const postData = {
          sample_id: analysis.sample_id,
          account: this.thisAccountPK,
          location: analysis.station,
          collected: analysis.collected,
          tag: analysis.tag
        }
        let status = {}
        this.$api.postSample(postData)
          .then(response => {
            status = { status: 'OK', id: response.id }
            resolve(status)
          }).catch((error) => {
            let text = null
            if (error.non_field_errors) {
              text = error.non_field_errors[0]
            } else {
              text = error
            }
            console.log('***Error in ImportAnalyses postSample: ', text)
            status = { status: 'ERROR', id: null }
            // Note I am not using reject here because otherwise I have to catch the error in the calling function
            resolve(status)
          })
      })
    },

    postAnalysis: function (analysis) {
      return new Promise((resolve, reject) => {
        const postData = {
          sample: analysis.sample_pk,
          dilution: analysis.dilution,
          analyzed: analysis.analyzed,
          device: this.deviceID,
          // device: this.webAppPk,
          tag: analysis.tag
        }
        console.log('***ANALYSIS =', analysis, postData)
        const analytePostData = []
        console.log('this.analytes', JSON.stringify(this.analytes))
        console.log('analysis', JSON.stringify(analysis))
        this.analytes.forEach((analyte) => {
          if (analyte.name in analysis) {
            const value = analysis[analyte.name]
            // console.log('***POST ANALYSIS analyte, analysis, value', analyte, analysis, value)
            // Important: we are only adding the analyte if it has a value
            // In addition this needs to be validated in Django at the data level
            console.log('****VALUE FIXED', value, Number(value).toFixed(3))
            const correctedValue = Number(value).toFixed(3)                        
            if (value || value > -1) {
              console.log('value exists', value, correctedValue)
              analytePostData.push({ analyte: analyte.id, value: correctedValue })
            }
          }
        })
        let status = {}
        postData.analysis_analyte = analytePostData
        console.log('***SENDING ANALYSIS POSTDATA', postData)
        this.$api.postAnalysis(postData)
          .then(response => {
            status = { status: 'OK', data_id: response.data_id }
            resolve(status)
          }).catch((error) => {
            let text = null
            if (error.non_field_errors) {
              text = error.non_field_errors[0]
            } else {
              text = error
            }
            console.log('***Error in ImportAnalyses postAnalysis: ', text)
            status = { status: 'ERROR', data_id: null, reason: text }
            reject(status)
          })
      })
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/styles/app.scss';
// @import "~ag-grid-community/styles/ag-grid.css";
// @import "~ag-grid-community/styles/ag-theme-alpine.css";

.this-text {
  /* pulling in a standard color defined in app.scss */
  color: $ndt-color-5;
}

.ag-theme-alpine {
  --ag-font-size: 12px;
}

.tablePadding {
  padding-left: 2%;
  padding-right: 2%;
}

.this-form-select {
  width: 204px;
  height: 35px;
  /* changed from 44.21 */
  background: #053C37;
  border: 1px solid #002323;
  border-radius: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #FFFFFF;
  margin-top: 18px;
  margin-bottom: 16px;
}

.this-table-outline {
  padding-top: 32px;
  border: 2px solid black;
  height: 453px;
  background: $ndt-debug-color;
  margin-left: $ndt-main-panel-left-margin;
  margin-right: $ndt-main-panel-right-margin;
}

.this-button-done-small {
  width: 110px;
  height: 31px;
  border: 1px solid $ndt-color-5;
  background: $ndt-color-5;
  border-radius: 72px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: $ndt-color-2;
}
.uploadInstruction{
  font-family: 'Avenir-book';
  text-align: justify;
  font-style: normal;
  font-weight: 400;
}
.disabled-button {
  opacity: 0.5; /* Adjust the opacity or other styles as needed */
  cursor: not-allowed; /* Change cursor style for better indication */
  /* Add more styles as needed */
}

.ag-theme-alpine {
  font-family: 'Avenir-book';
}
</style>
