<template>
  <div class="ndt-main-panel" :class="{ 'ndt-sidebar-expanded': sidebarVisible, 'ndt-sidebar-collapsed': sidebarCollapsed }">
    <div class="ndt-debug" v-if="debugOn">
      {{getParentFileName}} / <strong>{{getFileName}}</strong>
    </div>

    <div class="d-flex">
      <div class="ndt-top-title d-flex">
        <div class="ndt-icon-container">
          <div class="this-person-container">
            <PersonMenu/>
          </div>
          <RoomIcon class="this-page-icon" :isActive="true"/>
        </div>
        <div class="ndt-title-text">
          Home
        </div>
      </div>
      <div class="ndt-top-button ml-auto">
        <div class="this-mobile-element">
          <AccountSelector/>
        </div>
      </div>
    </div>

    <div class="overviewDiv">
      <div class="introLineDiv" v-if="sidebarVisible">Hello, Here's your summary</div>
      <div class="introLineDiv" v-if="!sidebarVisible">Hello</div>
      <div class="overviewStatsContainer">
        <div class="statBoxAndButtonContainer">
        <div class="statBox">
          pH/EC Logs:  <strong>{{ pHECLogs }}</strong>
        <br>
        Last Log: <strong>{{ lastpHECLog }}</strong>
        </div>
        <div class="statBoxActionButton"  @click="openpHECLogger()">Log pH/EC values</div>
      </div>

      <div class="statBoxAndButtonContainer" v-if="sidebarVisible">
        <div class="statBox">
                    Lab Reports:  <strong>{{ labReports }}</strong>
                    <br>
                    Last Upload:  <strong>{{ lastLabReportUpload }}</strong>
        </div>
        <div class="statBoxActionButton" @click="openLapReportUploader()">Upload Lab report</div>
      </div>
      <div class="statBoxAndButtonContainer" v-if="sidebarVisible">
        <div class="statBox">
                    Analyzer tests: <strong>{{ analyzerTests }}</strong>
                    <br>
                    Last Test: <strong>{{ lastAnalyzerTest }}</strong>
        </div>
        <div class="statBoxActionButton"><a  href="mailto:support@nordetect.com?Subject=Support%20Contact" target="_blank"
          style="all: unset;">Analyzer Support</a></div>
      </div>
      </div>
    </div>
<div class="roomsHeadingContainer"  >
    <div class="ndt-title-text">
          Rooms
    </div>
</div>
    <!-- <div class="d-flex flex-wrap this-rooms-container align-items-center justify-content-center"> -->
    <div class="d-flex flex-wrap this-rooms-container justify-content-center">
      <div v-for="room in rooms" :key="room.id" class="this-room">
        <div class="d-flex">
          <div class="this-room-icon"><RoomPrettyIcon/></div>
          <div class="this-room-text">{{ room.name }}</div>
        </div>
        <template v-if="room.location">
          <div v-for="station in room.location" :key="station.id" class="this-station-container d-flex">
            <div @click="handleEditStationClicked(station, room)" style="cursor: pointer" class="this-edit-icon">
              <EditIcon/>
            </div>
            <div class="this-station d-flex">
              <div class="this-station-icon"><StationPrettyIcon/></div>
              <div class="this-station-text">{{ station.name }}</div>
            </div>
            <div @click="handleDeleteStationClicked(station)" style="cursor: pointer" class="this-delete-icon">
              <DeleteIcon/>
            </div>
          </div>
        </template>
        <div @click="addStation(room)" style="cursor: pointer" class="this-add-station">
        + Add station
        </div>
        <div @click="handleEditRoomClicked(room)" style="cursor: pointer" class="this-edit-room">
          <EditIcon/>
        </div>
        <div @click="handleDeleteRoomClicked(room)" style="cursor: pointer" class="this-delete-room">
          <DeleteIcon/>
        </div>
      </div>
      <div @click="addRoom" style="cursor: pointer" class="this-add-room">
      + Add Room
      </div>
    </div>

    <b-modal
      v-model="deleteModalVisible"
      size="sm"
      content-class="ndt-card-1"
      centered
      scrollable
      no-close-on-backdrop
      no-close-on-escape
      hide-header-close
      hide-header
      hide-footer
    >
      <template v-if="selectedRoom">
        <template v-if="selectedRoom.location.length > 0">
          <div class="mb-2">This room contains one or more stations. You must delete the stations first before deleting the room</div>
          <div class="text-center">
            <button class="ndt-button-cancel-small" @click="cancelDelete()">
              Ok
            </button>
          </div>
        </template>
        <template v-else>
          <div class="text-center mb-2">You are deleting {{selectedRoom.name}}</div>
          <div class="text-center">
            <button class="ndt-button-done-small" @click="deleteItem(selectedRoom.id)">Confirm</button>
            <span class="ml-2">
              <button class="ndt-button-cancel-small" @click="cancelDelete()">
                Cancel
              </button>
            </span>
          </div>
          <b-card class="mt-1 mb-1" v-if="errorText">
            {{errorText}}
          </b-card>
        </template>
      </template>
    </b-modal>

    <b-modal
      v-model="stationDeleteModalVisible"
      size="sm"
      content-class="ndt-card-1"
      centered
      scrollable
      no-close-on-backdrop
      no-close-on-escape
      hide-header-close
      hide-header
      hide-footer
    >
      <div class="text-center mb-2 ndt-text-lg">You are deleting {{selectedStationName}}</div>
      <div class="text-center">
        <button class="ndt-button-done-small" @click="deleteStation(selectedStationId)">Confirm</button>
        <span class="ml-2">
          <button class="ndt-button-cancel-small" @click="cancelStationDelete()">
            Cancel
          </button>
        </span>
      </div>
      <b-card class="mt-1 mb-1" v-if="errorText">
        {{errorText}}
      </b-card>
    </b-modal>

    <b-modal
      v-model="prodObjectModalVisible"
      content-class="ndt-card-1"
      size="lg"
      centered
      scrollable
      no-close-on-backdrop
      no-close-on-escape
      hide-header-close
      hide-header
      hide-footer
    >
      <ProdObjectCreateAndUpdate v-if="action === 'create'" :action="{ create: true }" @closeMe="prodObjectModalVisible = false" @success="prodObjectCreateSuccess()" />
      <ProdObjectCreateAndUpdate v-if="action === 'edit'" :action="{ update: true }" :pk="selectedObjectId" @closeMe="prodObjectModalVisible = false" @success="prodObjectCreateSuccess()" />
    </b-modal>

    <b-modal
      v-model="stationModalVisible"
      content-class="ndt-card-1"
      size="lg"
      centered
      scrollable
      no-close-on-backdrop
      no-close-on-escape
      hide-header-close
      hide-header
      hide-footer
    >
      <StationCreateAndUpdate v-if="action === 'create'" :action="{ create: true }" :prodObject="selectedRoom" @closeMe="stationModalVisible = false" @success="stationCreateSuccess()" />
      <StationCreateAndUpdate v-if="action === 'edit'" :action="{ update: true }" :pk="selectedObjectId" :data="selectedObject" :prodObject="selectedRoom" @closeMe="stationModalVisible = false" @success="stationCreateSuccess()" />
    </b-modal>

  </div>
</template>

<script>
import GeneralMixin from '@/mixins/GeneralMixin.vue'
import RoomIcon from '@/assets/RoomIcon.vue'
import RoomPrettyIcon from '@/assets/RoomPrettyIcon.vue'
import StationPrettyIcon from '@/assets/StationPrettyIcon.vue'
import DeleteIcon from '@/assets/DeleteIcon.vue'
import EditIcon from '@/assets/EditIcon.vue'
import PersonMenu from '@/components/PersonMenu.vue'
import AccountSelector from '@/components/AccountSelector.vue'
import StationCreateAndUpdate from '@/components/StationCreateAndUpdate.vue'
import ProdObjectCreateAndUpdate from '@/components/ProdObjectCreateAndUpdate.vue'

export default {
  name: 'RoomBoard',

  components: {
    RoomIcon,
    EditIcon,
    ProdObjectCreateAndUpdate,
    StationCreateAndUpdate,
    RoomPrettyIcon,
    StationPrettyIcon,
    DeleteIcon,
    PersonMenu,
    AccountSelector
  },

  mixins: [GeneralMixin],

  watch: {
    analysesGlobal: {
      handler: function (newValue, oldValue) {
        console.log('new analysesGlobal in rooms', newValue, oldValue)
        this.calculateLabAndAnalyzerStats()
      },
      deep: true
    },

    genericDataGlobal: {
      handler: function (newValue, oldValue) {
        console.log('new genericDataGlobal in rooms', newValue, oldValue)
        this.calculateQuickStats()
      },
      deep: true
    }
  },

  data () {
    return {
      deleteModalVisible: false,
      editModalVisible: false,
      prodObjectModalVisible: false,
      stationDeleteModalVisible: false,
      stationModalVisible: false,
      itemToDelete: null,
      nameToDelete: null,
      errorText: null,
      selectedStationName: null,
      selectedObjectName: null,
      selectedObjectId: null,
      selectedRoom: null,
      action: null,
      pHECLogs: 'Loading',
      lastpHECLog: 'Loading',
      labReports: 'Loading',
      lastLabReportUpload: 'Loading',
      analyzerTests: 'Loading',
      lastAnalyzerTest: 'Loading'
    }
  },

  mounted () {
    this.logEvent('Data tab mounted')
    this.calculateLabAndAnalyzerStats()
    this.calculateQuickStats()
  },

  computed: {
    rooms: function () {
      const indata = this.$store.getters['data/prodObjects']
      const rooms = []
      indata.forEach((item) => {
        if (item.type?.name === 'Room') {
          rooms.push(item)
        }
      })
      return rooms
    }
  },

  methods: {
    openpHECLogger () {
      this.$router.push({ path: '/data/quickloggerupload', props: { showQuickSelect: true, showQuickLogger: true } })
    },

    openLapReportUploader () {
      this.$router.push({ path: '/data/pdfUpload', props: { pdfUploadModalVisible: true } })
    },

    stationCreateSuccess () {
      this.$store.dispatch('data/getProdObjects')
      this.$store.dispatch('data/getStations')
      this.$store.dispatch('objectboard/getProdObjectTopLevelList')
    },

    prodObjectCreateSuccess () {
      this.$store.dispatch('data/getProdObjects')
      this.$store.dispatch('objectboard/getProdObjectTopLevelList')
    },

    prodObjectDeleteSuccess () {
      this.$store.dispatch('data/getProdObjects')
      this.$store.dispatch('objectboard/getProdObjectTopLevelList')
    },

    addStation (obj) {
      this.selectedRoom = obj
      this.action = 'create'
      this.stationModalVisible = true
    },

    addRoom (e) {
      this.action = 'create'
      this.prodObjectModalVisible = true
    },

    handleDeleteRoomClicked (obj) {
      this.errorText = null
      this.selectedRoom = obj
      this.deleteModalVisible = true
    },

    handleEditRoomClicked (obj) {
      this.errorText = null
      this.selectedObjectName = obj.name
      this.selectedObjectId = obj.id
      this.action = 'edit'
      this.prodObjectModalVisible = true
    },

    handleEditStationClicked (obj, room) {
      this.errorText = null
      this.selectedObjectName = obj.name
      this.selectedObjectId = obj.id
      obj.prod_object = room.id
      this.selectedObject = obj
      this.action = 'edit'
      this.stationModalVisible = true
    },

    cancelDelete: function () {
      // this.selectedObject = null
      this.deleteModalVisible = false
    },

    cancelStationDelete: function () {
      // this.selectedObject = null
      this.stationDeleteModalVisible = false
    },

    edit (e) {
      this.selectedObject = e
      this.selectedObjectId = e.id
      this.editModalVisible = true
    },

    handleShowDetails (e) {
      this.showDetails = true
      this.modalData = e
    },

    showStationDetails (e) {
      this.showStationDetailsModal = true
      this.selectedStationId = e.id
    },

    handleDeleteStationClicked (obj) {
      this.errorText = null
      this.selectedStationName = obj.name
      this.selectedStationId = obj.id
      this.stationDeleteModalVisible = true
    },

    deleteItem: function (pk) {
      this.$api.deleteProdObjectDetail(pk)
        .then(response => {
          this.$store.dispatch('data/getProdObjects')
          this.deleteModalVisible = false
        })
        .catch(error => {
          this.logDebug(`***Error in deleteItem ${error}`)
          this.errorText = error
        })
    },

    deleteStation: function (pk) {
      this.$api.deleteStationDetail(pk)
        .then(response => {
          this.$store.dispatch('data/getStations')
          this.$store.dispatch('data/getProdObjects')
          this.stationDeleteModalVisible = false
        })
        .catch(error => {
          this.logDebug(`***Error in deleteStation ${error}`)
          this.errorText = error
        })
    },

    calculateLabAndAnalyzerStats: function () {
      const labDates = []
      const analyzerDates = []
      const labData = this.analysesGlobal.filter((value) => value.device === this.webAppPk)
      const analyzerData = this.analysesGlobal.filter((value) => value.device !== this.webAppPk)
      this.labReports = labData.length
      this.analyzerTests = analyzerData.length

      labData.forEach((value) => labDates.push(new Date(value.collected)))
      analyzerData.forEach((value) => analyzerDates.push(new Date(value.collected)))

      labDates.sort((a, b) => a.getTime() - b.getTime())
      analyzerDates.sort((a, b) => a.getTime() - b.getTime())

      if (labDates.length > 0) {
        this.lastLabReportUpload = labDates[labDates.length - 1].toDateString()
      } else {
        this.lastLabReportUpload = 'N/A'
      }
      if (analyzerDates.length > 0) {
        this.lastAnalyzerTest = analyzerDates[analyzerDates.length - 1].toDateString()
      } else {
        this.lastAnalyzerTest = 'N/A'
      }
    },

    calculateQuickStats: function () {
      const dates = []
      this.pHECLogs = this.genericDataGlobal.length
      this.genericDataGlobal.forEach((value) => dates.push(new Date(value.collected)))
      dates.sort((a, b) => a.getTime() - b.getTime())
      if (dates.length > 0) {
        this.lastpHECLog = dates[dates.length - 1].toDateString()
      }
    }
  }

}
</script>

<style lang="scss" scoped>
  @import '@/styles/app.scss';

  .overviewDiv{
    font-family: 'Inter';
    margin-left: 82px;
  }
  .introLineDiv{
    text-align: start;
    font-size: 3rem;
    font-weight: 700;
    color: black;
    margin-top: 2%;
  }

  .overviewStatsContainer{
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-weight: 600;
      margin-top: 2%;
      margin-bottom: 4%;
  }

  .statBoxAndButtonContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .statBoxActionButton{
    background: #64F08C;
    border-radius: 19px;
    padding: 3% 5% 3% 5%;
    margin-top: 5%;
    margin-bottom: 5%;
    margin-top: 10%;
    cursor: pointer;
  }

  .statBox{
    border: 1px solid $ndt-color-2;
    border-radius: 8px;
    padding: 2%;
    margin-top: 5%;
    height: 4rem;
    width: 16rem;
  }

  .statBoxMargin{
    margin-top: 5%;
  }

  .roomsHeadingContainer{
    text-align: start;
    margin-left: 74px;
    margin-top: 1%;
  }
  .this-rooms-container {
    margin-left: 28px;
    margin-top: 38px;
  }

  @media (max-width: $tablet-breakpoint) {
    .this-rooms-container {
      margin-left: 0px;
      overflow: auto
    }
  }

  .this-room {
    position: relative;
    width: 380px;
    border: 1px solid $ndt-color-2;
    margin-left: 26px;
    margin-bottom: 35px;
    background: $ndt-color-1;
    border: 1px solid $ndt-color-2;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
  }

  @media (max-width: $tablet-breakpoint) {
    .this-room {
      margin-left: 0px;
      width: 380px;
      max-width: 380px;
      margin-right: 0px;
    }

    .overviewDiv{
      font-family: 'Inter';
      margin-left: 59px;
    }
    .overviewStatsContainer{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      margin-left: -59px;
      margin-top: 2%;
      margin-bottom: 8%;
    }

    .roomsHeadingContainer{
      text-align: start;
      margin-left: 55px;
      margin-top: 1%;
  }
  }

  .this-room-icon {
    margin-left: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .this-room-text {
    margin-left: 12px;
    margin-top: 18px;
    margin-bottom: 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #002323;
  }

  .this-station-icon {
    margin-left: 12px;
    margin-top: 10px
  }

  .this-station-text {
    margin-left: 12px;
    margin-top: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #002323;
  }

  .this-station-container {
    margin-left: 36px;
    margin-right: 36px;
    margin-bottom: 16px;
  }

  .this-station {
    margin-left: 16px;
    width: 204px;
    height: 43px;
    border: 1px solid $ndt-color-3;
    border-radius: 10px;
  }

  .this-delete-icon {
    margin-left: 16px;
    margin-top: 6px;
  }

  .this-edit-icon {
    margin-left: 16px;
    margin-top: 6px;
  }

  .this-add-station {
    padding-top: 10px;
    margin-left: 82px;
    width: 204px;
    height: 43px;
    border-radius: 10px;
    margin-bottom: 16px;
    background: #F9FFFA;
    border: 1px solid $ndt-color-5;
    border-radius: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #002323;
  }

  .this-add-room {
    padding-top: 10px;
    margin-left: 26px;
    width: 380px;
    height: 43px;
    border: 1px solid $ndt-color-3;
    border-radius: 10px;
    margin-bottom: 16px;
    background: #F9FFFA;
    border: 1px solid $ndt-color-5;
    border-radius: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #002323;
  }

  @media (max-width: $tablet-breakpoint) {
    .this-add-room {
      margin-left: 0px;
    }
  }

  .this-delete-room {
    position: absolute;
    bottom: 16px;
    right: 16px;
    /* margin-top: 16px;
    margin-left: 320px;
    margin-bottom: 16px; */
  }

  .this-edit-room {
    position: absolute;
    bottom: 16px;
    left: 16px;
    /* margin-top: 16px;
    margin-left: 320px;
    margin-bottom: 16px; */
  }

  /*---- The following controls the transition to tablet ------*/

  .this-person-container {
    display: none;
  }

  .this-page-icon {
    display: block;
  }

  .this-mobile-element {
    display: none;
  }

  @media (max-width: $tablet-breakpoint) {
    .this-person-container {
      display: block;
    }

    .this-page-icon {
      display: none;
    }

    .this-mobile-element {
      display: block;
    }
  }

</style>
