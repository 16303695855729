import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import utils from '@/utils/utils.js'
import api from '@/utils/api.js'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import '@/styles/app.scss'
// https://stackoverflow.com/questions/39373047/include-global-functions-in-vue-js
Vue.prototype.$api = api
Vue.prototype.$utils = utils
Vue.config.productionTip = false

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// Create an Event Bus
export const eventsBus = new Vue()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
