<template lang="html">
  <router-view></router-view>
</template>

<script>
export default {
  name: 'LoginLayout'
}
</script>

<style lang="scss" scoped>
</style>
