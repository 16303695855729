<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import axios from 'axios'
import { eventsBus } from '@/main'
import GeneralMixin from '@/mixins/GeneralMixin.vue'

export default {
  name: 'App',

  mixins: [GeneralMixin],

  created: function () {
    axios.interceptors.response.use(undefined, function (err) {
      return new Promise(function (resolve, reject) {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch('logout')
        }
        throw err
      })
    })
    const loggedIn = this.$store.getters['auth/loggedIn']
    // I have moved the debug setting into AppHeader.vue
    // if (process.env.NODE_ENV === 'development') { this.$store.dispatch('options/setDebugOn', true) }
    // TEST
    // const apiKey = 'T1YgCFAl.jA1PwTWy7tlWdRKB70QN9yg14PFfdhhF'
    // PROD
    const apiKey = '6zZd54dQ.NQDhXxKKdWwSI9KFPsQMCh8R5F6kYiay'
    axios.defaults.headers.common['X-Api-Key'] = apiKey
    // axios.defaults.headers.common['Content-Type'] = 'application/json'
    if (loggedIn) {
      // If we are logged in then it means we need to reload the non persisted data as we are
      // not going through the login process
      const token = this.$store.getters['auth/authToken']
      axios.defaults.headers.common.Authorization = 'Bearer ' + token
      this.getAllData()
    }
    eventsBus.$on('accountChanged', (data) => {
      const activeAccount = this.$store.getters['options/activeAccount']
      console.log('**** APP.VUE Account changed', activeAccount)
      if (activeAccount) {
        this.getAllData()
      }
    })
    eventsBus.$on('changedView', (data) => {
      console.log('***EVENT in App.vue, from to', data.from, data.to)
    })
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },

  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },

  mounted () {
  },

  methods: {
    // see also:
    // https://stackoverflow.com/questions/58141018/mobile-device-detection
    getDeviceType: function () {
      const ua = navigator.userAgent
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return 'tablet'
      } else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
        return 'mobile'
      }
      return 'desktop'
    },

    getAllData: function () {
      const aset = this.$store.getters['options/accountSet']
      const activeAccount = this.$store.getters['options/activeAccount']
      console.log('****App.vue getAllData ActiveAccount 1', aset, activeAccount.id)
      this.getAccounts()
      if (this.$store.getters['options/accountSet']) {
        // this.getSamples()
        this.getAnalysesCompact()
        this.getProdObjects()
        this.getStations()
        this.getCrops()
        this.getTargets()
        this.getAnalytes()
        this.getDevices()
        this.getEvents()
        this.getGenericVars()
        this.getGenericPredefinedVars()
        this.getGenericTargets()
        this.getGenericData()
        this.getProdObjectTypes()
      }
    },
    getAccounts: function () {
      this.$store.dispatch('data/getAccounts')
    },
    getSamples: function () {
      this.$store.dispatch('data/getSamples')
    },
    getAnalysesCompact: function () {
      const payload = { unit: this.unit, pag: 0, noStaff: false }
      this.$store.dispatch('data/getAnalysesCompact', payload)
    },
    getProdObjects: function () {
      this.$store.dispatch('data/getProdObjects')
    },
    getStations: function () {
      this.$store.dispatch('data/getStations')
    },
    getCrops: function () {
      this.$store.dispatch('data/getCrops')
    },
    getTargets: function () {
      this.$store.dispatch('data/getTargets')
    },
    getAnalytes: function () {
      this.$store.dispatch('data/getAnalytes')
    },
    getDevices: function () {
      this.$store.dispatch('data/getDevices')
    },
    getEvents: function () {
      this.$store.dispatch('data/getEvents')
    },
    getGenericVars: function () {
      this.$store.dispatch('data/getGenericVars')
    },
    getGenericPredefinedVars: function () {
      this.$store.dispatch('data/getGenericPredefinedVars')
    },
    getGenericTargets: function () {
      this.$store.dispatch('data/getGenericTargets')
    },
    getGenericData: function () {
      this.$store.dispatch('data/getGenericData')
    },
    getProdObjectTypes: function () {
      this.$store.dispatch('data/getProdObjecTypes')
    },
    handleResize: function () {
      const wwidth = window.innerWidth
      // const wheight = window.innerHeight
      // const vwidth = document.documentElement.clientWidth
      // const vheight = document.documentElement.clientHeight
      // console.log('***HANDLE RESIZE', wwidth)
      this.$store.dispatch('options/setWidth', wwidth)
      // if (wwidth < 800) {
      // this.$store.dispatch('options/setMediaType', 'mobile')
      // } else {
      // this.$store.dispatch('options/setMediaType', 'desktop')
      // }
      // We are not using deviceType for anything, instead we are using the media query
      // hover: hover if we need to detect a mobile device / tablet
      // const deviceType = this.getDeviceType()
      // this.$store.dispatch('options/setDeviceType', deviceType)
    }
  }
}

</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
