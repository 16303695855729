<template>
  <span>
    <div class="viewButton" @click="invokeAction">View</div>
  </span>
</template>

<script>
export default {
  name: 'CustomButtonComponent',
  mounted () {
    if (!this.params || !this.params.context || !this.params.context.componentParent) {
      console.error('params or componentParent is undefined!')
    }
  },
  methods: {
    invokeAction () {
      if (this.params && this.params.context && this.params.context.componentParent) {
        // console.log('invokeAction', this.params.data)
        this.params.context.componentParent.viewRow(this.params.data)
      } else {
        console.error('Cannot invoke action because params or componentParent is undefined')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.viewButton {
  width: 2.5rem;
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 90px;
  background: #64F08C;
  color: #000;
  font-family: Helvetica Neue;
  font-size: 14px;
  font-style: normal;
  cursor: pointer;
  height: 1.45rem;
  display: inline-flex;
}
</style>
