<template lang="html">
<div>
  <!-- <AppHeader/> -->
  <AppSidebar/>
  <router-view></router-view>
  <AppFooter/>
</div>
</template>

<script>
import GeneralMixin from '@/mixins/GeneralMixin.vue'
// import AppHeader from './AppHeader.vue'
import AppFooter from './AppFooter.vue'
import AppSidebar from './AppSidebar.vue'

export default {
  name: 'HomeLayout',

  components: {
    // AppHeader,
    AppFooter,
    AppSidebar
  },

  mixins: [GeneralMixin]

}
</script>

<style lang="scss" scoped>
</style>
