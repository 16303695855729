import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth.js'
import data from './data.js'
import options from './options.js'
import map from './map.js'
import objectboard from './objectboard.js'
import cropboard from './cropboard.js'
import analyticsboard from './analyticsboard'
import createPersistedState from 'vuex-persistedstate'
import Cookies from 'js-cookie'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth,
    data,
    options,
    map,
    objectboard,
    cropboard,
    analyticsboard
  },
  plugins: [
    createPersistedState({
      paths: ['auth', 'options'],
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) =>
          // cookie expires on browser close
          Cookies.set(key, value, { secure: false }),
        removeItem: (key) => Cookies.remove(key)
      }
    })
  ]
})

export default store
