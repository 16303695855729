import api from '@/utils/api.js'
import utils from '@/utils/utils.js'

const state = {
  station_status: '',
  stations: [],
  account_status: '',
  accounts: [],
  this_account_meta: { sampleCount: 0, analysisCount: 0, stationCount: 0, cropCount: 0, prodObjectCount: 0, targetCount: 0 },
  prodobject_status: '',
  prodobjects: [],
  samples: [],
  sample_meta: { page: 0, cumulativeCount: 0 },
  sample_status: '',
  analyses: [],
  analysis_meta: { page: 0, cumulativeCount: 0 },
  analysis_status: '',
  analysescompact: [],
  analysis_compact_status: '',
  targets: [],
  target_status: '',
  crops: [],
  crop_status: '',
  analytes: [],
  analyte_status: '',
  devices: [],
  device_status: '',
  events: [],
  event_meta: { page: 0, cumulativeCount: 0 },
  event_status: '',
  genericpredefinedvars: [],
  genericpredefinedvars_status: '',
  genericvars: [],
  genericvars_status: '',
  genericdata: [],
  genericdata_status: '',
  generictargets: [],
  generictargets_status: '',
  genericlocationlist: {},
  prodobjecttype_status: '',
  prodobjecttypes: [],
  gameboyobject_status: '',
  gameboyobjects: { test: 1 }
}

const mutations = {
  station_request (state) {
    state.station_status = 'loading'
  },
  station_success (state, data) {
    state.station_status = 'success'
    state.stations = data
    state.this_account_meta.stationCount = data.length
  },
  station_error (state) {
    state.station_status = 'error'
  },
  account_request (state) {
    state.account_status = 'loading'
  },
  account_success (state, data) {
    state.account_status = 'success'
    state.accounts = data
  },
  account_error (state) {
    state.account_status = 'error'
  },
  // this_account_meta (state, data) {
  //  state.this_account_meta = data
  // },
  prodobject_request (state) {
    state.prodobject_status = 'loading'
  },
  prodobject_success (state, data) {
    state.prodobject_status = 'success'
    state.prodobjects = data
    state.this_account_meta.prodObjectCount = data.length
  },
  prodobject_error (state) {
    state.prodobject_status = 'error'
  },
  sample_success (state, data) {
    state.sample_status = 'success'
    state.samples = data
    // state.this_account_meta.sampleCount = data.length
  },
  sample_meta (state, data) {
    state.sample_meta = data
    state.this_account_meta.sampleCount = data.count
  },
  sample_error (state) {
    state.sample_status = 'error'
  },
  analysis_request (state) {
    state.analysis_status = 'loading'
  },
  analysis_success (state, data) {
    state.analysis_status = 'success'
    state.analyses = data
    state.this_account_meta.analysisCount = data.length
  },
  analysis_error (state) {
    state.analysis_status = 'error'
  },
  analysis_compact_request (state) {
    state.analysis_compact_status = 'loading'
  },
  analysis_compact_success (state, data) {
    state.analysis_compact_status = 'success'
    state.analysescompact = data
    state.this_account_meta.analysisCount = data.length
  },
  analysis_compact_error (state) {
    state.analysis_compact_status = 'error'
  },
  analysis_meta (state, data) {
    state.analysis_meta = data
  },
  target_success (state, data) {
    state.target_status = 'success'
    state.targets = data
    state.this_account_meta.targetCount = data.length
  },
  target_error (state) {
    state.target_status = 'error'
  },
  crop_success (state, data) {
    state.crop_status = 'success'
    state.crops = data
    state.this_account_meta.cropCount = data.length
  },
  crop_error (state) {
    state.crop_status = 'error'
  },
  analyte_success (state, data) {
    state.analyte_status = 'success'
    state.analytes = data
  },
  analyte_error (state) {
    state.analyte_status = 'error'
  },
  device_success (state, data) {
    state.device_status = 'success'
    state.devices = data
  },
  device_error (state) {
    state.device_status = 'error'
  },
  event_success (state, data) {
    state.event_status = 'success'
    state.events = data
  },
  event_error (state) {
    state.event_status = 'error'
  },
  event_meta (state, data) {
    state.event_meta = data
  },
  genericvar_success (state, data) {
    state.genericvar_status = 'success'
    state.genericvars = data
  },
  genericvar_error (state) {
    state.genericvar_status = 'error'
  },
  genericpredefinedvar_success (state, data) {
    state.genericpredefinedvar_status = 'success'
    state.genericpredefinedvars = data
  },
  genericpredefinedvar_error (state) {
    state.genericpredefinedvar_status = 'error'
  },
  genericdata_success (state, data) {
    state.genericdata_status = 'success'
    state.genericdata = data
  },
  genericlocationlist_success (state, data) {
    state.genericlocationlist = data
  },
  genericdata_error (state) {
    state.genericdata_status = 'error'
  },
  generictarget_success (state, data) {
    state.generictarget_status = 'success'
    state.generictargets = data
  },
  generictarget_error (state) {
    state.generictarget_status = 'error'
  },
  prodobjecttype_success (state, data) {
    state.prodobjecttype_status = 'success'
    state.prodobjecttypes = data
  },
  prodobjecttype_error (state) {
    state.prodobjecttype_status = 'error'
  },
  gameboyobjects_success (state, data) {
    state.gameboyobject_status = 'success'
    state.gameboyobjects = data
  }
}

const getters = {
  stations: state => state.stations,
  accounts: state => state.accounts,
  thisAccountMeta: state => state.this_account_meta,
  prodObjects: state => state.prodobjects,
  samples: state => state.samples,
  sampleMeta: state => state.sample_meta,
  analyses: state => state.analyses,
  analysesCompact: state => state.analysescompact,
  analysisMeta: state => state.analysis_meta,
  targets: state => state.targets,
  crops: state => state.crops,
  analytes: state => state.analytes,
  devices: state => state.devices,
  events: state => state.events,
  eventMeta: state => state.event_meta,
  genericVars: state => state.genericvars,
  genericPredefinedVars: state => state.genericpredefinedvars,
  genericData: state => state.genericdata,
  genericTargets: state => state.generictargets,
  genericLocationList: state => state.genericlocationlist,
  prodObjectTypes: state => state.prodobjecttypes,
  gameboyObjects: state => state.gameboyobjects
}

const actions = {

  setUnit ({ commit, getters }, unit) {
    console.log('***SETTING UNIT IN STORE', unit)
    const analyses = getters.analysesCompact
    analyses.forEach((analysis) => {
      // console.log('****analysis =', analysis)
      analysis.analysis_analyte?.forEach((item) => {
        if (unit === 'mmol') {
          item.value = (item.base_value / item.molecular_weight).toFixed(3)
        } else if (unit === 'second') {
          item.value = (item.base_value * item.analyte_second_conv).toFixed(3)
        } else {
          item.value = item.base_value
        }
      })
    })
    commit('analysis_compact_success', analyses)
  },

  setGameboyObjects ({ commit, getters }, data) {
    console.log('***SETTING GB OBJECTS IN STORE', data)
    commit('gameboyobjects_success', data)
  },

  setInitialGameboyObjects ({ commit }) {
    console.log('***SETTING Initial GB OBJECTS IN STORE')
    const data = {
      deviceConnected: false,
      deviceError: false,
      deviceErrorMessage: 'Error in connecting to device',
      selectedDevice: null,
      primaryDevice: null,
      primaryServer: null,
      allServicesObtained: false,
      selectExpanded: false,
      csvDataChunks: [],
      totalChunks: 0,
      csvData: null,
      allServices: {},
      gettingCSVData: false,
      totalProgress: 0,
      gameBoyResults: [],
      showSpinner: false,
      resultDataServiceUUID: '4fafc201-1fb5-459e-8fcc-c5c9c331914b',
      RTCServiceUUID: '6d5b6a3f-65d2-4b2b-96ab-af231ab301c8',
      wifiConfigServiceUUID: 'f5a5c0f9-99f4-4a6e-9f92-864af2d9bfce',
      djangoDeviceIDServiceUUID: '3a1e8f60-3c2d-4e0a-93b7-0a954b1c9e20',
      wifiName: '',
      wifiPassword: '',
      wifiConfigured: false,
      wifiConfiguringMessage: '',
      currentWifiConfigStatus: 'No Device Connected',
      deviceID: null,
      dataSourceName: 'Dashboard',
      showPassword: false
    }
    commit('gameboyobjects_success', data)
  },

  getStations ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('station_request')
      api.getStationListMin()
        .then(resp => {
          commit('station_success', resp)
          resolve(resp)
        })
        .catch(err => {
          commit('station_error')
          reject(err)
        })
    })
  },
  getAccounts ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('account_request')
      api.getAccountList()
        .then(resp => {
          commit('account_success', resp)
          resolve(resp)
        })
        .catch(err => {
          commit('account_error')
          reject(err)
        })
    })
  },
  getAccountsTest ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('account_request')
      api.getTestList()
        .then(resp => {
          commit('account_success', resp)
          resolve(resp)
        })
        .catch(err => {
          commit('account_error')
          reject(err)
        })
    })
  },
  getProdObjects ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('prodobject_request')
      api.getProdObjectList()
        .then(resp => {
          commit('prodobject_success', resp)
          resolve(resp)
        })
        .catch(err => {
          commit('prodobject_error')
          reject(err)
        })
    })
  },
  getSamples ({ commit, getters }, pag = 0) {
    let currentPage = getters.sampleMeta.page
    if (!currentPage) { currentPage = 0 }
    let nextPage = null
    nextPage = currentPage + pag
    if (nextPage < 1) { nextPage = 1 }
    return new Promise((resolve, reject) => {
      api.getSampleList(nextPage)
        .then(resp => {
          // console.log('****STORE SAMPLES data ', resp)
          const metadata = {}
          metadata.page = nextPage
          metadata.count = resp.count
          metadata.cumulativeCount = getters.sampleMeta.cumulativeCount + resp.results.length
          metadata.size = resp.results.length
          if (nextPage === 1) { metadata.initialSize = resp.results.length } else { metadata.initialSize = getters.sampleMeta.initialSize }
          if (nextPage === 1) { metadata.maxPages = Math.ceil(resp.count / resp.results.length) } else { metadata.maxPages = getters.sampleMeta.maxPages }
          const show = ((metadata.initialSize * (metadata.page - 1)) + metadata.size) + 1
          if (show > metadata.count) { metadata.showingNumber = metadata.count } else { metadata.showingNumber = show }
          commit('sample_success', resp.results)
          commit('sample_meta', metadata)
          resolve(resp)
        })
        .catch(err => {
          commit('sample_error')
          reject(err)
        })
    })
  },
  getAnalyses ({ commit, getters }, payload) {
    let currentPage = getters.analysisMeta.page
    if (payload.pag === 0) { currentPage = 1 }
    if (!currentPage) { currentPage = 0 }
    // console.log('***STORE ANALYSES current page, payload: ', currentPage, payload)
    let nextPage = null
    nextPage = currentPage + payload.pag
    if (nextPage < 1) { nextPage = 1 }
    return new Promise((resolve, reject) => {
      api.getAnalysisList(nextPage, payload.noStaff)
        .then(resp => {
          // console.log('****STORE ANALYSES data ', resp)
          const metadata = {}
          metadata.page = nextPage
          metadata.count = resp.count
          metadata.cumulativeCount = getters.analysisMeta.cumulativeCount + resp.results.length
          metadata.size = resp.results.length
          if (nextPage === 1) { metadata.initialSize = resp.results.length } else { metadata.initialSize = getters.analysisMeta.initialSize }
          if (nextPage === 1) { metadata.maxPages = Math.ceil(resp.count / resp.results.length) } else { metadata.maxPages = getters.analysisMeta.maxPages }
          const show = ((metadata.initialSize * (metadata.page - 1)) + metadata.size) + 1
          if (show > metadata.count) { metadata.showingNumber = metadata.count } else { metadata.showingNumber = show }
          commit('analysis_success', resp.results)
          commit('analysis_meta', metadata)
          resolve(resp)
        })
        .catch(err => {
          commit('sample_error')
          reject(err)
        })
    })
  },
  getAnalysesCompact ({ commit, getters }, payload) {
    let currentPage = getters.analysisMeta.page
    if (payload.pag === 0) { currentPage = 1 }
    if (!currentPage) { currentPage = 0 }
    // console.log('***STORE ANALYSES current page, payload: ', currentPage, payload)
    let nextPage = null
    nextPage = currentPage + payload.pag
    if (nextPage < 1) { nextPage = 1 }
    // const combinedResult = {}
    return new Promise((resolve, reject) => {
      api.getAnalysisListCompact(nextPage, payload.noStaff).then((resp) => {
        // console.log('getAnalysisListCompact result', result)
        // combinedResult.compact = result
        const compactResponse = utils.correctAnalysisUnitsAndValues(resp, payload)
        api.getAnalysisListFull(nextPage, payload.noStaff).then((newResponse) => {
          const metadata = {}
          metadata.page = nextPage
          metadata.count = resp.count
          metadata.cumulativeCount = getters.analysisMeta.cumulativeCount + resp.results.length
          metadata.size = resp.results.length
          if (nextPage === 1) { metadata.initialSize = resp.results.length } else { metadata.initialSize = getters.analysisMeta.initialSize }
          if (nextPage === 1) { metadata.maxPages = Math.ceil(resp.count / resp.results.length) } else { metadata.maxPages = getters.analysisMeta.maxPages }
          const show = ((metadata.initialSize * (metadata.page - 1)) + metadata.size) + 1
          if (show > metadata.count) { metadata.showingNumber = metadata.count } else { metadata.showingNumber = show }
          const fullResponse = utils.correctAnalysisUnitsAndValues(newResponse, payload)
          const combinedResponse = utils.combineCompactAndFullAnalysesResponses(compactResponse, fullResponse)
          // console.log('getAnalysisListCompact full', newResponse)
          // console.log('getAnalysesCompact combinedResult', combinedResponse)
          commit('analysis_compact_success', combinedResponse.results)
          commit('analysis_meta', metadata)
          resolve(combinedResponse)
        }).catch(err => {
          commit('analysis_compact_error')
          reject(err)
        })
      })
    })

    // return new Promise((resolve, reject) => {
    //   api.getAnalysisListCompact(nextPage, payload.noStaff)
    //     .then(resp => {
    //       console.log('****STORE ANALYSES data FULL AND COMPACT ', resp)
    //       const metadata = {}
    //       metadata.page = nextPage
    //       metadata.count = resp.count
    //       metadata.cumulativeCount = getters.analysisMeta.cumulativeCount + resp.results.length
    //       metadata.size = resp.results.length
    //       if (nextPage === 1) { metadata.initialSize = resp.results.length } else { metadata.initialSize = getters.analysisMeta.initialSize }
    //       if (nextPage === 1) { metadata.maxPages = Math.ceil(resp.count / resp.results.length) } else { metadata.maxPages = getters.analysisMeta.maxPages }
    //       const show = ((metadata.initialSize * (metadata.page - 1)) + metadata.size) + 1
    //       if (show > metadata.count) { metadata.showingNumber = metadata.count } else { metadata.showingNumber = show }
    //       resp.results.forEach((result) => {
    //         result.collected = result.collected ? result.collected : result.analyzed
    //         result.analysis_analyte?.forEach((item) => {
    //           // console.log('****STORE DATA ANALYSIS_ANALYTE', item)
    //           // If it is a micronutrient then all displayed values need to be as ppb or micrommoles
    //           if (item.analyte_type === 'micro') {
    //             item.base_value = item.value * 1000
    //           } else {
    //             item.base_value = item.value
    //           }
    //           if (payload.unit === 'mmol') {
    //             item.value = (item.base_value / item.molecular_weight).toFixed(3)
    //           } else if (payload.unit === 'second') {
    //             item.value = (item.base_value * item.analyte_second_conv).toFixed(3)
    //           } else {
    //             item.value = item.base_value
    //           }
    //         })
    //       })
    //       commit('analysis_compact_success', resp.results)
    //       commit('analysis_meta', metadata)
    //       resolve(resp)
    //     })
    //     .catch(err => {
    //       commit('analysis_compact_error')
    //       reject(err)
    //     })
    // })
  },
  /* getAnalyses ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('analysis_request')
      api.getAnalysisList()
        .then(resp => {
          commit('analysis_success', resp)
          resolve(resp)
        })
        .catch(err => {
          commit('analysis_error')
          reject(err)
        })
    })
  }, */

  getTargets ({ commit }) {
    return new Promise((resolve, reject) => {
      api.getTargetList()
        .then(resp => {
          commit('target_success', resp)
          resolve(resp)
        })
        .catch(err => {
          commit('target_error')
          reject(err)
        })
    })
  },
  getCrops ({ commit }) {
    return new Promise((resolve, reject) => {
      api.getCropList()
        .then(resp => {
          commit('crop_success', resp)
          resolve(resp)
        })
        .catch(err => {
          commit('crop_error')
          reject(err)
        })
    })
  },
  getAnalytes ({ commit }) {
    return new Promise((resolve, reject) => {
      api.getAnalyteList()
        .then(resp => {
          commit('analyte_success', resp)
          resolve(resp)
        })
        .catch(err => {
          commit('analyte_error')
          reject(err)
        })
    })
  },
  getDevices ({ commit }) {
    return new Promise((resolve, reject) => {
      api.getDeviceList()
        .then(resp => {
          commit('device_success', resp)
          resolve(resp)
        })
        .catch(err => {
          commit('device_error')
          reject(err)
        })
    })
  },
  /* getEvents ({ commit }) {
    return new Promise((resolve, reject) => {
      api.getEventList()
        .then(resp => {
          commit('event_success', resp)
          resolve(resp)
        })
        .catch(err => {
          commit('event_error')
          reject(err)
        })
    })
  } */
  getEvents ({ commit, getters }, pag = 0) {
    let currentPage = getters.eventMeta.page
    if (!currentPage) { currentPage = 0 }
    let nextPage = null
    nextPage = currentPage + pag
    if (nextPage < 1) { nextPage = 1 }
    return new Promise((resolve, reject) => {
      api.getEventList(nextPage)
        .then(resp => {
          // console.log('****STORE EVENTS data ', resp)
          const metadata = {}
          metadata.page = nextPage
          metadata.count = resp.count
          metadata.cumulativeCount = getters.eventMeta.cumulativeCount + resp.results.length
          metadata.size = resp.results.length
          if (nextPage === 1) { metadata.initialSize = resp.results.length } else { metadata.initialSize = getters.eventMeta.initialSize }
          if (nextPage === 1) { metadata.maxPages = Math.ceil(resp.count / resp.results.length) } else { metadata.maxPages = getters.eventMeta.maxPages }
          const show = ((metadata.initialSize * (metadata.page - 1)) + metadata.size) + 1
          if (show > metadata.count) { metadata.showingNumber = metadata.count } else { metadata.showingNumber = show }
          commit('event_success', resp.results)
          commit('event_meta', metadata)
          resolve(resp)
        })
        .catch(err => {
          commit('sample_error')
          reject(err)
        })
    })
  },
  getGenericVars ({ commit }) {
    return new Promise((resolve, reject) => {
      api.getGenericVarList()
        .then(resp => {
          commit('genericvar_success', resp)
          resolve(resp)
        })
        .catch(err => {
          commit('genericvar_error')
          reject(err)
        })
    })
  },
  getGenericPredefinedVars ({ commit }) {
    return new Promise((resolve, reject) => {
      api.getGenericPredefinedVarList()
        .then(resp => {
          commit('genericpredefinedvar_success', resp)
          resolve(resp)
        })
        .catch(err => {
          commit('genericpredefinedvar_error')
          reject(err)
        })
    })
  },
  getGenericData ({ commit }) {
    return new Promise((resolve, reject) => {
      api.getGenericDataList()
        .then(resp => {
          console.log('***GENERIC DATA', resp)
          const stationGenericList = {
            pH: [],
            EC: []
          }
          resp.forEach((item) => {
            if (item.variable_name === 'pH' && item.location && !stationGenericList.pH.includes(item.location)) {
              stationGenericList.pH.push(item.location)
            }
            if (item.variable_name === 'EC' && item.location && !stationGenericList.EC.includes(item.location)) {
              stationGenericList.EC.push(item.location)
            }
          })
          commit('genericdata_success', resp)
          commit('genericlocationlist_success', stationGenericList)
          resolve(resp)
        })
        .catch(err => {
          console.log('***GENERIC DATA ERROR', err)
          commit('genericdata_error')
          reject(err)
        })
    })
  },
  getGenericTargets ({ commit }) {
    return new Promise((resolve, reject) => {
      api.getGenericTargetList()
        .then(resp => {
          commit('generictarget_success', resp)
          resolve(resp)
        })
        .catch(err => {
          commit('generictarget_error')
          reject(err)
        })
    })
  },
  getProdObjecTypes ({ commit }) {
    return new Promise((resolve, reject) => {
      api.getProdObjectTypeList()
        .then(resp => {
          commit('prodobjecttype_success', resp)
          resolve(resp)
        })
        .catch(err => {
          commit('prodobjecttype_error')
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
