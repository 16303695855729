<template>
  <div class="ndt-main-panel"
    :class="{ 'ndt-sidebar-expanded': sidebarVisible, 'ndt-sidebar-collapsed': sidebarCollapsed }">
    <div class="ndt-debug" v-if="debugOn">
      {{ getParentFileName }} / <strong>{{ getFileName }}</strong>
    </div>

    <div class="d-flex">
      <div class="ndt-top-title d-flex">
        <div class="ndt-icon-container device-heading">
          <div class="this-mobile-element">
            <PersonMenu />
          </div>
          <DeviceIcon class="this-desktop-element" :isActive="true" />
        </div>
        <div class="ndt-title-text">
          Device
        </div>
      </div>
      <div class="ndt-top-button d-flex ml-auto">

        <div v-if="!sidebarVisible" class="this-datasource">
          <b-dropdown id="data source dropdown" :text="dataSourceName"  class="this-button-done-small">
            <b-dropdown-item class="this-datasource-dropdown-link"
            @click="handleDatasourceDropdown('dashboard')">Dashboard</b-dropdown-item>
            <b-dropdown-item class="this-datasource-dropdown-link"
            @click="handleDatasourceDropdown('wificonfig')">Manage Wifi</b-dropdown-item>
          </b-dropdown>
        </div>

        <div class="this-mobile-element">
          <AccountSelector />
        </div>
      </div>
    </div>

    <div class="this-dividing-line ndt-hide-on-mobile" :class="{ 'this-select-expanded': selectExpanded }">
    </div>

    <div v-if="showDashboard">
    <div v-if="!deviceConnected" class="this-upload h-100 d-flex align-items-center justify-content-center">
      <div class="this-button-large d-flex" @click="scanAndConnect">Find and Connect
      </div>
    </div>

    <div v-if="deviceError" class="h-100 d-flex align-items-center justify-content-center">
      {{ deviceErrorMessage }}
    </div>

    <div v-if="deviceConnected" class="h-100 d-flex align-items-center justify-content-center">
      Device Connected
    </div>

    <div v-if="deviceConnected && !gettingCSVData &&!showSpinner"
      class="this-upload h-100 d-flex align-items-center justify-content-center">
      <div class="this-button-large d-flex" @click="refreshResults">Refresh Results
      </div>
    </div>

    <div v-if="deviceConnected"
      class="this-upload h-100 d-flex align-items-center justify-content-center">
      <div class="this-button-large d-flex" style="background: red" @click="disconnectDevice">Disconnect Device
      </div>
    </div>

    <div v-if="gettingCSVData" class="h-100 d-flex align-items-center justify-content-center">
      Getting Data - {{ totalProgress }} % Complete
    </div>

    <div v-if="showSpinner">
      <b-spinner style="width: 2rem; height: 2rem; margin-top: 0.5rem; margin-right: 0.5rem" label="Large Spinner">
      </b-spinner>
    </div>

    <div id="GameBoyResultsTableHolder" v-if="gameBoyResults.length > 0">
      <GameBoyResultsTableHolder
      :gameBoyResults="gameBoyResults"
      :deviceID="deviceID"
      @removeSamplesFromGameboy="removeSamplesFromGameboy"
      @updateGameboyResults="updateGameboyResults" />
    </div>
    <div v-if="deviceConnected && allServicesObtained && gameBoyResults.length == 0">
      <br> <br>
        No Results Found
    </div>
  </div>
    <div style="display: flex; justify-content: center;" v-if="showWifiConfigPage">
      <b-card title="Enter WiFi Information" :sub-title="currentWifiConfigStatus">
        <b-form @submit.prevent="submitForm" autocomplete="nope">
          <b-form-group label="WiFi Name:" label-for="wifiName">
            <b-form-input id="wifiName" v-model="wifiName" required autocomplete="nope"></b-form-input>
          </b-form-group>
          <b-form-group label="WiFi Password:" label-for="wifiPassword">
            <b-form-input id="wifiPassword" v-model="wifiPassword" :type="showPassword ? 'text' : 'password'" required autocomplete="nope"></b-form-input>
          </b-form-group>
          <div style="display: flex; justify-content: flex-start;">
            <b-form-checkbox v-model="showPassword">Show Password</b-form-checkbox>
          </div>
        <b-button type="submit" variant="primary" :disabled="!deviceConnected">Submit</b-button>
        </b-form>
      </b-card>
    </div>
    <div v-if="showSpinner && showWifiConfigPage">
      <b-spinner style="width: 2rem; height: 2rem; margin-top: 0.5rem; margin-right: 0.5rem" label="Large Spinner">
      </b-spinner>
    </div>
    <div v-if="showWifiConfigPage && wifiConfigured" class="h-100 d-flex align-items-center justify-content-center">
      {{wifiConfiguringMessage}}
    </div>
  </div>

</template>

<script>
/* eslint-disable */
import GeneralMixin from '@/mixins/GeneralMixin.vue'
import DeviceIcon from '@/assets/DeviceIcon.vue'
import PersonMenu from '@/components/PersonMenu.vue'
import AccountSelector from '@/components/AccountSelector.vue'
import GameBoyResultsTableHolder from '@/components/GameBoyResultsTableHolder.vue'
// import { tr } from 'date-fns/locale'

export default {
  name: 'DeviceBoard',

  components: {
    DeviceIcon,
    PersonMenu,
    AccountSelector,
    GameBoyResultsTableHolder
  },

  mixins: [GeneralMixin],

  data() {
    return {
      deviceConnected: false,
      deviceError: false,
      deviceErrorMessage: 'Error in connecting to device',
      selectedDevice: null,
      primaryDevice: null,
      primaryServer: null,
      allServicesObtained: false,
      selectExpanded: false,
      csvDataChunks: [],
      totalChunks: 0,
      csvData: null,
      allServices: {},
      gettingCSVData: false,
      totalProgress: 0,
      gameBoyResults: [],
      showSpinner: false,
      resultDataServiceUUID: '4fafc201-1fb5-459e-8fcc-c5c9c331914b',
      RTCServiceUUID: '6d5b6a3f-65d2-4b2b-96ab-af231ab301c8',
      wifiConfigServiceUUID: 'f5a5c0f9-99f4-4a6e-9f92-864af2d9bfce',
      djangoDeviceIDServiceUUID: '3a1e8f60-3c2d-4e0a-93b7-0a954b1c9e20',
      wifiName: '',
      wifiPassword: '',
      wifiConfigured: false,
      wifiConfiguringMessage: '',
      currentWifiConfigStatus:'No Device Connected',
      deviceID: null,
      dataSourceName: 'Dashboard',
      showPassword: false,
    }
  },

  computed: {
  },

  props: {
    showDashboard: {
      type: Boolean,
      default: true
    },
    showWifiConfigPage: {
      type: Boolean,
      default: false
    },
  },

  watch: {
    gameboyObjectsGlobal: {
      deep: true, // Enable deep watching for nested properties
      handler(newVal, oldVal) {
        // This function will be called when gameboyObjectsGlobal changes
        console.log('gameboyObjectsGlobal changed:', newVal, oldVal);
        Object.assign(this.$data, newVal);
      },
    },

  },

  mounted() {
    this.logEvent('Device Handler mounted, this.gameboyObjectsGlobal', this.gameboyObjectsGlobal)
    console.log('showWifiConfigPage', this.showWifiConfigPage)
    Object.assign(this.$data, this.gameboyObjectsGlobal);
    // if (Array.isArray(this.gameboyObjectsGlobal)){
    //   console.log('gameboy objects are NOT set', this.gameboyObjectsGlobal)
    // } else {
    //   console.log('gameboy objects are set', this.gameboyObjectsGlobal)

    //   // this.$data = this.gameboyObjectsGlobal
    //   Object.assign(this.$data, this.gameboyObjectsGlobal);
    // }      
  },

  methods: {
    handleDatasourceDropdown: function (type) {
      this.showDashboard = false
      this.showWifiConfigPage = false
      if (type === 'dashboard') {
        this.showDashboard = true
        this.dataSourceName = 'Dashboard'
      } else if (type === 'wificonfig') {
        this.showWifiConfigPage = true
        this.dataSourceName = 'Manage Wifi'
      }
    },
    onBLEDisconnected(event) {
      // Object event.target is Bluetooth Device getting disconnected.
      console.log('Bluetooth Device disconnected');
      this.deviceConnected = false
      this.currentWifiConfigStatus = 'No device connected'
      this.$store.dispatch('data/setGameboyObjects', this.$data)
    },
    disconnectDevice() {
      // console.log('Bluetooth Device disconnected');
      this.primaryServer.disconnect()
      this.deviceConnected = false
      this.currentWifiConfigStatus = 'No device connected'
      this.$store.dispatch('data/setInitialGameboyObjects')
      Object.assign(this.$data, this.gameboyObjectsGlobal)
    },
    async scanAndConnect() {
      try {
        this.primaryDevice = await navigator.bluetooth.requestDevice({
          // acceptAllDevices: true,
          optionalServices: [this.resultDataServiceUUID, this.RTCServiceUUID, this.wifiConfigServiceUUID, this.djangoDeviceIDServiceUUID], // Leave it empty to accept all services
          filters: [
                    // { name: "ExampleName" },
                    { namePrefix: "Nora 2" }
                  ]
        }
        )
        this.primaryDevice.addEventListener('gattserverdisconnected', this.onBLEDisconnected);
        console.log('Device found:', this.primaryDevice)

        this.primaryServer = await this.primaryDevice.gatt.connect()
        this.deviceConnected = true
        this.deviceError = false
        this.deviceErrorMessage = ''
        this.getServicesAndCharacteristics()
      } catch (error) {
        this.deviceError = true
        this.deviceErrorMessage = "Couldn't find nearby devices. Make sure the device is switched on and within range. Please try again."
        console.error('Error scanning devices:', error)
      }

      // if (!this.deviceError) {
      //   try {
      //     this.primaryServer = await this.primaryDevice.gatt.connect()
      //     this.deviceConnected = true
      //   } catch (error) {
      //     this.deviceError = true
      //     this.deviceErrorMessage = 'Device found but an error occurred while connecting to the device'
      //     console.error('Error connecting to device:', error)
      //   }
      // }
    },
    async getServicesAndCharacteristics() {
      try {
        const totalChunksCharacteristicUUID = 'beb5483e-36e1-4688-b7f5-ea07361b26a9'
        const csvDataCharacteristicUUID = 'beb5483e-36e1-4688-b7f5-ea07361b26a8'
        const currentChunkIndexCharacteristicUUID = 'beb5483e-36e1-4688-b7f5-ea07361b26aa'
        const deleteResultCharacteristicUUID = 'a7e8490c-7cd0-43a5-bd65-e35b3e0c9b59'

        const setRTCCharacteristicUUID = '9a7d8c2b-af5e-4e97-b9cc-d1e6d4f8e5a1'

        const wifiConfigCharacteristicUUID = '4ebeef8d-36a5-48c9-bd2f-c08f90567d0b'
        const wifiConfigStatusCharacteristicUUID = '7c2b12a9-5f87-4e39-b6e3-f1dfb1b7a818'

        const djangoDeviceIDCharacteristicUUID = 'd3f28136-9e61-4e2d-86f0-267f37a418e5'

        this.showSpinner = true

        const resultDataService = await this.primaryServer.getPrimaryService(this.resultDataServiceUUID)
        const totalChunksCharacteristic = await resultDataService.getCharacteristic(totalChunksCharacteristicUUID)
        const csvDataCharacteristic = await resultDataService.getCharacteristic(csvDataCharacteristicUUID)
        const currentChunkIndexCharacteristic = await resultDataService.getCharacteristic(currentChunkIndexCharacteristicUUID)
        const deleteResultCharacteristic = await resultDataService.getCharacteristic(deleteResultCharacteristicUUID)

        const RTCService = await this.primaryServer.getPrimaryService(this.RTCServiceUUID)
        const setRTCCharacteristic = await RTCService.getCharacteristic(setRTCCharacteristicUUID)

        const wifiConfigService = await this.primaryServer.getPrimaryService(this.wifiConfigServiceUUID)
        const wifiConfigCharacteristic = await wifiConfigService.getCharacteristic(wifiConfigCharacteristicUUID)
        const wifiConfigStatusCharacteristic = await wifiConfigService.getCharacteristic(wifiConfigStatusCharacteristicUUID)

        const djangoDeviceIDService = await this.primaryServer.getPrimaryService(this.djangoDeviceIDServiceUUID)        
        const djangoDeviceIDCharacteristic = await djangoDeviceIDService.getCharacteristic(djangoDeviceIDCharacteristicUUID)

        this.allServices.resultData = {
          service: resultDataService,
          characteristics: {
            totalChunks: totalChunksCharacteristic,
            csvData: csvDataCharacteristic,
            currentChunkIndex: currentChunkIndexCharacteristic,
            deleteResult: deleteResultCharacteristic
          }
        }
        
        this.allServices.RTC = {
          service: RTCService,
          characteristics: {
            setRTC: setRTCCharacteristic,
          }
        }

        this.allServices.wifiConfig = {
          service: wifiConfigService,
          characteristics: {
            wifiConfig: wifiConfigCharacteristic,
            wifiConfigStatus: wifiConfigStatusCharacteristic
          }
        }

        this.allServices.djangoDeviceID = {
          service: djangoDeviceIDService,
          characteristics: {
            djangoDeviceID: djangoDeviceIDCharacteristic,
          }
        }

        this.allServicesObtained = true
        console.log('getServicesAndCharacteristics successfully executed')
        await this.updateGameboyTime()
        await this.getCurrentWifiConfiguration()
        await this.getTotalChunks()
        await this.fetchCSVData()
        await this.getDjangoDeviceID()
        console.log('this.$data', this.$data)
        this.$store.dispatch('data/setGameboyObjects', this.$data)
        this.showSpinner = false
      } catch (error) {
        this.deviceError = true
        this.deviceErrorMessage = 'Device found and successfully connected but an error while getting internal details' 
        // Device successfully connected but an error occured while fetching data. #TODO :next line Please disconnect device and try again.
        console.error('Error getting getServicesAnd Characteristics', error.message)
        this.showSpinner = false

      }

      // if (!this.deviceError) {
      //   // eslint-disable-next-line
      //   await this.getTotalChunks()
      //   await this.fetchCSVData()
      // }
    },
    async getDjangoDeviceID() {
      const value = await this.allServices.djangoDeviceID.characteristics.djangoDeviceID.readValue()
      const decodedValue = new TextDecoder().decode(value)
      this.deviceID = decodedValue
      console.log('Device ID', this.deviceID)
    },
    async getCurrentWifiConfiguration() {
      const value = await this.allServices.wifiConfig.characteristics.wifiConfig.readValue()
      // console.log('getCurrentWifiConfiguration', new TextDecoder().decode(value))
      const decodedValue = JSON.parse(new TextDecoder().decode(value))
      if (decodedValue.is_wifi_config_saved){
        this.wifiName = decodedValue.name
        this.wifiPassword = decodedValue.password
      }
      const currentWifiConfigStatus = await this.allServices.wifiConfig.characteristics.wifiConfigStatus.readValue() // Obtain the characteristic instance
      // console.log('currentWifiConfigStatus', currentWifiConfigStatus)
      this.currentWifiConfigStatus = new TextDecoder().decode(currentWifiConfigStatus)
      // console.log('currentWifiConfigStatus', this.currentWifiConfigStatus)      
    },
    async refreshResults() {
      // await this.getTotalChunks()
      // await this.fetchCSVData()
      this.primaryServer = await this.primaryDevice.gatt.connect()
      this.deviceConnected = true
      this.getServicesAndCharacteristics()
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    async writeToCharacteristic(service, characteristic, value) {
      try {
        await this.allServices[service].characteristics[characteristic].writeValueWithResponse(value)
        console.log('Written to characteristic')
        return true
      } catch (error) {
        console.log('Could not write to characteristic', error)
        return false
      }
    },
    async writeToCharacteristicWithoutResponse(service, characteristic, value) {
      try {
        await this.allServices[service].characteristics[characteristic].writeValueWithoutResponse(value)
        console.log('Written to characteristic')
        return true
      } catch (error) {
        console.log('Could not write to characteristic', error)
        return false
      }
    },
    async getTotalChunks() {
      try {
        const value = await this.allServices.resultData.characteristics.totalChunks.readValue()
        this.totalChunks = value.getUint16(0, true) // Assuming total chunks are 16-bit unsigned integers
        console.log('Total Chunks:', this.totalChunks)
      } catch (error) {
        this.deviceError = true
        this.deviceErrorMessage = 'Device successfully connected but an error occured while fetching data. #TODO :next line Please disconnect device and try again.'
        console.error('Error getting getTotalChunks', error.message)
      }
    },
    async fetchCSVData() {
      if (this.totalChunks === 0) {
        this.csvData = ''
        return ''
      }
      try {
        this.gettingCSVData = true
        this.csvDataChunks = [] // Clear any previous chunks
        // Reset the currentChunkIndexCharacteristic to value 0 by writing a Uint8Array with two zero bytes
        // eslint-disable-next-line
        const chunkIndexReset = await this.writeToCharacteristic('resultData', 'currentChunkIndex', new Uint8Array([0, 0]))

        console.time('Time taken to get all chunks') // Start measuring time
        for (let i = 0; i < this.totalChunks; i++) {
          const value = await this.allServices.resultData.characteristics.csvData.readValue()
          this.totalProgress = Math.round((i / this.totalChunks) * 100)
          this.csvDataChunks.push(value)
          await this.sleep(15) // Optional: Add a short delay between reading chunks
        }
        console.timeEnd('Time taken to get all chunks') // End measuring time //140 seconds for 10800 bytes but coming in arrays of 9 values [0,1,2,3,4,5,6,7,8]
        // End measuring time // 67 seconds for 10800 bytes but coming in arrays of 18 values [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17]
        let csvDataString = ''
        this.csvDataChunks.forEach((chunk) => {
          const chunkString = new TextDecoder().decode(chunk)
          csvDataString += chunkString
        })
        // console.log('csvDataChunks, csvDataString', this.csvDataChunks, csvDataString)
        const parsedData = JSON.parse(csvDataString)
        this.gettingCSVData = false
        console.log('Data received', parsedData)
        this.gameBoyResults = parsedData
        return parsedData
      } catch (error) {
        this.deviceError = true
        this.deviceErrorMessage = 'Device found, successfully connected but an error while getting data'
        console.error('Error getting fetchCSVData', error.message)
      }
    },
    async removeSamplesFromGameboy(data) {
  const resultsToRemove = [];
  const allUnsavedResults = [];

  for (const oldResult of this.gameBoyResults) {
    let shouldRemove = false;
    
    for (const uploadedResult of data) {
      if (uploadedResult.collected === oldResult.collected) {
        shouldRemove = true;
        break; // No need to check other uploadedResults for this oldResult
      }
    }

    if (shouldRemove) {
      resultsToRemove.push(oldResult);
    } else {
      allUnsavedResults.push(oldResult);
    }
  }

  const successfullDeletions = [];
  const failedDeletions = [];

  for (const result of resultsToRemove) {
    console.log('result collected', result.collected);
    this.showSpinner = true;
    const data = new TextEncoder().encode(result.collected); // Convert value to ArrayBufferView
    const deletionResult = await this.writeToCharacteristic('resultData', 'deleteResult', data);

    if (deletionResult) {
      successfullDeletions.push(result);
    } else {
      failedDeletions.push(result);
    }

    this.sleep(30);
    this.showSpinner = false;
  }

  this.showSpinner = false;
  this.gameBoyResults = [...allUnsavedResults, ...failedDeletions];
},
    updateGameboyResults: function (data) {
      console.log('updateGameboyResults', data)
      // this.gameBoyResults = data
    },
    getcurrentformattedTimestamp() {
      const date = new Date()
      return [
        date.getFullYear(),
        date.getMonth() + 1, // Months are 0-indexed, so add 1
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      ]
    },
    async updateGameboyTime() {
      try {
        const timestamp = this.getcurrentformattedTimestamp()
        const data = new TextEncoder().encode(JSON.stringify(timestamp)) // Convert value to ArrayBufferView
        const result = await this.writeToCharacteristic('RTC','setRTC', data)
        if (result) {
          this.timestamp = timestamp
        }
      } catch (error) {
        this.deviceError = true
        this.deviceErrorMessage = 'Device found, successfully connected but an error while updating internal device data (timestamp)'
        console.error('Error updating timestamp', error.message)
      }
    },
    async submitForm() {
      this.showSpinner = true
      const wifiInfo = {
        name: this.wifiName,
        password: this.wifiPassword
      }
      console.log('Submitted WiFi Information:', wifiInfo)
      const data = new TextEncoder().encode(JSON.stringify(wifiInfo))
      const wifiConfigSubmission = await this.writeToCharacteristicWithoutResponse('wifiConfig', 'wifiConfig', data)
      if(wifiConfigSubmission){ 
        // this.showSpinner = false
        this.wifiConfigured = true
        this.wifiConfiguringMessage = 'Wifi credentials successfully sent, the device should restart in 1-2 minutes and there should be a wifi symbol on the device. If any error occurs, it will be shown here'
        this.showSpinner = true
        this.currentWifiConfigStatus = 'Attempting to configure WiFi on device'
        await new Promise(resolve => setTimeout(resolve, 25000)); // Wait for 45 seconds
                
         // Obtain the characteristic instance
        // const totalTimeSeconds = 90 // 1.5 minutes
        this.checkWifiConfigStatus()

      }else{
        this.showSpinner = false
        this.deviceError = true
        this.deviceErrorMessage = 'Error in configuring wifi on the device, could not send credentials.'
      }
    },
    // async checkWifiConfigStatus () {
    //   // this.refreshResults()
    //   const reConnectTrialNumber = 5
    //   for (let i = 0; i < reConnectTrialNumber; i++) {
    //     // try {
    //   try {
    //     this.primaryServer = await this.primaryDevice.gatt.connect()
    //     this.deviceConnected = true
    //     await this.getServicesAndCharacteristics()
    //     const currentWifiConfigStatus = await this.allServices.wifiConfig.characteristics.wifiConfigStatus.readValue()
    //     const receivedData = new TextDecoder().decode(currentWifiConfigStatus)
    //     this.currentWifiConfigStatus = receivedData
    //     console.log("Received WiFi config status:", receivedData);
    //       if (receivedData == 'ERROR') {
    //         this.wifiConfiguringMessage = 'Wifi credentials successfully sent but could not configure wifi on device.'
    //         this.showSpinner = false
    //         return ''                
    //       } else if (receivedData == 'CONFIGURING') {
    //         this.wifiConfiguringMessage = 'Wifi credentials successfully sent, currently configuring wifi on device. If any error occurs, it will be shown here'
    //       } else if (receivedData == 'CONFIGURED') {
    //         this.wifiConfiguringMessage = 'Wifi credentials successfully sent and configured wifi on device.'
    //         this.showSpinner = false                
    //         return ''                              
    //       }else {
    //         this.wifiConfiguringMessage = 'Wifi is not configured on the device.'
    //         this.showSpinner = false                
    //         return ''        
    //         }
    //       }
    //       catch (error){
    //         console.log('error while reconnecting and getting wifi config status', error)
    //       }
    //       await new Promise(resolve => setTimeout(resolve, 2000));
    //     }  
    //   }
    async checkWifiConfigStatus() {
  const reConnectTrialNumber = 5

  for (let i = 0 ; i < reConnectTrialNumber; i++) {
    try {
      console.log('Connecting to GATT server. Attempt ', i)
      this.primaryServer = await this.primaryDevice.gatt.connect()
      console.log('Connected to GATT server.')

      this.deviceConnected = true
      await this.getServicesAndCharacteristics()

      const currentWifiConfigStatus = await this.allServices.wifiConfig.characteristics.wifiConfigStatus.readValue()
      const receivedData = new TextDecoder().decode(currentWifiConfigStatus)
      this.currentWifiConfigStatus = receivedData

      console.log("Received WiFi config status:", receivedData)

      if (receivedData == 'WiFi not configured') {
        this.wifiConfiguringMessage = 'Wifi credentials successfully sent but could not configure wifi on device.'
        this.showSpinner = false
        return ''
      } else if (receivedData == 'Configuring WiFi') {
        this.wifiConfiguringMessage = 'Wifi credentials successfully sent, currently configuring wifi on device. If any error occurs, it will be shown here'
      } else if (receivedData == 'WiFi configured') {
        this.wifiConfiguringMessage = 'Wifi credentials successfully sent and configured wifi on device.'
        this.showSpinner = false
        return ''
      } else {
        this.wifiConfiguringMessage = 'Wifi not configured.'
        this.showSpinner = false
        return ''
      }
    } catch (error) {
      console.log('Error while reconnecting and getting wifi config status:', error)
    }

    // Wait for a short duration before retrying
    await new Promise(resolve => setTimeout(resolve, 5000))
  }

  console.log('Unable to establish a connection.')
  },
}

}

</script>

<style lang="scss" scoped>
@import '@/styles/app.scss';

.this-top-title {
  margin-top: 45px;
  width: 149px;
  height: 51px;
}

.this-icon-container {
  width: 26px;
  height: 26px;
}

.this-title-text {
  width: 93px;
  height: 31px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 31px;
  color: #000000;
}

.this-save-button {
  margin-top: 32px;
  padding-top: 6px;
  width: 69px;
  height: 30px; // 43px
  background: #64F08C;
  border-radius: 90px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.this-save-button:hover {
  background: #64F08C95;
}

.this-reset-button {
  padding-top: 8px; //4px
  width: 107px; //100
  // height: 30px;
  background: $ndt-color-4;
  border-radius: 20px;
  color: $ndt-color-1;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem; //14px
  line-height: 22px;
}

.this-chart-menu {
  background: $ndt-debug-color;
  margin-top: 15px;
  margin-left: $ndt-main-panel-left-margin;
}

.this-chart-menu-item {
  margin-top: 0px;
}

.this-chart-menu-text {
  margin-top: 0px;
  margin-bottom: 12px;
  text-align: left;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.this-form-select {
  width: 204px;
  height: 43px;
  /* changed from 44.21 */
  background: #053C37;
  border: 1px solid #002323;
  border-radius: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  // font-size: 12px;
  line-height: 19px;
  color: #FFFFFF;
  margin-bottom: 16px;
}

.this-variables-form-select {
  height: 30px;
  width: 100px;
}

.this-chart-form-select {
  // height: 43px;
  width: 100px;
}

.this-form-datepicker {
  /* box-sizing: border-box; */
  width: 130px; //114
  height: 43px; // 43
  text-align: center;
  background: #053C37;
  border: 1px solid #002323;
  border-radius: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  // font-size: 12px;  // 16
  line-height: 15px;
  color: #FFFFFF !important;
  margin-bottom: 16px;
}

.this-form-input {
  /* box-sizing: border-box; */
  text-align: center;
  width: 52px;
  height: 43px; // 43
  background: #053C37;
  border: 1px solid #002323;
  border-radius: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  // font-size: 12px;
  line-height: 19px;
  color: #FFFFFF !important;
  margin-bottom: 16px;
}

.this-chart-menu-right-margin {
  margin-right: 28px;
}

.this-dividing-line {
  width: 100%;
  height: 0px;
  margin-right: 0px;
  margin-left: 0px;
  margin-top: 36px;
  /* 202.5 from top */
  border: 1px solid #D5D5D5;
}

.this-select-expanded {
  margin-top: -8px;
}

.this-chart-title {
  text-align: left;
  height: 31px;
  margin-top: 20px;
  margin-left: $ndt-main-panel-left-margin;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 31px;
  color: #000000;
  background: $ndt-debug-color;
}

.this-datasource {
  position: relative;
  margin-right: 25px;
}

.this-datasource-dropdown-content {
  display: none;
  overflow: visible;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: $ndt-color-5;
  width: 130px;
  border: 1px solid $ndt-color-5;
  border-radius: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px;
  text-align: left;
  z-index: 5000;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: $ndt-color-2;
}

@media (max-width: $tablet-breakpoint) {
  .this-datasource-dropdown-content {
    top: -3px;
  }
}

.this-datasource-dropdown-content ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.this-datasource:hover .this-datasource-dropdown-content {
  display: block;
}

.this-datasource-dropdown-title {
  font-size: 0.9rem;
}

.this-datasource-dropdown-link {
  font-size: 0.9rem;
  cursor: pointer;
}

.this-datasource-dropdown-link:hover {
  color: $ndt-color-6;
}

.this-button-done-small {
  // padding-top: 5px;
  // width: 110px;
  // height: 31px;
  border: 1px solid $ndt-color-5;
  background: $ndt-color-5;
  border-radius: 72px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: $ndt-color-2;
}

.this-datasource-button-container {
  margin-top: 38.5px;
  margin-left: 100px;
}

@media (max-width: $tablet-breakpoint) {
  .this-form-select {
    width: 175px;
  }

  .this-chart-title {
    text-align: left;
    margin-top: 20px;
    margin-left: $ndt-main-panel-left-margin;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
  }
}

/*---- The following controls the transition to tablet ------*/

.this-mobile-element {
  display: none;
}

.this-desktop-element {
  display: block;
}

@media (max-width: $tablet-breakpoint) {
  .this-mobile-element {
    display: block;
  }

  .this-desktop-element {
    display: none;
  }
}

:deep(.btn-secondary) {
  color: #fff;
  background: none;
  border: none;
}

:deep(.btn-secondary:hover) {
  color: #fff;
  background: none;
  border: none;
}

:deep(.btn-secondary:focus) {
  outline: none;
  box-shadow: none;
}

:deep(.dropdown-toggle) {
  background-color: transparent !important;
  border-color: transparent !important;
}

.this-upload {
  position: relative;
  // cursor: pointer;
  padding-top: 3%;
}

.this-button-large {
  cursor: pointer;
  width: 177px;
  height: 40px;
  background: #0D3C3C;
  border: 1px solid #0D3C3C;
  border-radius: 72px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  align-items: center;
  /* padding-left: 30%; */
  display: flex;
  justify-content: center;
}

.chartHolder {
  border: 2.5px solid #003232;
  overflow: hidden;
  margin-left: 2%;
  margin-right: 2%;
  min-height: 300px;
}

.device-heading{
  margin-left: 10px;
  margin-top: 6.5px;
}
</style>
