<template>
<div class="d-flex justify-content-center mainLoginComponent">
  <div class="loginCardClass">
    <div style="margin-bottom: 9%;">
      <!-- Nordetect Logo -->
      <img src="../assets/img/Orsted-nordetect-logo-brightgreen-darkgreen-RGB.png" height="62" width="321px">
    </div>
    <div class="NAPDescription">
      <!-- Nutrient Analysis Platform -->
      Nordetect Analytics Platform
    </div>
    <form method="post" @submit.prevent="userLogin" v-if="!isSignUp">
      <b-form-group>
        <div class="inputBoxDiv">
          <b-form-input
            v-on:keyup.enter="userLogin"
            class = "inputBox border-0"
            type="text"
            id="username"
            name="username"
            v-model="username"
            placeholder="Email address / Username">
          </b-form-input>
        </div>
      </b-form-group>
      <b-form-group>
        <div class="inputBoxDiv">
          <b-form-input
            v-on:keyup.enter="userLogin"
            class = "border-0"
            :type="showPassword ? 'text' : 'password'"
            id="password"
            name="password"
            v-model="password"
            placeholder="Password">
          </b-form-input>
        </div>
      </b-form-group>
      <Notification :message="errorText" v-if="errorText"/>
      <div style="display: flex; justify-content: flex-start;">
        <b-form-checkbox v-model="showPassword">Show Password</b-form-checkbox>
      </div>
      <b-spinner v-if="loading" style="width: 2rem; height: 2rem; margin-top: 0.5rem; margin-right: 0.5rem" label="Large Spinner"></b-spinner>
        <div class="signInButtonDiv" @click.prevent="userLogin">
          <!-- <b-button type="submit" class="signInButtonDiv">Sign In</b-button> -->Sign In
        </div>
    </form>
    <div class="forgotPWDSignupLinksContainer" v-if="!isSignUp">
      <div>
      Forgot Password?
    </div>
    <div @click.prevent="showSignupPage" style="cursor: pointer;">
          Sign up!
    </div>
    </div>

    <div v-if="isSignUp">
      <iframe style="height: 420px;
        width: -webkit-fill-available;" src="https://share.hsforms.com/1n6H2XIeKRLW2_DvPybl7SA31v7r">
      </iframe>
      <div>
       <b-icon icon="arrow-left-circle-fill" font-scale="1.8" style="color:#64F08C;margin-top: 2%;" @click.prevent="showLoginPage"></b-icon>
      </div>
  </div>
  </div>
</div>
</template>

<script>
import Notification from '@/components/Notification.vue'
// import LogoText from '@/assets/LogoText.vue'
import GeneralMixin from '@/mixins/GeneralMixin.vue'

export default {
  name: 'LoginComponent',

  mixins: [GeneralMixin],

  components: {
    Notification
    // LogoText
  },
  data () {
    return {
      username: '',
      password: '',
      errorText: '',
      loading: false,
      isSignUp: false,
      showPassword: false
    }
  },
  methods: {
    async userLogin () {
      try {
        this.loading = true
        await this.$store.dispatch('auth/login', { username: this.username, password: this.password })
        // this.loading = false
      } catch (e) {
        if (e.non_field_errors) {
          this.errorText = e.non_field_errors[0]
          this.loading = false
        } else {
          this.loading = false
          this.errorText = 'Incorrect Username or Password'
        }
        return
      }
      // in your authentication promise handler or callback
      console.log('****Calling Pendo', localStorage.getItem('_pendo_visitorId.532b2151-792b-4cbf-52a9-ad738cc0073f'))
      this.$store.dispatch('options/setSidebarCollapsed', false)
      await this.$store.dispatch('options/getUserPrefs')
      await this.$store.dispatch('data/getAccounts')
      if (this.$store.getters['options/accountSet']) {
        this.$store.dispatch('data/setInitialGameboyObjects')
        this.getAnalysesCompact()
        this.getCrops()
        this.getProdObjects()
        this.getStations()
        // this.getSamples()
        this.getTargets()
        this.getAnalytes()
        this.getDevices()
        this.getEvents()
        this.getGenericVars()
        this.getGenericPredefinedVars()
        this.getGenericTargets()
        this.getGenericData()
        this.getAnalyticsSummary()
        this.getProdObjectTypes()
      }
      this.postLoginEvent()
      // const layout = this.$store.getters['options/layout']
      if (this.$route.params.nextUrl != null) {
        this.$router.push(this.$route.params.nextUrl)
      } else {
        // this.$router.push('/data')
        this.$router.push('/rooms')
      }
    },

    showSignupPage: function () {
      this.isSignUp = true
    },

    showLoginPage: function () {
      this.isSignUp = false
    },

    getAccounts: function () {
      this.$store.dispatch('data/getAccounts')
    },
    getAnalysesCompact: function () {
      const payload = { unit: this.unit, pag: 0, noStaff: false }
      this.$store.dispatch('data/getAnalysesCompact', payload)
    },
    getSamples: function () {
      this.$store.dispatch('data/getSamples')
    },
    getProdObjects: function () {
      this.$store.dispatch('data/getProdObjects')
    },
    getStations: function () {
      this.$store.dispatch('data/getStations')
    },
    getCrops: function () {
      this.$store.dispatch('data/getCrops')
    },
    getTargets: function () {
      this.$store.dispatch('data/getTargets')
    },
    getAnalytes: function () {
      this.$store.dispatch('data/getAnalytes')
    },
    getDevices: function () {
      this.$store.dispatch('data/getDevices')
    },
    getEvents: function () {
      this.$store.dispatch('data/getEvents')
    },
    getGenericVars: function () {
      this.$store.dispatch('data/getGenericVars')
    },
    getGenericPredefinedVars: function () {
      this.$store.dispatch('data/getGenericPredefinedVars')
    },
    getGenericTargets: function () {
      this.$store.dispatch('data/getGenericTargets')
    },
    getGenericData: function () {
      this.$store.dispatch('data/getGenericData')
    },
    getProdObjectTypes: function () {
      this.$store.dispatch('data/getProdObjecTypes')
    },
    getAnalyticsSummary: function () {
      const payload = { startDate: this.getDate(7), endDate: this.getDate(0) }
      this.$store.dispatch('analyticsboard/getAnalyticsSummary', payload)
    },
    postLoginEvent: function () {
      this.$store.dispatch('options/setUserId')
        .then(resp => {
          this.logEvent('Logged in')
        })
        .catch(err => {
          console.log('***Error in LoginComponent: postLoginEvent: ', err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/app.scss';

.this-card {
  width: 30rem;
  margin-top: 8rem;
}
.mainLoginComponent{
  height: 100vh;
  background: #003232;
}
.loginCardClass {
  margin-top: 8rem;
  background: white;
  // border: 1px solid black;
  padding: 4% 4% 4% 4%;
  height: fit-content;
}

.NAPDescription{
  font-family: 'Inter';
  font-weight: 500;
  margin-bottom: 14%;
  font-size: 1.25rem;
}

.inputBoxDiv{
  display: block;
  border: 1px solid #D9D9D9;
  text-align: justify;
  font-family: 'Inter';
  padding: 5%;
  color: #9CA3A5;
  // margin-bottom: 5%;
  font-weight: 500;
}

.inputBox{
  text-align: justify;
  font-family: 'Inter';
  // padding: 5%;
  color: #9CA3A5;
  // margin-bottom: 5%;
  font-weight: 500;
}
.signInButtonDiv{
  background-color: #02FF84;
  padding: 5%;
  font-family: 'Inter';
  font-weight: 900;
  font-size: 1.25rem;
  margin-top: 14%;
  margin-bottom: 6%;
  cursor: pointer;
}
.forgotPWDSignupLinksContainer{
  display: flex;
  justify-content: space-between;
  font-family: 'Inter';
  color: #503AD9;
  font-weight: 800;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input:-webkit-autofill{
    -webkit-text-fill-color: #9CA3A5 !important;
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #9CA3A5;
            opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #9CA3A5;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
            color: #9CA3A5;
 }

@media (max-width: $mobile-breakpoint) {
  .this-card {
      max-width: 90vw;
  }
}

</style>
