import api from '@/utils/api.js'

const state = {
  stationId: null,
  prodObjects: [],
  prodObjectStatus: ''
}

const mutations = {
  prodobject_request (state) {
    state.prodObjectStatus = 'loading'
  },
  prodobject_success (state, data) {
    state.prodObjectStatus = 'success'
    state.prodObjects = data
  },
  prodobject_error (state) {
    state.prodObjectStatus = 'error'
  },
  stationid_set (state, data) {
    state.stationId = data
  }
}

const getters = {
  prodObjects: state => state.prodObjects,
  prodObjectStatus: state => state.prodObjectStatus,
  stationId: state => state.stationId
}

const actions = {

  getProdObjectTopLevelList ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('prodobject_request')
      api.getProdObjectTopLevelList()
        .then(resp => {
          commit('prodobject_success', resp)
          resolve(resp)
        })
        .catch(err => {
          commit('prodobject_error')
          reject(err)
        })
    })
  },

  setStationId ({ commit }, value) {
    commit('stationid_set', value)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
