<template>
<div style="color: black; text-align: left;">
  <div class="this-section">Screen width/height: {{ swidth }}, {{ sheight }}</div>
  <div class="this-section">Screen available width/height: {{ swavail }}, {{ shavail }}</div>
  <div class="this-section">Window inner width/height:  {{ wwidth }}, {{ wheight }}</div>
  <div class="this-section">Window outer width/height:  {{ wowidth }}, {{ woheight }}</div>
  <div class="this-section">Viewport width/height:  {{ vwidth }}, {{ vheight }}</div>
  <div class="this-section">appVersion:  {{ appVersion }}</div>
  <div class="this-section">userAgent:  {{ userAgent }}</div>
</div>
</template>

<script>
export default {
  name: 'Dimensions',
  components: {
  },
  data () {
    return {
      swidth: null,
      sheight: null,
      swavail: null,
      shavail: null,
      wwidth: null,
      wheight: null,
      wowidth: null,
      woheight: null,
      vwidth: null,
      vheight: null
    }
  },

  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    this.appVersion = navigator.appVersion
    this.userAgent = navigator.userAgent
  },

  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },

  mounted () {
  },

  computed: {
  },

  methods: {
    handleResize () {
      this.swidth = window.screen.width
      this.sheight = window.screen.height
      this.swavail = window.screen.availWidth
      this.shavail = window.screen.availHeight
      this.wwidth = window.innerWidth
      this.wheight = window.innerHeight
      this.wowidth = window.outerWidth
      this.woheight = window.outerHeight
      this.vwidth = document.documentElement.clientWidth
      this.vheight = document.documentElement.clientHeight
    }
  }
}
</script>

<style lang="scss" scoped>
 .this-section {
    max-width: 250px;
    margin-bottom: 20px;
 }
</style>
