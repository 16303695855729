<template>
  <div>
    Page Not Found
    <br/>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>
