
/* ServerAPI talks REST with the backend server.
 *
 * All server communication takes place via this singleton module!
 *
*/

// https://blog.logrocket.com/how-to-make-http-requests-like-a-pro-with-axios/

import axios from 'axios'
import store from '@/store'
// import router from '@/router'

const URL_BASE = 'https://console.nordetect.com/api/v1/'
// const URL_BASE = 'http://localhost:8000/api/v1/'
// const URL_BASE = 'http://ec2-54-155-252-96.eu-west-1.compute.amazonaws.com/api/v1/'
const URL_USER = 'users/'
const URL_ANALYTICS = 'analytics/'
const URL_PDFEXTRACT = 'pdfextract/'
const URL_ACCOUNT = 'accounts/'
const URL_STATION = 'stations/'
const URL_SAMPLE = 'samples/'
const URL_ANALYSIS = 'analyses/'
const URL_TARGET = 'targets/'
const URL_CROP = 'crops/'
const URL_DEVICE = 'devices/'
const URL_EVENT = 'clientevents/'
const URL_PRODOBJECT = 'prodobjects/'
const URL_PRODOBJECT_TYPE = 'prodobject-types/'
const URL_AUTH_TOKEN = 'token/'
const URL_REFRESH = 'tokenrefresh/'
const URL_AUTH_USER = 'currentuser/'
const URL_USERPREFS = 'userprefs/'
const URL_ANALYTE = 'analytes/'
// const URL_FILE = 'media/'
const URL_CHANGE_PASSWORD = 'changepassword/'
const URL_GENERIC_VAR = 'genericvars/'
const URL_GENERIC_DATA = 'genericdata/'
const URL_GENERIC_TARGET = 'generictargets/'

// AUTHORIZATION HEADER
// Currently we set the global Authorization header in App.vue:created
// axios cheat sheet
// https://kapeli.com/cheat_sheets/Axios.docset/Contents/Resources/Documents/index

class ServerAPI {
  postLogin (postData) {
    const url = `${URL_BASE}${URL_AUTH_TOKEN}`
    const headers = { 'Content-Type': 'application/json' }
    return axios.post(
      url,
      postData,
      headers
    )
      .then(response => {
        return response.data
      })
      .catch((error) => {
        console.log('****posLogin Error stringify: ', JSON.stringify(error))
        if (error.response) { throw error.response.data }
        throw error.message
      })
  }

  // https://www.atatus.com/blog/how-to-perform-http-requests-with-axios-a-complete-guide/#error-handling

  async getTest (apiUrl, queryParams) {
    const url = `${URL_BASE}${apiUrl}${queryParams}`
    try {
      const response = await axios.get(url)
      return response.data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // get a new token and try again, if the refresh fails in store/auth.js then usr is logged out
        await store.dispatch('auth/refresh')
        try {
          const response = await axios.get(url)
          return response.data
        } catch (error) {
          if (error.response) { throw error.response.data }
          throw error.message
        }
      } else {
        if (error.response) { throw error.response.data }
        throw error.message
      }
    }
  }

  async getList (apiUrl, queryParams) {
    const url = `${URL_BASE}${apiUrl}${queryParams}`
    try {
      const response = await axios.get(url)
      return response.data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // get a new token and try again, if the refresh fails in store/auth.js then usr is logged out
        await store.dispatch('auth/refresh')
        try {
          const response = await axios.get(url)
          return response.data
        } catch (error) {
          if (error.response) { throw error.response.data }
          throw error.message
        }
      } else {
        if (error.response) { throw error.response.data }
        throw error.message
      }
    }
  }

  async postList (apiUrl, queryParams, postData) {
    const url = `${URL_BASE}${apiUrl}${queryParams}`
    try {
      const response = await axios.post(url, postData)
      return response.data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // get a new token and try again, if the refresh fails in store/auth.js then usr is logged out
        await store.dispatch('auth/refresh')
        try {
          const response = await axios.post(url, postData)
          return response.data
        } catch (error) {
          if (error.response) { throw error.response.data }
          throw error.message
        }
      } else {
        if (error.response) { throw error.response.data }
        throw error.message
      }
    }
  }

  postListX (apiUrl, queryParams, postData) {
    const url = `${URL_BASE}${apiUrl}${queryParams}`
    return axios.post(
      url,
      postData
    )
      .then(response => {
        return response.data
      })
      .catch((error) => {
        console.log('NDLOG postList Error stringify: ', JSON.stringify(error))
        console.log('NDLOG response data: ', error.response.data)
        // throw ('An error occurred: ' + (error[response][data]))
        // throw new Error('An error occurred: ' + (error[response][data]))
        throw error.response.data
      })
  }

  postRefresh (apiUrl, postData) {
    const options = {
      method: 'post',
      url: `${URL_BASE}${apiUrl}`,
      headers: { 'Content-Type': 'application/json' },
      data: JSON.stringify(postData),
      transformRequest: [(data, headers) => {
        delete headers.common.Authorization
        return data
      }]
    }
    return axios(options)
      .then(response => {
        return response.data
      })
      .catch((error) => {
        console.log('api.js postRefresh error.response.data: ', error.response.data)
        throw error.response.data
      })
  }

  async getDetail (apiUrl, queryParams, id) {
    const url = `${URL_BASE}${apiUrl}${id}${queryParams}`
    try {
      const response = await axios.get(url)
      return response.data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // get a new token and try again, if the refresh fails in store/auth.js then usr is logged out
        await store.dispatch('auth/refresh')
        try {
          const response = await axios.get(url)
          return response.data
        } catch (error) {
          if (error.response) { throw error.response.data }
          throw error.message
        }
      } else {
        if (error.response) { throw error.response.data }
        throw error.message
      }
    }
  }

  async putDetail (apiUrl, queryParams, id, putData) {
    const url = `${URL_BASE}${apiUrl}${id}${queryParams}`
    try {
      const response = await axios.put(url, putData)
      return response.data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // get a new token and try again, if the refresh fails in store/auth.js then usr is logged out
        await store.dispatch('auth/refresh')
        try {
          const response = await axios.put(url, putData)
          return response.data
        } catch (error) {
          if (error.response) { throw error.response.data }
          throw error.message
        }
      } else {
        if (error.response) { throw error.response.data }
        throw error.message
      }
    }
  }

  putDetailX (apiUrl, queryParams, id, putData) {
    const url = `${URL_BASE}${apiUrl}${id}${queryParams}`
    return axios.put(
      url,
      putData
    )
      .then(response => {
        return response.data
      })
      .catch((error) => {
        console.log('NDLOG putDetail Error stringify: ', JSON.stringify(error))
        return error
      })
  }

  async patchDetail (apiUrl, queryParams, id, patchData) {
    const url = `${URL_BASE}${apiUrl}${id}${queryParams}`
    try {
      const response = await axios.patch(url, patchData)
      return response.data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // get a new token and try again, if the refresh fails in store/auth.js then usr is logged out
        await store.dispatch('auth/refresh')
        try {
          const response = await axios.patch(url, patchData)
          return response.data
        } catch (error) {
          if (error.response) { throw error.response.data }
          throw error.message
        }
      } else {
        if (error.response) { throw error.response.data }
        throw error.message
      }
    }
  }

  patchDetailX (apiUrl, queryParams, id, patchData) {
    const url = `${URL_BASE}${apiUrl}${id}${queryParams}`
    return axios.patch(
      url,
      patchData
    )
      .then(response => {
        return response.data
      })
      .catch((error) => {
        console.log('NDLOG patchDetail Error stringify: ', JSON.stringify(error))
        throw error.response.data
      })
  }

  async deleteDetail (apiUrl, id) {
    const url = `${URL_BASE}${apiUrl}${id}`
    try {
      const response = await axios.delete(url)
      return response.data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // get a new token and try again, if the refresh fails in store/auth.js then usr is logged out
        await store.dispatch('auth/refresh')
        try {
          const response = await axios.delete(url)
          return response.data
        } catch (error) {
          if (error.response) { throw error.response.data }
          throw error.message
        }
      } else {
        if (error.response) { throw error.response.data }
        throw error.message
      }
    }
  }

  deleteDetailx (apiUrl, id) {
    const url = `${URL_BASE}${apiUrl}${id}`
    return axios.delete(
      url
    )
      .then(response => {
        return response.data
      })
      .catch((error) => {
        console.log('NDLOG deleteDetail Error stringify: ', JSON.stringify(error))
        throw error.response.data
      })
  }

  async getFile (filename) {
    const url = filename
    try {
      const response = await axios.get(url)
      return response.data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // get a new token and try again, if the refresh fails in store/auth.js then usr is logged out
        await store.dispatch('auth/refresh')
        try {
          const response = await axios.get(url)
          return response.data
        } catch (error) {
          if (error.response) { throw error.response.data }
          throw error.message
        }
      } else {
        if (error.response) { throw error.response.data }
        throw error.message
      }
    }
  }

  getFilex (filename) {
    console.log('NDLOG getFile filename: ', filename)
    const url = filename
    return axios.get(
      url
    )
      .then(response => {
        return response.data
      })
      .catch((error) => {
        // console.log('NDLOG getDetail Error stringify: ', JSON.stringify(error))
        throw error.response.data
      })
  }

  getUserList () {
    return this.getList(URL_USER, '')
  }

  getUserAnalyticsList () {
    const params = '?all_data=true&no_staff=true'
    return this.getList(URL_USER, params)
  }

  getUserDetail (id) {
    return this.getDetail(URL_USER, '', id)
  }

  patchUserDetail (id, putData) {
    return this.patchDetail(URL_USER, '', id, putData)
  }

  getUserAnalyticsDetail (id, fromDate = null, toDate = null) {
    let params = '?all_data=true'
    if (fromDate) { params = params + '&from_date=' + fromDate }
    if (toDate) { params = params + '&to_date=' + toDate }
    return this.getDetail(URL_ANALYTICS, params, id)
  }

  getUserAnalyticsSummary (fromDate = null, toDate = null) {
    let params = '?all_data=true&no_staff=true&summary=true'
    if (fromDate) { params = params + '&from_date=' + fromDate }
    if (toDate) { params = params + '&to_date=' + toDate }
    return this.getList(URL_ANALYTICS, params)
  }

  getUserPrefs () {
    return this.getList(URL_USERPREFS, '')
  }

  patchUserPrefs (id, patchData) {
    return this.patchDetail(URL_USERPREFS, '', id, patchData)
  }

  putChangePassword (putData) {
    //  console.log('PUTCHANGEPASSWORD', putData)
    return this.putDetail(URL_CHANGE_PASSWORD, '', '', putData)
  }

  getTestList () {
    return this.getTest(URL_ACCOUNT, '?all_perms=true')
  }

  getAccountList () {
    return this.getList(URL_ACCOUNT, '?all_perms=true')
  }

  getAccountListMin () {
    return this.getList(URL_ACCOUNT, '?fields=name,id&all_perms=true')
  }

  postAccount (postData) {
    return this.postList(URL_ACCOUNT, '', postData)
  }

  getAccountDetail (id) {
    return this.getDetail(URL_ACCOUNT, '', id)
  }

  postRefreshToken (postData) {
    return this.postRefresh(URL_REFRESH, postData)
  }

  postSample (postData) {
    return this.postList(URL_SAMPLE, '', postData)
  }

  getSampleList (page = null) {
    let params = '?accounts=' + this.getActiveAccountId()
    if (page) { params = params + '&page=' + page }
    return this.getList(URL_SAMPLE, params)
  }

  getSampleById (sampleId) {
    let params = '?accounts=' + this.getActiveAccountId() + '&sample_id=' + sampleId
    params = params + '&fields=id,sample_id,account'
    return this.getList(URL_SAMPLE, params)
  }

  getSampleListMin (accountId) {
    return this.getList(URL_SAMPLE, '?fields=id,sample_id&accounts=' + this.getActiveAccountId())
  }

  getSampleDetail (id) {
    return this.getDetail(URL_SAMPLE, '', id)
  }

  putSampleDetail (id, putData) {
    return this.putDetail(URL_SAMPLE, '', id, putData)
  }

  deleteSampleDetail (id) {
    return this.deleteDetail(URL_SAMPLE, id)
  }

  patchSampleDetail (id, patchData) {
    return this.patchDetail(URL_SAMPLE, '', id, patchData)
  }

  getAnalysisList (page = null, noStaff = null) {
    let params = null
    if (noStaff) {
      params = '?all_data=true&no_staff=true'
    } else {
      params = '?accounts=' + this.getActiveAccountId()
    }
    if (page) { params = params + '&page=' + page }
    return this.getList(URL_ANALYSIS, params)
  }

  getAnalysisListCompact (page = null, noStaff = null) {
    let params = '?compact=true'
    if (noStaff) {
      params = params + '&all_data=true&no_staff=true'
    } else {
      params = params + '&accounts=' + this.getActiveAccountId()
    }
    if (page) { params = params + '&page=' + page }
    return this.getList(URL_ANALYSIS, params)
  }

  getAnalysisListFull (page = null, noStaff = null) {
    let params = '?compact=false'
    if (noStaff) {
      params = params + '&all_data=true&no_staff=true'
    } else {
      params = params + '&accounts=' + this.getActiveAccountId()
    }
    if (page) { params = params + '&page=' + page }
    return this.getList(URL_ANALYSIS, params)
  }

  getAnalysisListNoStaff (page = null) {
    let params = '?all_data=true&no_staff=true'
    if (page) { params = params + '&page=' + page }
    return this.getList(URL_ANALYSIS, params)
  }

  getAnalysisDetail (id) {
    return this.getDetail(URL_ANALYSIS, '', id)
  }

  getAnalysisDetailAdmin (id) {
    return this.getDetail(URL_ANALYSIS, '?all_data=true', id)
  }

  patchAnalysisDetail (id, patchData) {
    return this.patchDetail(URL_ANALYSIS, '', id, patchData)
  }

  deleteAnalysisDetail (id) {
    return this.deleteDetail(URL_ANALYSIS, id)
  }

  postAnalysis (postData) {
    return this.postList(URL_ANALYSIS, '', postData)
  }

  getActiveAccountId () {
    const activeAccount = store.getters['options/activeAccount']
    // console.log('***In api.js activeAcc', activeAccount, activeAccount[0], activeAccount[0].id)
    if (activeAccount) {
      return activeAccount.id
    }
    return ''
  }

  getStationList () {
    return this.getList(URL_STATION, '?accounts=' + this.getActiveAccountId())
  }

  getStationListMin () {
    console.log('****CALLING getStationListMin')
    return this.getList(URL_STATION, '?fields=id,name&accounts=' + this.getActiveAccountId())
  }

  postStation (postData) {
    return this.postList(URL_STATION, '', postData)
  }

  patchStationDetail (id, patchData) {
    return this.patchDetail(URL_STATION, '', id, patchData)
  }

  getStationDetail (id) {
    return this.getDetail(URL_STATION, '', id)
  }

  getStationDetailAll (id, from = null, to = null) {
    let params = '?detailed=true'
    if (from) { params = params + '&from_date=' + from }
    if (to) { params = params + '&to_date=' + to }
    return this.getDetail(URL_STATION, params, id)
  }

  deleteStationDetail (id) {
    return this.deleteDetail(URL_STATION, id)
  }

  // getProdObjectList () {
  // return this.getList(URL_PRODOBJECT, '?accounts=' + this.getActiveAccountId())
  // }

  getProdObjectList (fields = null) {
    let params = '?accounts=' + this.getActiveAccountId()
    if (fields) { params = params + '&fields=' + fields }
    return this.getList(URL_PRODOBJECT, params)
  }

  getProdObjectTopLevelList () {
    const params = '?accounts=' + this.getActiveAccountId() + '&top_level=true'
    return this.getList(URL_PRODOBJECT, params)
  }

  deleteProdObjectDetail (id) {
    return this.deleteDetail(URL_PRODOBJECT, id)
  }

  getProdObjectListMin () {
    return this.getList(URL_PRODOBJECT, '?fields=id,name&accounts=' + this.getActiveAccountId())
  }

  postProdObject (postData) {
    return this.postList(URL_PRODOBJECT, '', postData)
  }

  getProdObjectDetail (id) {
    return this.getDetail(URL_PRODOBJECT, '', id)
  }

  patchProdObjectDetail (id, patchData) {
    return this.patchDetail(URL_PRODOBJECT, '', id, patchData)
  }

  getEventList (page = null) {
    let params = null
    if (page) { params = '?page=' + page }
    return this.getList(URL_EVENT, params)
  }

  getEventDetail (id) {
    return this.getDetail(URL_EVENT, '', id)
  }

  postEvent (postData) {
    return this.postList(URL_EVENT, '', postData)
  }

  getDeviceList () {
    return this.getList(URL_DEVICE, '')
  }

  postDevice (postData) {
    return this.postList(URL_DEVICE, '', postData)
  }

  patchDeviceDetail (id, patchData) {
    return this.patchDetail(URL_DEVICE, '', id, patchData)
  }

  getDeviceDetail (id) {
    return this.getDetail(URL_DEVICE, '', id)
  }

  deleteDeviceDetail (id) {
    return this.deleteDetail(URL_DEVICE, '', id)
  }

  getAnalyteList () {
    return this.getList(URL_ANALYTE, '')
  }

  getProdObjectTypeList () {
    return this.getList(URL_PRODOBJECT_TYPE, '')
  }

  getAnalyteListMin () {
    return this.getList(URL_ANALYTE, '?fields=id,name')
  }

  postAnalyte (postData) {
    return this.postList(URL_ANALYTE, '', postData)
  }

  getAnalyteDetail (id) {
    return this.getDetail(URL_ANALYTE, '', id)
  }

  patchAnalyteDetail (id, patchData) {
    return this.patchDetail(URL_ANALYTE, '', id, patchData)
  }

  deleteAnalyteDetail (id) {
    return this.deleteDetail(URL_ANALYTE, '', id)
  }

  getTargetList () {
    return this.getList(URL_TARGET, '?accounts=' + this.getActiveAccountId())
  }

  getTargetListMin () {
    return this.getList(URL_TARGET, '?fields=id,name&accounts=' + this.getActiveAccountId())
  }

  getTargetDetail (id) {
    return this.getDetail(URL_TARGET, '', id)
  }

  deleteTargetDetail (id) {
    return this.deleteDetail(URL_TARGET, id)
  }

  postTarget (postData) {
    return this.postList(URL_TARGET, '', postData)
  }

  patchTargetDetail (id, patchData) {
    return this.patchDetail(URL_TARGET, '', id, patchData)
  }

  getCropList (fields = null, fromDate = null, toDate = null) {
    let params = '?accounts=' + this.getActiveAccountId()
    if (fields) { params = params + '&fields=' + fields }
    if (fromDate) { params = params + '&from_date=' + fromDate }
    if (toDate) { params = params + '&to_date=' + toDate }
    return this.getList(URL_CROP, params)
  }

  getCropDetail (id) {
    return this.getDetail(URL_CROP, '', id)
  }

  getCropDetailAll (id) {
  // Gets all the analyses for this crop
    return this.getDetail(URL_CROP, '?detailed=true', id)
  }

  deleteCropDetail (id) {
    return this.deleteDetail(URL_CROP, id)
  }

  getCropListMin () {
    return this.getList(URL_CROP, '?fields=id,name&accounts=' + this.getActiveAccountId())
  }

  postCrop (postData) {
    return this.postList(URL_CROP, '', postData)
  }

  patchCropDetail (id, patchData) {
    return this.patchDetail(URL_CROP, '', id, patchData)
  }

  getAuthUser () {
    return this.getList(URL_AUTH_USER, '')
  }

  getGenericPredefinedVarList () {
    return this.getList(URL_GENERIC_VAR, '?predefined=true')
  }

  postGenericPredefinedVar () {
    return this.postList(URL_GENERIC_VAR, '?predefined=true')
  }

  getGenericVarList () {
    return this.getList(URL_GENERIC_VAR, '?accounts=' + this.getActiveAccountId())
  }

  getGenericVarListMin () {
    return this.getList(URL_GENERIC_VAR, '?fields=id,name&accounts=' + this.getActiveAccountId())
  }

  getGenericVarDetail (id) {
    return this.getDetail(URL_GENERIC_VAR, '', id)
  }

  deleteGenericVarDetail (id) {
    return this.deleteDetail(URL_GENERIC_VAR, id)
  }

  postGenericVar (postData) {
    return this.postList(URL_GENERIC_VAR, '', postData)
  }

  patchGenericVarDetail (id, patchData) {
    return this.patchDetail(URL_GENERIC_VAR, '', id, patchData)
  }

  getGenericDataList (fields = null, variables = null, locations = null) {
    let params = '?accounts=' + this.getActiveAccountId()
    if (fields) { params = params + '&fields=' + fields }
    if (variables) { params = params + '&variables=' + variables }
    if (locations) { params = params + '&locations=' + locations }
    console.log('****DATA.JS PARAMS', params)
    return this.getList(URL_GENERIC_DATA, params)
  }

  getGenericDataListMin () {
    return this.getList(URL_GENERIC_DATA, '?fields=id,name&accounts=' + this.getActiveAccountId())
  }

  getGenericDataDetail (id) {
    return this.getDetail(URL_GENERIC_DATA, '', id)
  }

  deleteGenericDataDetail (id) {
    return this.deleteDetail(URL_GENERIC_DATA, id)
  }

  postGenericData (postData) {
    return this.postList(URL_GENERIC_DATA, '', postData)
  }

  patchGenericDataDetail (id, patchData) {
    return this.patchDetail(URL_GENERIC_DATA, '', id, patchData)
  }

  getGenericTargetList () {
    return this.getList(URL_GENERIC_TARGET, '?accounts=' + this.getActiveAccountId())
  }

  getGenericTargetListMin () {
    return this.getList(URL_GENERIC_TARGET, '?fields=id,name&accounts=' + this.getActiveAccountId())
  }

  getGenericTargetDetail (id) {
    return this.getDetail(URL_GENERIC_TARGET, '', id)
  }

  deleteGenericTargetDetail (id) {
    return this.deleteDetail(URL_GENERIC_TARGET, id)
  }

  postGenericTarget (postData) {
    return this.postList(URL_GENERIC_TARGET, '', postData)
  }

  patchGenericTargetDetail (id, patchData) {
    return this.patchDetail(URL_GENERIC_TARGET, '', id, patchData)
  }

  postPdfExtract (postData) {
    return this.postList(URL_PDFEXTRACT, '', postData)
  }
}

// export singleton API
const api = new ServerAPI()
export default api
